import { Tooltip } from "react-tooltip";

const ReviewProgress = ({ comparable }) => {
  const fairPsf = comparable.fair_psf >= 0 ? comparable.fair_psf : null;
  const avgPsf = comparable.avg_psf_last3mths;
  const underPriced = avgPsf < fairPsf;

  // const icon = fairPsf ? ('icon-arrow-top-right' + (underPriced ? '' : ' rotate-90')) : 'icon-usd';
  const icon = 'icon-usd';
  const color = fairPsf ? (underPriced ? 'bg-green-2' : 'bg-red-1') : 'bg-dark';
  const textColor = fairPsf ? (underPriced ? 'text-green-2' : 'text-red-1') : 'text-dark';

  return (
    <div className="mt-20 mb-20">
      <div className="d-flex items-center">
        <div className={`flex-center ${color} rounded-4 size-70 text-22 fw-800 text-white`}>
          <i className={`${icon} text-white-1`} />
        </div>

        <div className="ml-15 y-gap-5">
          <div className="text-12 text-light-1 lh-12">Estimated Fair Price</div>
          <div
            className="text-14 text-dark-1 fw-500 lh-14"
            data-tooltip-id="proj-review-tooltip"
            data-tooltip-html="Fair price relative to nearby properties"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >Fair Price: {comparable.fair_psf >= 0 ? `$${comparable.fair_psf.toFixed(2)} psf` : 'No data yet'}</div>
          <div
            className={`text-14 lh-14 fw-500`}
            data-tooltip-id="proj-review-tooltip"
            data-tooltip-html="Past 3 months average PSF"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >Recent PSF: <span className={textColor}>${comparable.avg_psf_last3mths.toFixed(2)}</span></div>
        </div>
      </div>

      <Tooltip id="proj-review-tooltip" style={{ zIndex: 600 }} />
    </div>
  );
};

export default ReviewProgress;
