export const COLORS = [
  'rgba(10,75,149,1)',
  'rgba(236,122,8,1)',
  'rgba(0,149,150,1)',
  'rgba(87,82,209,1)',
  'rgba(244,193,69,1)',
  'rgba(76,177,64,1)',
  'rgba(138,141,144,1)',
  'rgba(0,102,204,1)',
  'rgba(124,198,116,1)',
  'rgba(115,197,197,1)',
  'rgba(132,129,221,1)',
  'rgba(246,209,115,1)',
  'rgba(239,146,52,1)',
  'rgba(184,187,190,1)',
  'rgba(139,193,247,1)',
  'rgba(81,157,233,1)',
  'rgba(0,75,149,1)',
  'rgba(0,47,93,1)',
  'rgba(189,226,185,1)',
  'rgba(56,129,47,1)',
  'rgba(35,81,30,1)',
  'rgba(162,217,217,1)',
  'rgba(0,95,96,1)',
  'rgba(0,55,55,1)',
  'rgba(178,176,234,1)',
  'rgba(60,61,153,1)',
  'rgba(42,38,95,1)',
  'rgba(249,224,162,1)',
  'rgba(240,171,0,1)',
  'rgba(197,140,0,1)',
  'rgba(244,182,120,1)',
  'rgba(196,97,0,1)',
  'rgba(143,71,0,1)',
  'rgba(210,210,210,1)',
  'rgba(106,110,115,1)',
];

export const getColor = (index) => COLORS[index % COLORS.length];

export const LINE_CHART_RED_BG = 'rgba(255,99,132,1)';
export const LINE_CHART_BLUE_BG = 'rgba(0,128,9,1)';
export const LINE_CHART_ORANGE_BG = 'rgba(236,122,8,1)';

export const CHART_RED_BG = '#e74c3c';
export const CHART_GREEN_BG = '#1abc9c';
export const CHART_ORANGE_BG = '#f39c12';

export const generateColorsByValue = (data) => data.map(value => value > 0 ? LINE_CHART_BLUE_BG : LINE_CHART_RED_BG);

export const BLUE_HUES = [
  '#e7eaf1',
  '#cfd6e4',
  '#b8c2d7',
  '#a0aeca',
  '#899abd',
];

export const BLUE_HUES_REVERSE = [
  '#899abd',
  '#a0aeca',
  '#b8c2d7',
  '#cfd6e4',
  '#e7eaf1',
];

export const getBlueHue = (value, reverse) => (reverse ? BLUE_HUES_REVERSE : BLUE_HUES)[value >= 0.999 ? 4 : Math.floor(value * 5) % 5];
