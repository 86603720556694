import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import DefaultHeader from "@/components/header/default-header";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import {
  checkMediaQuery,
  continueIfAllowedUser,
  noSelectClass
} from "@/utils/user";
import {
  MARKET_SEGMENTS,
  MARKET_SEGMENT_ID_MAP,
  PLANNING_AREAS,
  REGIONS,
  getPropertyFileName,
  unravelId
} from "@/utils/areas";
import {
  downloadFileUrl,
  logNameSearch,
  logProjectPageView
} from "@/utils/api";
import FilterBox from "@/components/project/FilterBox";
import {
  calculateDist,
  convertPropertyData,
  generateGMapUrl,
  getFloorFromAddress
} from "@/utils/convert";
import OverviewTab from "@/components/project/OverviewTab";
import TransactionsTab from "@/components/project/TransactionsTab";
import CompareTab from "@/components/project/CompareTab";
import NearbyTab from "@/components/project/NearbyTab";
import UnitsTab from "@/components/project/UnitsTab";
import DiscussionTab from "@/components/project/DiscussionTab";
import RentalTab from "@/components/project/RentalTab";
import TabButtons from "@/components/project/TabButtons";
import ScrollToTop from "@/components/project/ScrollToTop";
import DetailMap from "@/components/project/DetailMap";
import { convertToProjectV2Url } from "@/utils/url";

const metadata = {
  title: "REALSMART.SG | Search Properties | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'transactions', label: 'Transactions' },
  { id: 'rental', label: 'Rental' },
  { id: 'compare', label: 'Compare' },
  { id: 'nearby', label: 'Nearby' },
  { id: 'units', label: 'Stack View' },
  { id: 'discuss', label: 'Discussion' },
];

const DEFAULT_ZOOM = 16;

const FULL_ZOOM = 10;

const TABS_REQUIRE_TABLE_DATA = new Set([
  'transactions',
  'rental',
  'compare',
  'units'
]);

const TABS_REQUIRE_MAIN_DATA = new Set([
  'overview',
  'nearby'
]);

const ProjectMapV2Page = ({ user, session }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  const [id, setId] = useState(params.get('id'));
  const [projectName, setProjectName] = useState(id?.split(',')?.[0]);
  const [streetName, setStreetName] = useState(id?.split(',')?.[1]);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const [result, setResult] = useState(null);
  const [hasBlocks, setHasBlocks] = useState(false);
  const [schools, setSchools] = useState({ origin: [], props: [] });
  const [resultPropLocation, setResultPropLocation] = useState(null);
  const [resultAllLocations, setResultAllLocations] = useState(null);
  const [comparable, setComparable] = useState(null);
  const [nearbyProperties, setNearbyProperties] = useState([]);
  const [txResults, setTxResults] = useState(null);
  const [profitResults, setProfitResults] = useState(null);
  const [rentalResults, setRentalResults] = useState(null);
  const [compsResults, setCompsResults] = useState(null);
  const [perfResults, setPerfResults] = useState(null);

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const isMinimizedRef = useRef(isMinimized);

  const [focusLocation, setFocusLocation] = useState(null);
  const [highlightList, setHighlightList] = useState([]);
  const [blockMarker, setBlockMarker] = useState(null);

  const [loadedTableData, setLoadedTableData] = useState(false);
  const [loadedMainData, setLoadedMainData] = useState(false);

  const [initialZoom, setInitialZoom] = useState({
    longitude: 103.8198,
    latitude: 1.3521,
    zoom: id ? DEFAULT_ZOOM : FULL_ZOOM
  });
  const [screenDim, setScreenDim] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [selectedTab, setSelectedTab] = useState(TABS[0].id);

  const mediaMatches = checkMediaQuery(); // check is mobile
  const defaultMediaStyle = {
    height: mediaMatches && id ? 'calc(52svh - 132px)' : '100%',
    width: mediaMatches || !id ? '100svw' : '52svw',
  };
  const [mapMediaStyle, setMapMediaStyle] = useState(defaultMediaStyle);

  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [idMapping, setIdMapping] = useState({});
  const [planningAreas, setPlanningAreas] = useState([]);

  useEffect(() => {
    isMinimizedRef.current = isMinimized;
  }, [isMinimized]);

  const handleResize = () => {
    if (!isMinimizedRef.current) {
      const mediaMatches = checkMediaQuery();
      setMapMediaStyle({
        height: mediaMatches && id ? 'calc(52svh - 132px)' : '100svh',
        width: mediaMatches || !id ? '100svw' : '52svw',
      });
    }
    setScreenDim({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMinimize = () => {
    setMapMediaStyle({
      height: mediaMatches ? '100svh' : '100svh',
      width: '100svw'
    });
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleExpand = () => {
    setMapMediaStyle(defaultMediaStyle);
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleMaximize = () => {
    setIsMaximized(true);
    setIsMinimized(false);
  };

  const generatePropertyLocation = (result) => {
    return {
      name: result['Project Name'],
      lat: result['lat'],
      lng: result['long'],
      type: 'Property',
      url: generateGMapUrl(result['Project Name'], result['lat'], result['long']),
      default: true,
      trend: 0,
    };
  };

  const convertLocationToType = (list) => {
    if (list === 'shopping_1000.0' || list === 'nearest_3_shopping') return 'Shopping';
    if (list === 'transport_1000.0' || list === 'nearest_3_subways') return 'Transport';
    if (list === 'schools_1000.0' || list === 'nearest_3_primary_schools') return 'Schools';
    if (list === 'sports_1000.0' || list === 'nearest_3_sports') return 'Sports';
    return null;
  };

  const generateLocationList = (result, list) => {
    const locations = [];
    list.map(category => {
      if (category in result && result[category]) {
        const data = JSON.parse(result[category]);
        data.forEach(location => {

          // fallback for distance is manual calculate distance between 2 coordinates
          const distance = location['distance_from_center']
            ? location['distance_from_center']
            : (location['walking_distance_m_avg']
              ? location['walking_distance_m_avg']
              : calculateDist(result['lat'], result['long'], location['latlong'][0], location['latlong'][1])
            );

          // fallback for walking time is manual calculate time by estimating human walking speed
          const walkTime = location['walking_time_s']
            ? location['walking_time_s']
            : (location['walking_time_s_avg']
              ? location['walking_time_s_avg']
              : distance / 0.9
            );
          
          // set the location object
          locations.push({
            name: location['displayname'],
            lat: location['latlong'][0],
            lng: location['latlong'][1],
            type: convertLocationToType(category),
            distance, // in m
            walkTime, // in s
            url: location['google_maps_url'],
          });
        });
      }
    });
    return locations.sort((a, b) => a.distance - b.distance);
  };

  const generateSimilarPropertiesNearby = (comparables) => comparables ?
    comparables.map(c => {
      const latlng = c['latlong'].split(',');
      const distance = c['criteria']['distance_from_other_meters'];
      return {
        name: c['project_name'],
        type: 'Property',
        lat: latlng[0],
        lng: latlng[1],
        distance,
        walkTime: distance / 0.9,
        url: generateGMapUrl(c['project_name'], latlng[0], latlng[1]),
        id: `${c['project_name']},${c['street']}`,
        'Primary Schools': c['criteria']['count_near_similar_primary_schools'],
        'Subways': c['criteria']['count_near_similar_subways'],
        'Property Types': c['criteria']['similar_property_types'],
        'Tenure': c['criteria']['similar_tenure'],
        'Units': c['criteria']['similar_total_units'],
        'avg_psf_last3mths': c['avg_psf_last3mths'],
        'perc_profitable': c['perc_profitable'],
        'property_types': c['property_types'],
        'perc_HDB_buyer': c['perc_HDB_buyer'],
        'latest_completion_date': c['latest_completion_date'],
        'tenure_available': c['tenure_available'],
        'total_units': c['total_units'] ? parseInt(c['total_units']) : null,
        'perc_sold_at_launch': c['perc_sold_at_launch']
      };
    })
    : [];

  const checkBlocks = (propTypes) => propTypes
    && (
      propTypes.indexOf('Apartment') >= 0
      || propTypes.indexOf('Condominium') >= 0
      || propTypes.indexOf('Executive Condominium') >= 0
      || propTypes.indexOf('Non-landed Properties') >= 0
    );

  const buildSchoolList = (props, propLoc) => {
    if (!props) return {
      origin: [],
      props: [],
    };
    const schSeen = new Set();
    const schList = [];
    const schoolList = props.filter(prop => prop['sch_1km']).map(prop => {
      const lat = parseFloat(prop.lat);
      const lng = parseFloat(prop.long);
      return {
        ...prop,
        schools: JSON.parse(prop['sch_1km']).map(sch => {
          const distance = calculateDist(lat, lng, parseFloat(sch.lat), parseFloat(sch.long));
          const id = `${sch.name}-${sch.level}`;
          if (!schSeen.has(id)) {
            schSeen.add(id);
            const originDistance = calculateDist(propLoc.lat, propLoc.lng, parseFloat(sch.lat), parseFloat(sch.long));
            schList.push({
              id,
              name: sch.name,
              type: 'Schools',
              lat: parseFloat(sch.lat),
              lng: parseFloat(sch.long),
              distance: originDistance,
              walkTime: originDistance / 0.9,
              level: sch.level,
              props: []
            });
          }
          schList.find(s => s.id === id).props.push({
            address: prop.address,
            block: prop.block,
            lat: parseFloat(prop.lat),
            lng: parseFloat(prop.long),
            postal: prop.postal,
            distance,
          });
          return {
            id,
            name: sch.name,
            type: 'Schools',
            lat: parseFloat(sch.lat),
            lng: parseFloat(sch.long),
            distance,
            walkTime: distance / 0.9,
            level: sch.level
          };
        }).sort((a, b) => a.distance - b.distance)
      };
    }).filter(prop => prop.schools.length > 0);
    return {
      origin: [
        ...schList.map(s => ({
          ...s,
          props: s.props.sort((a, b) => a.distance - b.distance)
        }))
      ],
      props: schoolList,
    };
  };

  const loadDetails = (projectName, streetName) => {
    const cloudFilename = getPropertyFileName(projectName, streetName);
    downloadFileUrl('properties/details', cloudFilename, 'json', (url) => {
      fetch(url)
      .then(resp => resp.json()).then(data => {
        setHasBlocks(checkBlocks(data?.result?.property_types?.split(',').filter(t => !!t)));
        setResult(convertPropertyData(data?.result));
        setPerfResults(data?.perf);
        if (data?.result && data.result.lat && data.result.long) {
          const propLoc = generatePropertyLocation(data.result);
          setResultPropLocation(propLoc);
          setResultAllLocations(generateLocationList(data.result, [
            'shopping_1000.0',
            'transport_1000.0',
            'schools_1000.0',
            'sports_1000.0'
          ]));
          setInitialZoom({
            longitude: propLoc.lng,
            latitude: propLoc.lat,
            zoom: DEFAULT_ZOOM
          });
          setFocusLocation({
            lat: propLoc.lat,
            lng: propLoc.lng,
          });
          if (data.sch) {
            const schools = buildSchoolList(data.sch, propLoc);
            setSchools(schools);
          }
        }
        if (data?.comparable) {
          const comps = data.comparable.result.comparables.replace(/NaN/g, 'null');
          const comparables = JSON.parse(comps);
          setComparable({
            ...data.comparable.result,
            comparables,
          })
          setNearbyProperties(generateSimilarPropertiesNearby(comparables));
        }
        setLoadedMainData(true);
      }).catch(err => {
        setErr(err);
        setLoadedMainData(true);
      });
    }, (err) => {
      setErr(err);
      setLoadedMainData(true);
    });
  };

  const loadTableData = (projectName, streetName) => {
    const cloudFilename = getPropertyFileName(projectName, streetName);
    downloadFileUrl('properties/full', cloudFilename, 'json', (url) => {
      fetch(url)
      .then(resp => resp.json()).then(json => {
        setTxResults(json.t.map(r => ({
          ...r,
          floor: getFloorFromAddress(r['Address'])
        })));
        setProfitResults(json.p);
        setRentalResults(json.r);
        setCompsResults(json.c);
        setLoadedTableData(true);
      }).catch(err => {
        setErr(err);
        setLoadedTableData(true);
      });
    }, (err) => {
      setTxResults([]);
      setLoadedTableData(true);
    });
  };

  const loadData = (projectName, streetName) => {
    // reset all
    setResult(null);
    setHasBlocks(false);
    setSchools({ origin: [], props: [] });
    setResultPropLocation(null);
    setResultAllLocations(null);
    setComparable(null);
    setNearbyProperties([]);
    setTxResults(null);
    setProfitResults(null);
    setRentalResults(null);
    setCompsResults(null);
    setPerfResults(null);
    setFocusLocation(null);
    setHighlightList([]);
    setBlockMarker(null);
    setLoadedTableData(false);
    setLoadedMainData(false);

    // load first level data
    loadDetails(projectName, streetName);

    // log for analytics
    logProjectPageView(session, projectName, streetName);
  };

  useEffect(() => {
    setErr(null);
    continueIfAllowedUser(user, () => {
      setTimeout(() => {
        loadMapData();
        if (id) {
          loadData(projectName, streetName);
        }
      }, 288);
    }, navigate, pathname);
  }, [user, id]);

  const goToProperty = (projectName, streetName) => {
    setId(`${projectName},${streetName}`);
    setProjectName(projectName);
    setStreetName(streetName);
    loadData(projectName, streetName);
    setSelectedTab(TABS[0].id);
    setTimeout(() => {
      convertToProjectV2Url(projectName, streetName);
      onSwitchTab(TABS[0].id);
    }, 668);
    if (isMaximized || isMinimized) {
      handleExpand();
    }
  };

  const onSearchLocation = (searchTerm) => {
    logNameSearch('DETAIL', session, searchTerm.name);
    goToProperty(searchTerm.name, searchTerm.address);
  };

  const onSwitchTab = (tab) => {
    if (TABS_REQUIRE_TABLE_DATA.has(tab) && !loadedTableData) {
      const chunks = id.split(',');
      loadTableData(chunks[0], chunks[1]);
    }
    document.getElementById('project-details-modal')?.scrollTo({ top: 0 });
    setBlockMarker(null);
    setHighlightList([]);
  };

  const onClickPlace = (place) => {
    const excludedList = highlightList.filter(n => (n.id && place.id) ? (n.id !== place.id) : (n.name !== place.name));
    if (highlightList.length !== excludedList.length) {
      setHighlightList(excludedList);
    } else {
      setFocusLocation({
        ...place,
        zoom: 17,
        target: resultPropLocation
      });
      setHighlightList([...excludedList, place]);
      if (isMaximized) {
        handleExpand();
      }
    }
  };

  const onBlockSelect = (block) => {
    if (!block) {
      setBlockMarker(null);
      return;
    }
    setFocusLocation({ ...block, zoom: 15 });
    setHighlightList([]);
    if (!blockMarker || blockMarker.some(s => s.type === 'school')) {
      setBlockMarker([{ ...block, props: [] }]);
    } else {
      const newList = blockMarker.filter(p => p.postal !== block.postal);
      if (newList.length === blockMarker.length) {
        setBlockMarker([...blockMarker, { ...block, props: [] }]);
      } else if (newList.length === 0) {
        setBlockMarker(null);
      } else {
        setBlockMarker(newList)
      }
    }
    if (isMaximized) {
      handleExpand();
    }
  };

  const onSchoolSelect = (school) => {
    if (!school) {
      setBlockMarker(null);
      return;
    }
    setFocusLocation({ ...school, zoom: 15 });
    setHighlightList([]);
    if (!blockMarker || blockMarker.some(p => p.type !== 'school')) {
      setBlockMarker([{ ...school, schools: [] }]);
    } else {
      const newList = blockMarker.filter(p => p.id !== school.id);
      if (newList.length === blockMarker.length) {
        setBlockMarker([...blockMarker, { ...school, schools: [] }]);
      } else if (newList.length === 0) {
        setBlockMarker(null);
      } else {
        setBlockMarker(newList);
      }
    }
    if (isMaximized) {
      handleExpand();
    }
  };

  const unpackCsv = (data) => ({
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "ts": data.t,
    "features": data.split('\n').map(line => {
      const elements = line.split('|');
      const lat = elements[1];
      const lng = elements[2];
      return {
        "type": "Feature",
        "properties": {
          "id": unravelId(elements[0]), // project name + street
          "px": parseInt(elements[10]) > 0 ? parseFloat(elements[3]) : -1,  // profitability %
          "tx": parseInt(elements[4]),  // transaction count
          "p6": parseInt(elements[5]),  // annualized gain > 6%
          "am": elements[6],  // tenure date
          "cd": elements[7],  // completion year
          "fp": parseFloat(elements[8]),  // fair price
          "fd": parseFloat(elements[9]),  // fair price - avg price
          "fx": parseFloat(elements[8]) > 0 ? (parseFloat(elements[9]) / parseFloat(elements[8]) * 100) : -1,
          "rx": parseInt(elements[10]), // resale transaction count
          "tp": elements[11],  // property types
          "ms": elements[12] !== '' ? MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(elements[12])].label] : null,  // market segment
          "pa": elements[13] !== '' ? REGIONS[elements[13].split(',')[0]]['areas'][elements[13].split(',')[1]] : null,  // planning area
          "dd": elements[14],  // district
          "tt": elements[15],  // tenure type
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            lng,
            lat,
            0
          ]
        }
      }
    })
  });

  const generateIdMapFromUnpackedData = (data) => {
    const mapping = {};
    data.features.forEach((feature, i) => {
      mapping[feature.properties.id] = i;
    });
    return mapping;
  };

  const loadMapData = () => {
    downloadFileUrl('map', 'dmap9', 'json', (url) => {
      fetch(url)
      .then(resp => resp.text())
      .then(txt => {
        const mapJson = unpackCsv(txt);
        setData(mapJson);
        setRawData(mapJson);
        setIdMapping(generateIdMapFromUnpackedData(mapJson));

        const properties = mapJson.features.map(f => ({
          id: f.properties.id,
          lat: f.geometry.coordinates[1],
          lng: f.geometry.coordinates[0],
          area: f.properties.pa,
          region: f.properties.ms,
          district: f.properties.dd,
          transactions: f.properties.tx,
          profitable: f.properties.px
        }));

        const areas = PLANNING_AREAS.map(a => ({
          id: a.name.en,
          lat: a.coordinates.latitude,
          lng: a.coordinates.longitude,
          properties: properties?.filter(p => p.area === a.name.en)?.sort((a, b) => b.profitable - a.profitable),
        })).filter(a => a.properties?.length !== 0);
        setPlanningAreas(areas);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log('test???', e)
        setErr('Failed to load map');
      })
    }, (err) => {
      setLoading(false);
      setErr('Failed to get map URL');
    });
  };

  const onMapBoundsChange = (bounds) => {
    console.log('test???', bounds)
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      {err
        && <section className="layout-pt-sm">
          <div className="container">
            <div className="alert alert-danger" role="alert">
              {err}
            </div>
          </div>
        </section>
      }

      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!loading
        && <div className="py-10 bg-dark-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <FilterBox
                  onSearchLocation={onSearchLocation}
                  hideClickHint
                  disableEnter
                />
              </div>
            </div>
          </div>
        </div>
      }

      {!loading && !err
        && <section className={noSelectClass(user)}>
          <div className="proj-bg-map">
            <DetailMap
              id={id}
              user={user}
              mapMediaStyle={mapMediaStyle}
              initialZoom={initialZoom}
              resultPropLocation={resultPropLocation}
              resultAllLocations={resultAllLocations}
              nearbyProperties={nearbyProperties}
              comparable={comparable}
              schools={schools}
              focusLocation={focusLocation}
              highlightList={highlightList}
              setHighlightList={setHighlightList}
              blockMarker={blockMarker}
              clearBlockMarker={() => setBlockMarker(null)}
              isMaximized={isMaximized}
              isMinimized={isMinimized}
              goToProperty={goToProperty}
              defaultZoom={FULL_ZOOM}
              onMapBoundsChange={onMapBoundsChange}
              planningAreas={planningAreas}
            />

            {isMinimized && resultPropLocation && (
              <button
                className="button -sm -dark-1 bg-blue-1 text-white rounded-100 text-12 px-10 py-5 proj-expand-btn"
                onClick={handleExpand}
              >
                VIEW {resultPropLocation.name} DETAILS
              </button>
            )}

            {!isMinimized && id && (
              <div className={`modal-container ${
                isMaximized ? "modal-full modal-container-fixed" : (
                  mediaMatches ? "" : "modal-container-fixed"
                )
              }`}>

                {mediaMatches
                  && <div className="d-flex justify-content-between text-10 text-light-1">
                    <div className="p-2 py-0"></div>
                    <div
                      className={`p-2 py-0 mb-10 cursor-pointer ${
                        isMaximized ? '' : 'rotate-180'
                      }`}
                      onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                    >
                      <i className="icon-chevron-sm-down py-5" />
                    </div>
                    <div
                      className="p-2 pt-5 pb-0 cursor-pointer"
                      onClick={() => handleMinimize()}
                    >
                      <i className="icon-close py-5" />
                    </div>
                  </div>
                }

                {!mediaMatches
                  && <div className="d-flex justify-content-end text-10 text-light-1">
                    <div
                      className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                      onClick={() => handleMinimize()}
                    >
                      <i className="icon-close" />
                    </div>
                  </div>
                }

                <TabButtons
                  tabs={TABS}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  onSwitchTab={onSwitchTab}
                  session={session}
                  projectName={projectName}
                  streetName={streetName}
                />

                <div className="modal-body watermark d-flex">
                  {!mediaMatches
                    && <div
                      className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                      onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                    >
                      <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                        <i className="icon-chevron-sm-down px-5" />
                      </div>
                    </div>
                  }

                  <div
                    id="project-details-modal"
                    className={`p-2 modal-scoll-content ${
                      isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                    }`}
                  >

                    <ScrollToTop />

                    <>
                      {selectedTab === 'overview' && loadedMainData
                        && <OverviewTab
                          user={user}
                          result={result}
                          perfResults={perfResults}
                          resultPropLocation={resultPropLocation}
                          screenDim={screenDim}
                          isMaximized={isMaximized}
                        />
                      }
                      {selectedTab === 'transactions' && loadedTableData
                        && <TransactionsTab
                          user={user}
                          result={result}
                          txResults={txResults}
                          profitResults={profitResults}
                          rentalResults={rentalResults}
                          comparable={comparable}
                          screenDim={screenDim}
                          isMaximized={isMaximized}
                        />
                      }
                      {selectedTab === 'rental' && loadedTableData
                        && <RentalTab
                          user={user}
                          rentalResults={rentalResults}
                          screenDim={screenDim}
                          isMaximized={isMaximized}
                        />
                      }
                      {selectedTab === 'compare' && loadedTableData
                        && <CompareTab
                          id={id}
                          user={user}
                          compsResults={compsResults}
                          screenDim={screenDim}
                          isMaximized={isMaximized}
                        />
                      }
                      {selectedTab === 'nearby' && loadedMainData
                        && <NearbyTab
                          user={user}
                          id={id}
                          schools={schools}
                          resultAllLocations={resultAllLocations}
                          nearbyProperties={nearbyProperties}
                          comparable={comparable}
                          onClickPlace={onClickPlace}
                          highlightList={highlightList}
                          clearHighlightList={() => setHighlightList([])}
                          isMaximized={isMaximized}
                          blockMarker={blockMarker}
                          onBlockSelect={onBlockSelect}
                          clearBlockSelect={() => setBlockMarker(null)}
                          onSchoolSelect={onSchoolSelect}
                          goToProperty={goToProperty}
                        />
                      }
                      {selectedTab === 'units' && loadedTableData
                        && <UnitsTab
                          user={user}
                          id={id}
                          txResults={txResults}
                          hasBlocks={hasBlocks}
                          isMaximized={isMaximized}
                          mediaMatches={mediaMatches}
                        />
                      }
                      {selectedTab === 'discuss'
                        && <DiscussionTab
                          user={user}
                          id={id}
                        />
                      }
                      {TABS_REQUIRE_TABLE_DATA.has(selectedTab) && !loadedTableData
                        && <div className="loader-container">
                          <Loader />
                        </div>
                      }
                      {TABS_REQUIRE_MAIN_DATA.has(selectedTab) && !loadedMainData
                        && <div className="loader-container">
                          <Loader />
                        </div>
                      }
                    </>
                  </div>
                </div>

                <div className="footer-block" />

              </div>
            )}
            
          </div>
        </section>
      }

    </>
  );
};

ProjectMapV2Page.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

ProjectMapV2Page.defaultProps = {
  user: null,
};

export default ProjectMapV2Page;
