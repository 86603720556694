import PropTypes from "prop-types";
// import Loader from "../Loader";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "bootstrap";

const SearchBar = (props) => {
  const {
    searchValue,
    selectedItem,
    onInput,
    onSelect,
    loading,
    locationSearchContent,
    onKeyDown,
    hideResults,
    setHideResults,
    hideClickHint,
    disableEnter,
    shortHeight,
    isSchool
  } = props;

  const dropdownToggleRef = useRef(null);
  const dropdownInstanceRef = useRef(null);

  useEffect(() => {
    if (dropdownToggleRef.current) {
      dropdownInstanceRef.current = new Dropdown(dropdownToggleRef.current);
    }
  }, []);

  useEffect(() => {
    if (searchValue && dropdownInstanceRef.current) {
      dropdownInstanceRef.current.show(); // Show dropdown when input is typed
    }
  }, [searchValue]);

  const [rowHighlight, setRowHighlight] = useState(null);

  const onSearchResults = () => {
    onKeyDown();
    setHideResults?.(true);
  };

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (rowHighlight !== null) {
        onSelect(locationSearchContent[rowHighlight]);
      } else {
        onSearchResults();
      }
    } else if (e.key === 'ArrowUp' || e.key === 'Up') {
      if (locationSearchContent.length > 0) {
        const lastIdx = locationSearchContent.length - 1;
        const newIdx = rowHighlight === null ? lastIdx : (rowHighlight > 0 ? (rowHighlight - 1) : lastIdx);
        setRowHighlight(newIdx);
        scrollToRow(newIdx);
      }
    } else if (e.key === 'ArrowDown' || e.key === 'Down') {
      if (locationSearchContent.length > 0) { 
        const newIdx = rowHighlight === null ? 0 : (rowHighlight + 1) % locationSearchContent.length;
        setRowHighlight(newIdx);
        scrollToRow(newIdx);
      }
    }
  };

  const scrollToRow = (idx) => {
    const row = document.getElementById(`project-search-result-${idx}`);
    if (row) {
      row.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  };

  const onUserClick = () => {
    setHideResults?.(false);
  };

  const onInputChange = (e) => {
    setHideResults?.(false);
    onInput(e.target.value);
    setRowHighlight(null);
  };

  // const digitizeBoolArrs = (arr) => arr.reduce((a, b) => a + (b ? '1' : '0'), '');

  // console.log(
  //   'search-state',
  //   searchValue,
  //   digitizeBoolArrs([loading, hideResults, !hideResults && !loading && searchValue && locationSearchContent?.length > 0])
  // );

  return (
    <>
      <div
        className="searchMenu-loc px-10 py-0 bg-white rounded-4 js-form-dd js-liverSearch"
        onScroll={e => e.stopPropagation()}
        onWheel={e => e.stopPropagation()}
        onTouchMove={e => e.stopPropagation()}
      >
        <div
          ref={dropdownToggleRef}
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          data-bs-offset="0,22"
        >
          <div className="text-15 text-light-1 ls-2 lh-16">
            <input
              autoComplete="off"
              type="search"
              placeholder={isSchool ? 'Enter school name' : 'Enter project or street name'}
              className="js-search js-dd-focus"
              value={searchValue}
              onChange={(e) => onInputChange(e)}
              onKeyDown={onInputKeyDown}
              onClick={onUserClick}
            />
          </div>
        </div>

        <div
          className="shadow-2 dropdown-menu propsearch-dropdown"
        >
          {!hideResults && searchValue
            && <>
              {/* {loading
                && <Loader />
              } */}

              {!loading && searchValue.length > 2
                &&  <div className={`bg-white px-10 py-10 sm:px-0 sm:py-15 rounded-4 project-search-dropdown ${
                  shortHeight ? 'project-search-dropdown-short' : ''
                }`}>
                  {!locationSearchContent?.length //&& searchValue.length > 2
                    && <p className="text-15 px-20 py-10">No results found</p>
                  }
                  {/* {!disableEnter && !hideClickHint && !isSuggested
                    && <div
                      className="mb-20 text-14 text-center text-blue-1 cursor-pointer"
                      onClick={() => onSearchResults()}
                    >
                      Click here or press enter to view matching properties
                    </div>
                  }
                  {!hideClickHint && isSuggested
                    && <div
                      className="mb-20 text-14 text-center text-blue-1"
                    >
                      Did you mean the following properties?
                    </div>
                  } */}
                  <ul className={`y-gap-5 js-results pt-10 ${(hideClickHint || disableEnter) ? '' : 'border-top-light'}`}>
                    {locationSearchContent.map((item, idx) => (
                      <li
                        id={`project-search-result-${idx}`}
                        className={`-link d-block col-12 text-left rounded-4 px-10 py-10 js-search-option mb-1 ${
                          selectedItem && selectedItem.id === item.id ? "active" : ""
                        } ${rowHighlight === idx ? 'bg-blue-2' : ''}`}
                        key={item.id}
                        role="button"
                        onClick={() => onSelect(item)}
                      >
                        <div className="d-flex text-14">
                          <div className="icon-location-2 text-light-1 pt-4" />
                          <div className="ml-10">
                            <div className="lh-12 fw-500 js-search-option-target">
                              {item.name}
                            </div>
                            <div className="text-12 lh-12 text-light-1 mt-5">
                              {item.address}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

SearchBar.propTypes = {
  searchValue: PropTypes.string,
  selectedItem: PropTypes.object,
  onInput: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  locationSearchContent: PropTypes.array,
  onKeyDown: PropTypes.func.isRequired,
  hideResults: PropTypes.bool,
  setHideResults: PropTypes.func,
  hideClickHint: PropTypes.bool,
  isSuggested: PropTypes.bool,
  disableEnter: PropTypes.bool,
  shortHeight: PropTypes.bool,
};

SearchBar.defaultProps = {
  searchValue: null,
  selectedItem: null,
  loading: false,
  locationSearchContent: [],
  hideResults: false,
  setHideResults: null,
  hideClickHint: false,
  isSuggested: false,
  disableEnter: false,
  shortHeight: false,
};

export default SearchBar;
