import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import { MAP_THEMES } from "@/utils/areas";
import { useState } from "react";

const MapStyleSelector = ({
  theme, setTheme, showFull,
}) => {
  const [showFilters, setShowFilters] = useState(showFull || window.innerWidth > 960);

  return (
    <>
      {showFilters
        && <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="maptheme-selection-tooltip"
            data-tooltip-html="Select map theme"
            data-tooltip-variant="dark"
            data-tooltip-place="left"
          >
            <span className="js-dropdown-title">
              <span className="text-blue-1 fw-600">MAP</span>&nbsp;&nbsp; 
              <span className="js-dropdown-pri">{theme}</span>
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown  dropdown-menu">
            <div className="text-13 y-gap-15 js-dropdown-list">
              {MAP_THEMES.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      theme === option.label ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setTheme(option.label);
                      document.querySelector(".js-dropdown-pri").textContent = option.label;
                    }}
                  >
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

      {!showFull
        && <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector filter-mobile-btn">
          <div
            className={`button -sm -dark-1 ${showFilters ? 'bg-blue-1 text-white' : 'bg-white text-blue-1'} border-light rounded-100 text-12 px-10 py-10`}
            onClick={() => setShowFilters(!showFilters)}
          >
            <span className="js-dropdown-title">
              <i className="icon icon-day-night text-12" />
            </span>
          </div>
        </div>
      }
      
      <Tooltip id="maptheme-selection-tooltip" style={{ zIndex: 999 }} />
    </>
  );
};

MapStyleSelector.propTypes = {
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  showFull: PropTypes.bool,
};

MapStyleSelector.defaultProps = {
  showFull: false,
};

export default MapStyleSelector;
