import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { capitalize } from "@/utils/text";
import {
  getUserClaims,
  isAdminTier,
  isFreeTier,
  isProTier,
  isSuperTier,
  isTrialTier
} from "@/utils/user";

const PLAN_FREE = 'free';
const PLAN_TRIAL = 'trial';
const PLAN_PRO = 'pro';
const PLAN_SUPER = 'super';
const PLAN_ADMIN = 'admin';

const PlanList = (props) => {
  const navigate = useNavigate();
  const { user } = props;

  const claims = getUserClaims(user);
  const isUserFreeTier = isFreeTier(claims);
  const isUserTrialTier = isTrialTier(claims);
  const isUserProTier = isProTier(claims);
  const isUserSuper = isSuperTier(claims);
  const isUserAdmin = isAdminTier(claims);

  const goToBilling = () => {
    navigate('/billing');
  };

  const goToPricing = () => {
    navigate('/pricing');
  };

  const getPlanName = () => {
    if (isUserAdmin) return PLAN_ADMIN;
    if (isUserSuper) return PLAN_SUPER;
    if (isUserProTier) return PLAN_PRO;
    if (isUserTrialTier) return PLAN_TRIAL;
    return PLAN_FREE;
  };

  return (
    <ul className="modalGrid px-30 py-30 sm:px-15 sm:py-15">

      {(isUserAdmin || isUserSuper)
        && <li
          className="modalGrid__item js-item active"
        >
          <div className="py-10 px-15 sm:px-5 sm:py-5 text-center">
            <div className="text-15 lh-15 fw-500 text-dark-1">
              {capitalize(getPlanName())} Tier
            </div>
            <div className="text-14 lh-15 mt-5 js-title">
              Current Plan
            </div>
          </div>
        </li>
      }

      {isUserProTier
        && <li
          className="modalGrid__item js-item active"
          // onClick={goToBilling}
        >
          <div className="py-10 px-15 sm:px-5 sm:py-5 text-center">
            <div className="text-15 lh-15 fw-500 text-dark-1">
              {capitalize(getPlanName())} Tier
            </div>
            <div className="text-14 lh-15 mt-5 js-title">
              {/* Manage Subscription */}
              Current
            </div>
          </div>
        </li>
      }

      {isUserFreeTier
        && <li
          className="modalGrid__item js-item active"
          // onClick={goToPricing}
        >
          <div className="py-10 px-15 sm:px-5 sm:py-5 text-center">
            <div className="text-15 lh-15 fw-500 text-dark-1">
              {capitalize(getPlanName())} Tier
            </div>
            <div className="text-14 lh-15 mt-5 js-title">
              {/* Upgrade to PRO */}
              Current
            </div>
          </div>
        </li>
      }

    </ul>
  );
};

PlanList.propTypes = {
  user: PropTypes.object,
};

export default PlanList;
