import { NA, convertFloorAreaRange } from "./convert";

export const RENTAL_TABLE_HEADERS = [
  {
    Header: 'Project',
    accessor: 'id',
    fixed: "left",
    disableSortBy: true,
    // pageLevelSort: true,
    Cell: (row) => <a
      className="text-blue-1"
      href={`/project/${encodeURIComponent(row.cell.value)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value.split(',')[0]}
    </a>
  },
  {
    Header: 'Address',
    accessor: 'Street Name',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 250,
    Cell: (row) => <a
      className="text-blue-1"
      href={`https://maps.google.com/?q=${row.cell.value}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value}
    </a>
  },
  {
    Header: 'Lease Commence',
    accessor: 'Lease Commencement Date',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 110,
  },
  {
    Header: 'Monthly Rent ($)',
    accessor: 'Monthly Rent ($)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
  },
  {
    Header: 'Unit Rent ($psf)',
    accessor: 'est_rental_psf_sgd',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
  },
  {
    Header: 'Property Type',
    accessor: 'Property Type',
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Floor Area (sft)',
    accessor: 'Floor Area (SQFT)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>{convertFloorAreaRange(row.cell.value)}</div>
  },
  {
    Header: 'No of Bedroom',
    accessor: 'No of Bedroom',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
    Cell: (row) => <div>{(!row.cell.value || row.cell.value === -1) ? NA : row.cell.value}</div>
  },
  {
    Header: 'Market Segment',
    accessor: 'Market Segment',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
];
