import { NA, calculateAge, calculateLeaseRemain } from "@/utils/convert";
import PropTypes from "prop-types";

const Factsheet = ({
  info: item,
  isMaximized,
  screenDim
}) => {
  const getTrendClass = (trend) => {
    if (trend === 0) {
      return '';
    }
    return trend > 0 ? 'text-green-2' : 'text-red-1';
  };

  const getProfitableClass = (item) => {
    const profitable = item?.['perc_profitable'] ?? 0;
    if (profitable > 0) return getTrendClass(1);
    if (profitable < 0) return getTrendClass(-1);
    return getTrendClass(0);
  };

  const generateValue = (value) => {
    if (value === -1) return NA;
    return value ?? NA;
  };

  const isXXL = isMaximized ? screenDim.width >= 1000 : screenDim.width >= 1700;
  const isSuperXXL = isMaximized && screenDim.width >= 1300;
  const xxlGrid = isSuperXXL ? (screenDim.width >= 1700 ? 2 : 3) : (isXXL ? 4 : 6)

  return (
    <>
      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-city-2 mr-10"></i>
            <div className="fw-500">General</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Developer:</span>{item['Developer'] ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Property Type:</span>{item['property_types']?.join(', ') ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Completion:</span>{item['latest_completion_date'] ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Total Units:</span>{
                  (!item?.['Total Number of Units in Project'] || item['Total Number of Units in Project'] < 0)
                    ? NA : item['Total Number of Units in Project']
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Unit Size:</span>{
                  item['min_sqft_size'] && item['max_sqft_size']
                    ? (
                      item['min_sqft_size'] !== item['max_sqft_size']
                        ? `${
                            item['min_sqft_size'].toLocaleString()
                          } - ${
                            item['max_sqft_size'].toLocaleString()
                          } sft`
                        : `${
                            item['min_sqft_size'].toLocaleString()
                          } sft`
                    )
                    : NA
                }
              </div>
            </div>

            <div className="col-12">
              <div className="d-flex px-0 py-0">
                <div className="proj-lw p-2 px-0 py-0">Tenure:</div>
                <div className="p-2 px-0 py-0">{item['tenure_available']?.map((t, i) => (
                  <>
                    {i > 0 && <br />}
                    {t}
                  </>
                )) ?? NA}</div>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Age:</span>{calculateAge(item['latest_tenure_start_date'], item['latest_completion_date'])}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Lease Remaining:</span>{
                  calculateLeaseRemain(
                    item['tenure_years_available'],
                    item['latest_tenure_start_date']
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-price-label mr-10"></i>
            <div className="fw-500">Profitability</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Profitable %:</span><span className={getProfitableClass(item)}>
                  {
                    (item?.['perc_profitable'] ?? 0).toFixed(0)
                  }%</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Profitable Transactions:</span><span className="text-green-2">{
                  item?.['num_profitable_transactions'] ?? 0
                }</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">&gt; 6% Annualized:</span><span className="text-green-2">{
                  item?.['num_profitable_above_6_perc'] ?? 0
                }</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Unprofitable Transactions:</span><span className="text-red-1">{
                  (item?.['num_unprofitable'] ?? 0) - (item?.['aborted'] ?? 0)
                }</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Total Transactions:</span>{
                  item?.['total_num_transactions'] ?? 0
                }
              </div>
            </div>
            
            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Total Profits:</span><span className={getTrendClass(item['total_profits'])}>{
                  item['total_profits'] < 0 ? '-' : ''
                }${
                  (Math.abs(item['total_profits'] ?? 0)).toLocaleString()
                }</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-usd mr-10"></i>
            <div className="fw-500">Transactions</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Sold At Launch (updated mthly):</span><span className={
                  item['perc_sold_at_launch'] ? 'text-green-2' : 'text-dark-1'
                }>{
                  item['perc_sold_at_launch'] ? item['perc_sold_at_launch'].toFixed(0) : NA
                }%</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">HDB Buyers:</span><span className="text-green-2">{
                  (item['perc_HDB_buyer'] ?? 0).toFixed(0)
                }%</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Developer Unsold:</span>{
                  item?.['Total Number of Unsold Units'] ?? 0
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Total Transacted:</span>${
                  `${item?.['total_amount_transacted_formatted']}`
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Last Transacted:</span>{
                  item?.['last_transacted_date'] ?? NA
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-compass mr-10"></i>
            <div className="fw-500">Location</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Market Segment:</span>{item?.['Market Segment'] ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Area:</span>{item?.['Planning Area']}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Region:</span>{item?.['Planning Region']}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">District:</span>{item?.['Postal District']}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Postal Sector:</span>{item?.['Postal Sector']}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-living-room mr-10"></i>
            <div className="fw-500">Rental</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Past 6mths Avg PSF Rental:</span>{
                  generateValue(item?.['past_6_mth_avg_psf_rental']) === NA
                    ? NA
                    : `$${item['past_6_mth_avg_psf_rental'].toFixed(2)}`
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">No. of Rentals in Past 6mths:</span>{
                  generateValue(item?.['past_6_mth_count_total_rentals']) === NA
                    ? NA
                    : item['past_6_mth_count_total_rentals'].toFixed(0)
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">Past 1mth Avg PSF Rental:</span>{
                  generateValue(item?.['past_1_mth_avg_psf_rental']) === NA
                    ? NA
                    : `$${item['past_1_mth_avg_psf_rental'].toFixed(2)}`
                }
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw-3">No. of Rentals in Past 1mth:</span>{
                  generateValue(item?.['past_1_mth_count_total_rentals']) === NA
                    ? NA
                    : item['past_1_mth_count_total_rentals'].toFixed(0)
                }
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}></div>

    </>
  );
};

Factsheet.propTypes = {
  info: PropTypes.object,
};

export default Factsheet;
