import { formatDistanceLabel, generateGMapUrl } from "@/utils/convert";
import PropTypes from "prop-types";

// const MAX_AMENITY_LIST = 200;

const generateSchoolSubsections = (locations, pri, sec) => {
  const preSchs = [];
  const priSchs = [...(pri ?? [])];
  const secSchs = [...(sec ?? [])];
  const others = [];
  const defaultSchs = [
    ...priSchs.map(s => s.name.toLowerCase()),
    ...secSchs.map(s => s.name.toLowerCase())
  ];
  locations.filter(location =>
    !pri?.some(s => location.name.toLowerCase().startsWith(s.name))
      && !sec?.some(s => location.name.toLowerCase().startsWith(s.name))
  ).forEach(location => {
    const name = location.name.toLowerCase();
    if (defaultSchs.some(s => name.startsWith(s))) {
      return;
    }
    if (name.includes('preschool')) {
      preSchs.push(location);
    } /*else if (name.includes('primary')) {
      priSchs.push(location);
    } else if (name.includes('secondary') || name.includes('high')) {
      secSchs.push(location);
    }*/ else {
      others.push(location);
    }
  });
  return [
    {
      id: 1,
      title: 'Preschools',
      img: '/img/general/icons/am_sch_pre.png',
      list: preSchs,
      includes: ['preschool']
    },
    {
      id: 2,
      title: 'Primary Schools',
      img: '/img/general/icons/am_sch_pri.png',
      list: priSchs
    },
    {
      id: 3,
      title: 'Secondary Schools',
      img: '/img/general/icons/am_sch_sec.png',
      list: secSchs
    },
    {
      id: 4,
      title: 'Others',
      img: '/img/general/icons/am_sch_misc.png',
      list: others
    },
  ].filter(sub => sub.list.length > 0);
};

const generateShoppingSubsections = (locations) => {
  const marts = [];
  const malls = [];
  const others = [];
  locations.forEach(location => {
    const name = location.name.toLowerCase();
    if (name.includes('supermarket')) {
      marts.push(location);
    } else if (name.includes('shopping') || name.includes('mall')) {
      malls.push(location);
    } else {
      others.push(location);
    }
  });
  return [
    {
      id: 1,
      title: 'Supermarkets',
      img: '/img/general/icons/am_shop_mart.png',
      list: marts
    },
    {
      id: 2,
      title: 'Shopping Malls',
      img: '/img/general/icons/am_shop_mall.png',
      list: malls
    },
    {
      id: 3,
      title: 'Others',
      img: '/img/general/icons/am_shop_misc.png',
      list: others
    },
  ].filter(sub => sub.list.length > 0);
};

const generateSportsSubsections = (locations) => {
  const gyms = [];
  const sports = [];
  const stadiums = [];
  const others = [];
  locations.forEach(location => {
    const name = location.name.toLowerCase();
    if (name.includes('gym')) {
      gyms.push(location);
    } else if (name.includes('complex')) {
      sports.push(location);
    } else if (name.includes('stadium')) {
      stadiums.push(location);
    } else {
      others.push(location);
    }
  });
  return [
    {
      id: 1,
      title: 'Gyms',
      img: '/img/general/icons/am_sp_gym.png',
      list: gyms
    },
    {
      id: 2,
      title: 'Sports Complex',
      img: '/img/general/icons/am_sp_complex.png',
      list: sports
    },
    {
      id: 3,
      title: 'Stadium',
      img: '/img/general/icons/am_sp_stadium.png',
      list: stadiums
    },
    {
      id: 4,
      title: 'Others',
      img: '/img/general/icons/am_shop_misc.png',
      list: others
    },
  ].filter(sub => sub.list.length > 0);
};

const Surroundings = ({
  locations,
  onClickPlace,
  highlightList,
  schools
}) => {
  const priSchs = schools.origin.filter(s => s.level === 'primary');
  const secSchs = schools.origin.filter(s => s.level === 'secondary');

  const surroundingsContent = [
    {
      id: 1,
      items: [
        {
          id: 1,
          title: "Shopping",
          icon: "icon-nearby",
          // list: locations.filter(location => location['type'] === 'Shopping'), //.slice(0, MAX_AMENITY_LIST),
          subsections: generateShoppingSubsections(locations.filter(location => location['type'] === 'Shopping'))
        },
      ],
    },
    {
      id: 2,
      items: [
        {
          id: 1,
          title: "Transport",
          icon: "icon-airplane",
          list: locations.filter(location => location['type'] === 'Transport'), //.slice(0, MAX_AMENITY_LIST),
        }
      ],
    },
    {
      id: 3,
      items: [
        {
          id: 1,
          title: "Schools",
          icon: "icon-ticket",
          // list: locations.filter(location => location['type'] === 'Schools'), //.slice(0, MAX_AMENITY_LIST),
          subsections: generateSchoolSubsections(locations.filter(location => location.type === 'Schools'), priSchs, secSchs)
        },
      ],
    },
    {
      id: 4,
      items: [
        {
          id: 1,
          title: "Sports",
          icon: "icon-ticket",
          // list: locations.filter(location => location['type'] === 'Sports'), //.slice(0, MAX_AMENITY_LIST),
          subsections: generateSportsSubsections(locations.filter(location => location['type'] === 'Sports'), priSchs, secSchs)
        },
      ],
    },
  ];

  const checkListEmpty = (c) => c.items[0].list?.length > 0 || c.items[0].subsections?.length > 0;
  const hasAllCategories = surroundingsContent.every(checkListEmpty);
  const displayedContent = surroundingsContent.filter(checkListEmpty);

  const generateName = (id, name) => {
    if (id !==3) {
      return name;
    }
    if (name?.indexOf(' Primary ') !== -1) {
      const chunks = name?.split(' Primary ');
      if (chunks.length === 2) {
        return (
          <span>{chunks[0]}<span className="text-green-2 fw-500"> Primary </span>{chunks[1]}</span>
        );
      }
    }
    return name;
  };

  const isXl = window.matchMedia("(min-width: 1200px)").matches;

  const showItem = (val) => highlightList.length === 0
    || highlightList.find(item => (item.id && val.id) ? (item.id === val.id) : (item.name === val.name));

  return (
    <>
      {displayedContent.map((item) => (
        <div className="col-xl-6 col-lg-12 col-md-12" key={item.id}>
          {item?.items?.map((single) => (
            <div className="mb-20 md:mb-10" key={single.id}>
              <div className="d-flex items-center mb-10">
                <i className="icon-compass text-20 mr-10"></i>
                <div className="text-16 fw-500">{single.title}</div>
              </div>

              <div className={`row y-gap-10 x-gap-0 px-10 py-0 ${
                isXl ? 'surrounding-fh' : ''
              }`}>
                {single.subsections?.map((subsection) => (
                  <>
                  <div className="d-flex items-center py-10 border-bottom-light stick bg-white">
                    <img className="am-ic mr-10" src={subsection.img} />
                    <div className="text-16 fw-500">{subsection.title}</div>
                  </div>
                  {subsection.list.map((val, i) => (
                    <div className="col-12 border-bottom-light" key={i}>
                      <div
                        className="row items-center justify-between"
                        style={{ opacity: showItem(val) ? 1 : 0.3 }}
                      >
                        <div className="col-9">
                          <div
                            className="text-13 hover-blue-row cursor-pointer"
                            onClick={() => onClickPlace?.(val)}
                          >
                            {/* <a href={generateGMapUrl(val.name, val.lat, val.lng)} target="_blank" rel="noopener noreferrer">
                              {generateName(item.id, val.name)}
                            </a> */}
                            {generateName(item.id, val.name)}
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="text-13 text-right">
                            {formatDistanceLabel(val.distance)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {subsection.list.length > 0 && <div className="mt-10"></div>}
                  </>
                ))}
                
                {single?.list?.map((val, i) => (
                  <div className="col-12 border-top-light" key={i}>
                    <div
                      className="row items-center justify-between"
                      style={{ opacity: showItem(val) ? 1 : 0.3 }}
                    >
                      <div className="col-9">
                        <div
                          className="text-13 hover-blue-row cursor-pointer"
                          onClick={() => onClickPlace?.(val)}
                        >
                          {/* <a href={generateGMapUrl(val.name, val.lat, val.lng)} target="_blank" rel="noopener noreferrer">
                            {generateName(item.id, val.name)}
                          </a> */}
                          {generateName(item.id, val.name)}
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="text-13 text-right">
                          {formatDistanceLabel(val.distance)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

Surroundings.propTypes = {
  locations: PropTypes.array,
};

Surroundings.defaultProps = {
  locations: [],
};

export default Surroundings;
