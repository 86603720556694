import { CHART_GREEN_BG, CHART_ORANGE_BG, CHART_RED_BG } from "@/utils/colors";
import {
  dateStrToDateObj,
  dateStrToMsec,
  formatShortDate,
  getTsYearsAgo
} from "@/utils/time";
import { noSelectClass } from "@/utils/user";
import TransactionTable from "@/components/project/TransactionTable";
import ProfitTable from "@/components/project/ProfitTable";
import MainChart from "@/components/project/MainChart";
import DistributionChart from "@/components/project/DistributionChart";
import AnnotatedMainChart from "@/components/project/AnnotatedChart";
import {
  PERIOD_TYPE_10Y,
  PERIOD_TYPE_12M,
  PERIOD_TYPE_2Y,
  PERIOD_TYPE_3Y,
  PERIOD_TYPE_5Y,
  PERIOD_TYPE_ALL,
  VALUE_TYPE_PERC,
  generatePropDistributionChart
} from "@/utils/chart";
import { useState } from "react";
import Sidebar from "../common/Sidebar";
import {
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FILTER_FULL_SALE_TYPE,
  FILTER_GAIN,
  FILTER_PRICE,
  FILTER_PROPERTY_TYPE,
  FILTER_SIZE,
  FILTER_TRANSACTION_DATE,
  FILTER_UNIT_PRICE,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import { getPropTypeFilterSelected } from "@/utils/convert";

const FLOOR_COLORS = [
  {
    label: 'low',
    color: CHART_GREEN_BG,
  },
  {
    label: 'mid',
    color: CHART_ORANGE_BG,
  },
  {
    label: 'high',
    color: CHART_RED_BG,
  },
];

const TX_FILTER_OPTIONS = [
  FILTER_TRANSACTION_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_SIZE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE
  // FILTER_PROPERTY_TYPE
];

const PROFIT_FILTER_OPTIONS = [
  ...TX_FILTER_OPTIONS,
  FILTER_GAIN
];

const TransactionsTab = ({
  user,
  txResults,
  profitResults,
  comparable,
  isMaximized,
  screenDim
}) => {
  const profitableData = profitResults?.filter(row => row['Annualized Profit Perc'] > 0);
  const unprofitableData = profitResults?.filter(row =>
    (row['Type of Sale'] !== 'New Sale' && row['Annualized Profit Perc'] <= 0)
      || (row['Type of Sale'] === 'New Sale' && row['Annualized Profit Perc'] < 0)
  );
  const abortedData = profitResults?.filter(row => row['Type of Sale'] === 'New Sale' && row['Annualized Profit Perc'] === 0);
  
  const params = new URLSearchParams();
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedTableFilter, setSelectedTableFilter] = useState(null);
  const [filteredTxResults, setFilteredTxResults] = useState(txResults);
  const [profitableTxResults, setProfitableTxResults] = useState(profitableData);
  const [unprofitableTxResults, setUnprofitableTxResults] = useState(unprofitableData);
  const [abortedTxResults, setAbortedTxResults] = useState(abortedData);

  const [txFilterSelected, setTxFilterSelected] = useState(initializeFilters(TX_FILTER_OPTIONS, params));
  const [profitableSelected, setProfitableSelected] = useState(initializeFilters(PROFIT_FILTER_OPTIONS, params));
  const [unprofitableSelected, setUnprofitableSelected] = useState(initializeFilters(PROFIT_FILTER_OPTIONS, params));
  const [abortedSelected, setAbortedSelected] = useState(initializeFilters(PROFIT_FILTER_OPTIONS, params));

  const [chartFloorSelect, setChartFloorSelect] = useState([]);
  const [psfChartData, setPsfChartData] = useState(txResults);

  // const PERIOD_LIMITS = {
  //   [PERIOD_TYPE_ALL]: 0,
  //   [PERIOD_TYPE_12M]: getTsYearsAgo(1),
  //   [PERIOD_TYPE_2Y]: getTsYearsAgo(2),
  //   [PERIOD_TYPE_3Y]: getTsYearsAgo(3),
  //   [PERIOD_TYPE_5Y]: getTsYearsAgo(5),
  //   [PERIOD_TYPE_10Y]: getTsYearsAgo(10),
  // };

  // const PERIOD_SELECTIONS = [
  //   {
  //     label: 'All Time',
  //     value: PERIOD_TYPE_ALL,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_ALL]
  //   },
  //   {
  //     label: 'Past 1 Year',
  //     value: PERIOD_TYPE_12M,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_12M]
  //   },
  //   {
  //     label: 'Past 2 Years',
  //     value: PERIOD_TYPE_2Y,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_2Y]
  //   },
  //   {
  //     label: 'Past 3 Years',
  //     value: PERIOD_TYPE_3Y,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_3Y]
  //   },
  //   {
  //     label: 'Past 5 Years',
  //     value: PERIOD_TYPE_5Y,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_5Y]
  //   },
  //   {
  //     label: 'Past 10 Years',
  //     value: PERIOD_TYPE_10Y,
  //     filter: (ts) => ts >= PERIOD_LIMITS[PERIOD_TYPE_10Y]
  //   },
  // ];

  // const [timePsfChart, setTimePsfChart] = useState(PERIOD_SELECTIONS[0]);
  // const [timeProfitChart, setProfitChart] = useState(PERIOD_SELECTIONS[0]);
  // const [timeDistributionChart, setTimeDistributionChart] = useState(PERIOD_SELECTIONS[0]);

  const getFloorType = (floor) => {
    if (floor > midFloorMax) return 'high';
    if (floor > lowFloorMax) return 'mid';
    return 'low';
  };

  const getFloorColor = (floor) => {
    if (!hasFloorRange) return CHART_GREEN_BG;
    const floorType = getFloorType(floor);
    if (floorType === 'high') return CHART_RED_BG;
    if (floorType === 'mid') return CHART_ORANGE_BG;
    return CHART_GREEN_BG;
  };

  const getFloorRangeDesc = (floorType) => {
    if (floorType === 'high') return `${midFloorMax + 1} - ${maxFloor}`;
    if (floorType === 'mid') return `${lowFloorMax + 1} - ${midFloorMax}`;
    return `1 - ${lowFloorMax}`;
  };

  const maxFloor = Math.max(...(txResults ?? []).map(r => r['floor']), 0);
  const hasFloorRange = maxFloor >= 3;
  const lowFloorMax = Math.floor(maxFloor * 0.33);
  const midFloorMax = Math.floor(maxFloor * 0.66);
  
  const chartHeight = screenDim.height < 800 ? null : 250;

  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  const onApplyFilter = () => {
    if (selectedTableFilter.startsWith('tx-')) {
      const filteredData = filterData(txFilterSelected, txResults);
      setFilteredTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-p') {
      const filteredData = filterData(profitableSelected, profitableData);
      setProfitableTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-m') {
      const filteredData = filterData(unprofitableSelected, unprofitableData);
      setUnprofitableTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-a') {
      const filteredData = filterData(abortedSelected, abortedData);
      setAbortedTxResults(filteredData);
    }
    
    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('tx-close-button').click();
    }
  };

  const onResetFilter = () => {
    if (selectedTableFilter.startsWith('tx-')) {
      setTxFilterSelected(initializeFilters(TX_FILTER_OPTIONS, params));
    } else if (selectedTableFilter === 'profit-tb-p') {
      setProfitableSelected(initializeFilters(PROFIT_FILTER_OPTIONS, params));
    } else if (selectedTableFilter === 'profit-tb-m') {
      setUnprofitableSelected(initializeFilters(PROFIT_FILTER_OPTIONS, params));
    } else if (selectedTableFilter === 'profit-tb-a') {
      setAbortedSelected(initializeFilters(PROFIT_FILTER_OPTIONS, params));
    }
  };

  const onFilterButtonClick = (name) => {
    if (name?.startsWith('tx-')) {
      setFilterOptions(TX_FILTER_OPTIONS);
      setSelectedTableFilter(name);
    } else if (name?.startsWith('profit-')) {
      setFilterOptions(PROFIT_FILTER_OPTIONS);
      setSelectedTableFilter(name);
    }
  };

  const filterData = (filterSelected, data) => {
    let filteredData = data;

    // filter sale date
    if (hasFilterSelected(FILTER_TRANSACTION_DATE, filterSelected)) {
      filteredData = filteredData.filter(row => {
        const startDate = filterSelected[FILTER_TRANSACTION_DATE.field[0]];
        const endDate = filterSelected[FILTER_TRANSACTION_DATE.field[1]];
        const saleDate = dateStrToMsec(row['Sale Date']);
        if (startDate !== null && saleDate < startDate.getTime()) {
          return false;
        }
        if (endDate !== null && saleDate > endDate.getTime()) {
          return false;
        }
        return true;
      });
    }

    // filter sale type
    if (hasFilterSelected(FILTER_FULL_SALE_TYPE, filterSelected)) {
      const filters = Object.keys(filterSelected[FILTER_FULL_SALE_TYPE.field])
        .filter(o => filterSelected[FILTER_FULL_SALE_TYPE.field][o]);
      filteredData = filteredData.filter(row => {
        return filters.indexOf(row['Type of Sale']) >= 0;
      });
    }

    // filter size
    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      const minSize = hasMin ? filterSelected[FIELD_MIN_SIZE] : null;
      const maxSize = hasMax ? filterSelected[FIELD_MAX_SIZE] : null;
      filteredData = filteredData.filter(row => {
        const size = row['Area (SQFT)'];
        if (maxSize !== null && size > maxSize) return false;
        if (minSize !== null && size < minSize) return false;
        return true;
      });
    }

    // filter price
    if (hasFilterSelected(FILTER_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row['Transacted Price ($)'];
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    // filter unit price
    if (hasFilterSelected(FILTER_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_UNIT_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_UNIT_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row['Unit Price ($ PSF)'];
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    // filter property type
    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      const filters = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
      filteredData = filteredData.filter(row => {
        const propType = row['Property Type'];
        return filters.indexOf(propType) >= 0;
      });
    }

    // filter gain only for profit
    if (selectedTableFilter.startsWith('profit-')) {
      if (hasFilterSelected(FILTER_GAIN, filterSelected)) {
        const hasMin = hasMinRangeSelected(FILTER_GAIN, filterSelected[FILTER_GAIN.field]);
        const hasMax = hasMaxRangeSelected(FILTER_GAIN, filterSelected[FILTER_GAIN.field]);
        const minVal = hasMin ? filterSelected[FILTER_GAIN.field].value.min : null;
        const maxVal = hasMax ? filterSelected[FILTER_GAIN.field].value.max : null;
        filteredData = filteredData.filter(row => {
          const gain = row['Annualized Profit Perc'];
          if (minVal !== null && gain < minVal) return false;
          if (maxVal !== null && gain > maxVal) return false;
          return true;
        });
      }
    }

    return filteredData;
  };

  const setFilterSelected = (filterSelected) => {
    if (selectedTableFilter?.startsWith('tx-')) {
      setTxFilterSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-p') {
      setProfitableSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-m') {
      setUnprofitableSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-a') {
      setAbortedSelected(filterSelected);
    }
  };

  const getFilterSelected = () => {
    if (selectedTableFilter?.startsWith('tx-')) {
      return txFilterSelected;
    }else if (selectedTableFilter === 'profit-tb-p') {
      return profitableSelected;
    } else if (selectedTableFilter === 'profit-tb-m') {
      return unprofitableSelected;
    } else if (selectedTableFilter === 'profit-tb-a') {
      return abortedSelected;
    }
    return {};
  };

  return (
    <>
      <section className="pt-10 pb-20">
        <div className={`${noSelectClass(user)}`}>
          <div className="row">
            <div className="col-12">
              <h3 className="text-18 fw-500">Sales Statistics</h3>
            </div>
          </div>

          <div className="row y-gap-20 chart_responsive mt-10">

            {txResults?.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">PSF Price Trend</h2>
                    {/* <ChartSelect /> */}
                  </div>

                  <div className="pt-10">
                    <AnnotatedMainChart
                      label="Unit Price ($psf)"
                      data={
                        psfChartData.map(row => ({
                          x: dateStrToDateObj(row['Sale Date']).getTime(),
                          y: row['Unit Price ($ PSF)'],
                        }))
                      }
                      colors={
                        maxFloor ? psfChartData.map(row => getFloorColor(row['floor'])) : null
                      }
                      tooltipTitle="PSF Price Trend ($psf)"
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          const area = psfChartData[context.dataIndex]['Area (SQFT)'];
                          const floor = psfChartData[context.dataIndex]['floor'];
                          const labels = [
                            `${formattedDate}: $${context.raw.y.toLocaleString()} psf`,
                            `Area: ${area} sqft`,
                          ];
                          if (hasFloorRange) labels.push(`Floor: ${floor} (${getFloorType(floor)} floor)`);
                          return labels;
                        }
                      }
                      annotationLine={comparable?.fair_psf >= 0 ? {
                        label: `Fair Price ($${comparable.fair_psf.toFixed(2)})`,
                        value: parseFloat(comparable.fair_psf.toFixed(2)),
                      } : null}
                      yAxisLabel="PSF Price ($psf)"
                      height={chartHeight}
                    />
                    {hasFloorRange
                      && <div className="mt-15 text-center">
                        {FLOOR_COLORS.map(p => (
                          <span
                            key={p}
                            className="c-tag text-10 px-10 pr-5 ml-5 cursor-pointer"
                            style={{
                              backgroundColor: p.color,
                              opacity: chartFloorSelect.length === 0 || chartFloorSelect.indexOf(p.label) >= 0 ? 1 : 0.3
                            }}
                            onClick={() => {
                              let newFloorSelect = [];
                              if (chartFloorSelect.indexOf(p.label) >= 0) {
                                newFloorSelect = chartFloorSelect.filter(s => s !== p.label)
                              } else {
                                newFloorSelect = [...chartFloorSelect, p.label];
                              }
                              setChartFloorSelect(newFloorSelect);
                              setPsfChartData(txResults?.filter(row => {
                                const type = getFloorType(row['floor']);
                                return newFloorSelect.length === 0 || newFloorSelect.indexOf(type) >= 0;
                              }));
                            }}
                          >
                            {p.label.charAt(0).toUpperCase() + p.label.slice(1)} Floor ({getFloorRangeDesc(p.label)})
                          </span>
                        ))}
                      </div>
                    }
                    
                  </div>

                </div>
              </div>
            }

            {profitResults?.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">Annualized Profit (%)</h2>
                  </div>

                  <div className="pt-10">
                    <MainChart
                      label="Annualized Profit (%)"
                      data={
                        profitResults.map(row => ({
                          x: dateStrToDateObj(row['Sale Date']).getTime(),
                          y: row['Annualized Profit Perc'],
                        }))
                      }
                      tooltipTitle="Annualized Profit (%)"
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          return `${formattedDate}: ${context.raw.y.toFixed(1)}%`;
                        }
                      }
                      yAxisLabel="Annualized Profit (%)"
                      height={chartHeight}
                    />
                  </div>

                </div>
              </div>
            }

            {profitResults?.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">Annualized Profit (%) Distribution</h2>
                  </div>

                  <div className="pt-10">
                    <DistributionChart
                      label="Annualized Profit (%) Distribution"
                      data={
                        generatePropDistributionChart(
                          VALUE_TYPE_PERC,
                          profitResults.map(row => row['Annualized Profit Perc'])
                        )
                      }
                      tooltipTitleCallback={
                        (context) => `Annualized ${parseFloat(context[0].label) <= 0 ? 'Loss:' : 'Gain:'} up to ${context[0].label}%`
                      }
                      tooltipLabelCallback={
                        (context) => `Number of Transactions: ${context.raw.toFixed(0)}`
                      }
                      xAxisLabel="Annualized Profit (%)"
                      yAxisLabel="Number of Transactions"
                      height={chartHeight}
                    />
                  </div>

                </div>
              </div>
            }

          </div>
        </div>
      </section>
      
      <section className={`pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Transactions</h3>
            </div>
          </div>
          <TransactionTable
            data={filteredTxResults}
            onFilterButtonClick={onFilterButtonClick}
          />
        </div>
      </section>

      <section className={`mt-10 pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Profitable Transactions ({
                profitableTxResults.length
              })</h3>
            </div>
          </div>
          <ProfitTable
            name="profit-tb-p"
            data={profitableTxResults}
            onFilterButtonClick={onFilterButtonClick}
          />
        </div>
      </section>

      <section className={`mt-10 pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Unprofitable Transactions ({
                unprofitableTxResults.length
              })</h3>
            </div>
          </div>
          <ProfitTable
            name="profit-tb-m"
            data={unprofitableTxResults}
            onFilterButtonClick={onFilterButtonClick}
          />
        </div>
      </section>

      <section className={`mt-10 pt-10 pb-50 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Aborted Transactions ({
                abortedTxResults.length
              })</h3>
            </div>
          </div>
          <ProfitTable
            name="profit-tb-a"
            data={abortedTxResults}
            onFilterButtonClick={onFilterButtonClick}
          />
        </div>
      </section>

      <div
        className="offcanvas offcanvas-start filter-bar-nopad mt-50"
        tabIndex="-1"
        id="listingSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            Filter Transactions
          </h5>
          <button
            id="tx-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <aside className="sidebar y-gap-40 xl:d-block">
            <Sidebar
              filters={getFilterSelected()}
              setFilters={setFilterSelected}
              options={filterOptions}
            />
          </aside>
        </div>

        <div className="row ml-10 mr-10 mt-10 mb-10">
          <div className="col-3">
            <button
              className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
              onClick={onResetFilter}
            >
              Reset
            </button>
          </div>
          <div className="col-9">
            <button
              className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
              onClick={onApplyFilter}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsTab;
