import PropTypes from "prop-types";
import { useState } from "react";

const TopHeaderFilter = (props) => {
  const {
    results,
    showSort,
    sortOrder,
    setSortOrder,
    filterTitle,
    showFilterButton,
  } = props;
  const [showSortButtons, setShowSortButtons] = useState(window.innerWidth > 767);
  const count = results?.length;

  const onSortSelect = (param) => {
    if (param in sortOrder) {
      const isDesc = sortOrder[param] === 'desc';
      const newSortOrder = { ...sortOrder };
      if (!isDesc) delete newSortOrder[param];
      else newSortOrder[param] = 'asc';
      setSortOrder(newSortOrder);
    } else {
      setSortOrder({ [param]: 'desc' });
    }
  };

  const getSortButton = (param, title) => {
    if (param in sortOrder) {
      const isDesc = sortOrder[param] === 'desc';
      return (
        <button
          className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
          onClick={() => onSortSelect(param)}
        >
          {isDesc ? '↓' : '↑'} {title}
        </button>
      );
    }
    return (
      <button
        className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
        onClick={() => onSortSelect(param)}
      >
        <i className="icon-up-down text-14 mr-10" />
        {title}
      </button>
    );
  };

  return (
    <>
      <div className="row y-gap-10 items-center justify-between">
        <div className="col-auto">
          <div className="text-18">
          {/* {results
            && <>
              <span className="fw-500">{count.toLocaleString()}</span> project{count > 1 ? 's' : ''} found
            </>
          } */}
          </div>
        </div>

        {/* count > 1 */
          <div className="ml-15 mr-15">
            <div className="d-none mr-20">
              <div className="d-flex justify-content-between">
                <button
                  id="filter-close-button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#listingSidebar"
                  className="button h-30 px-10 rounded-100 text-light-2 filter-button text-12"
                >
                  <i className="icon-menu-2 text-12 pl-10" />
                  <span className="px-10">{filterTitle ?? 'Filter'}</span>
                </button>
                {showSort?.length > 0
                  && <button
                    className={`button -blue-1 ${showSortButtons ? 'bg-blue-1 text-blue-2' : 'bg-blue-1-05 text-blue-1'} h-30 px-10 rounded-100 text-12  ml-10 ${showSortButtons ? 'mb-10' : ''}`}
                    onClick={() => setShowSortButtons(!showSortButtons)}
                  >
                    <i className="icon-up-down text-12 pl-10" />
                    <span className="px-10">Sort</span>
                  </button>
                }
              </div>
            </div>

            <div className="d-flex justify-content-between x-gap-10">
            
              {showFilterButton
                && <div className="p-2 md:d-none">
                  <button
                    id="filter-close-button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#listingSidebar"
                    className="button h-30 px-10 rounded-100 text-light-2 filter-button text-12"
                  >
                    <i className="icon-menu-2 text-12 pl-10" />
                    <span className="px-10">{filterTitle ?? 'Filter'}</span>
                  </button>
                </div>
              }
              {showFilterButton && window.innerWidth > 767
                && <div className="p-2 d-none md:d-block"></div>
              }

              {showSortButtons && <div className="d-flex flex-wrap justify-content-end mr-15">
                {showSort.map(sort => (
                    <div className="p-2" key={sort[0]}>
                      {getSortButton(sort[0], sort[1])}
                    </div>
                  ))}
                </div>
              }
              
            </div>
          </div>
        }
      </div>
    </>
  );
};

TopHeaderFilter.propTypes = {
  results: PropTypes.array,
  showSort: PropTypes.array,
  sortOrder: PropTypes.object,
  setSortOrder: PropTypes.func.isRequired,
  filterTitle: PropTypes.string,
  showFilterButton: PropTypes.bool,
};

TopHeaderFilter.defaultProps = {
  showSort: [],
  showUnitsConverter: false,
  filterTitle: null,
  showFilterButton: false,
};

export default TopHeaderFilter;
