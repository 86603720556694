import { useState } from 'react';
import PropTypes from "prop-types";

import CustomTable from '../transactions/CustomTable';
import { convertDisplayDate, dateStrToMsec } from '@/utils/time';

const tableConfig = {
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const DEFAULT_COLUMNS = [
  {
    Header: 'Sale Date',
    accessor: 'sale_date',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
  },
  {
    Header: 'Address',
    accessor: 'Address',
    disableGlobalFilter: true,
    disableSortBy: true,
    Cell: (row) => <a
      className="text-blue-1"
      href={`https://maps.google.com/?q=${row.cell.value}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value}
    </a>
  },
  {
    Header: 'Type of Sale',
    accessor: 'Type of Sale',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Transacted Price ($)',
    accessor: 'transacted_price',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
  },
  {
    Header: 'Unit Price ($psf)',
    accessor: 'Unit Price ($ PSF)',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
  },
  {
    Header: 'Property Type',
    accessor: 'Property Type',
    disableGlobalFilter: true,
  },
  {
    Header: 'Area (sft)',
    accessor: 'Area (SQFT)',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
  },
  {
    Header: 'Type of Area',
    accessor: 'Type of Area',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Profit ($)',
    accessor: 'profit_value',
    disableGlobalFilter: true,
    width: 100,
    Cell: (row) => <div
      className={
        (row.cell.value - 1000000000000) > 0 ? 'text-green-2' : 'text-red-1'
      }
    >
      {
        (row.cell.value - 1000000000000) > 0 ? '' : '-'
      }${
        Math.abs(row.cell.value - 1000000000000).toLocaleString()
      }
    </div>
  },
  {
    Header: 'Annualized Profit %',
    accessor: 'annualized_profit',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div
      className={
        ((row.cell.value - 10000) / 100) > 0 ? 'text-green-2' : 'text-red-1'
      }
    >
      {((row.cell.value - 10000) / 100).toFixed(1)}%
    </div>
  },
  {
    Header: 'Purchaser Address Indicator',
    accessor: 'Purchaser Address Indicator',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Prev Transacted (Years)',
    accessor: 'Prev Transacted (Years)',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{parseInt(row.cell.value)}</div>
  },
  {
    Header: 'Postal Code',
    accessor: 'Postal Code',
    disableGlobalFilter: true,
    width: 80,
  },
];

const ProfitTable = ({ data, name, onFilterButtonClick }) => {
  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      name={name}
      data={
        data.map(row => ({
          ...row,
          'sale_date': dateStrToMsec(row['Sale Date']),
          'annualized_profit': parseInt(10000 + (100 * row['Annualized Profit Perc'])),
          'transacted_price': parseInt(row['Transacted Price ($)']),
          'profit_value': row['Profit'] + 1000000000000,
        })).sort((a, b) => b.sale_date - a.sale_date)
      }
      tableConfig={tableConfig}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
      onFilterButtonClick={onFilterButtonClick}
    />
  );
};

ProfitTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ProfitTable;
