import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import DefaultHeader from "@/components/header/default-header";
import DefaultFooter from "@/components/footer/default";

import MetaComponent from "@/components/common/MetaComponent";
import { continueIfAdmin } from "@/utils/user";
import Loader from "@/components/common/Loader";
import { debugCall } from "@/utils/api";
import PieChart from "@/components/common/PieChart";

const metadata = {
  title: "Admin Panel | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const MEMBERSHIP = [
  {
    label: 'Super',
    value: 800,
  },
  {
    label: 'Pro',
    value: 200,
  },
  {
    label: 'Free',
    value: 10,
  },
];

const AdminPage = ({ user }) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);
  const [email, setEmail] = useState(null);
  const [membership, setMembership] = useState(800);

  const [queryEmail, setQueryEmail] = useState(null);
  const [queryUser, setQueryUser] = useState(null);

  const [usersAnalytics, setUsersAnalytics] = useState(null);
  const [pagesAnalytics, setPagesAnalytics] = useState(null);
  const [userViews, setUserViews] = useState(null);

  useEffect(() => {
    continueIfAdmin(user, () => {
      setDataLoading(false);

      // for analytics view
      getUsersAnalytics();
      getPagesAnalytics();
      getUserViewsAnalytics();
    }, navigate);
  }, [user]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitSuperUser = () => {
    setDataLoading(true);
    setErr(null);
    setSuccess(null);
    const plan = MEMBERSHIP.find(v => v.value === membership);
    if (validateEmail(email)) {
      debugCall({
        e: email,
        m: 'u',
        p: plan.value,
      }, () => {
        setSuccess(`User level set successfully for ${email} as ${plan.label} user (${plan.value})`);
        setEmail(null);
        setMembership(800);
        setDataLoading(false);
      }, (err) => {
        setErr(err);
        setDataLoading(false);
      });
    } else {
      setErr('Invalid email address');
      setDataLoading(false);
    }
  };

  const queryUserByEmail = () => {
    setDataLoading(true);
    setQueryUser(null);
    setErr(null);
    setSuccess(null);
    if (validateEmail(queryEmail)) {
      debugCall({
        e: queryEmail,
        m: 'q',
      }, (data) => {
        setQueryUser(data);
        setSuccess(`${queryEmail} user information received`);
        setQueryEmail(null);
        setDataLoading(false);
      }, (err) => {
        setErr(err);
        setDataLoading(false);
      });
    } else {
      setErr('Invalid email address');
      setDataLoading(false);
    }
  };

  const refreshLandingPageData = () => {
    setRefreshLoading(true);
    debugCall({
      m: 'l',
    }, (data) => {
      setSuccess('Landing page data updated');
      setRefreshLoading(false);
    }, (err) => {
      setErr(err);
      setRefreshLoading(false);
    });
  };

  const refreshUpcomingData = () => {
    setRefreshLoading(true);
    debugCall({
      m: 'c',
    }, (data) => {
      setSuccess('Upcoming properties page data updated');
      setRefreshLoading(false);
    }, (err) => {
      setErr(err);
      setRefreshLoading(false);
    });
  };

  const getUsersAnalytics = () => {
    setUsersAnalytics(null);
    debugCall({
      m: 'au'
    }, (data) => {
      setUsersAnalytics(data);
    }, (err) => {
      setErr(err);
      setUsersAnalytics({});
    });
  };

  const getPagesAnalytics = () => {
    setPagesAnalytics(null);
    debugCall({
      m: 'ap'
    }, (data) => {
      setPagesAnalytics(data);
    }, (err) => {
      setErr(err);
      setPagesAnalytics({});
    });
  };

  const getUserViewsAnalytics = () => {
    setUserViews(null);
    debugCall({
      m: 'an'
    }, (data) => {
      setUserViews(data);
    }, (err) => {
      setErr(err);
      setUserViews({});
    });
  };

  const sumCount = (obj) => Object.keys(obj).reduce((t, o) => t + obj[o], 0);

  const generateExpiryStr = (expiry) => (!expiry || expiry <= 0) ? 'None' : new Date(expiry).toLocaleDateString();

  const generateMembershipStr = (membership) => {
    if (membership >= 900) return 'Admin';
    if (membership >= 800) return 'Super User';
    if (membership >= 200) return 'Pro User';
    if (membership >= 100) return 'Trial User';
    return 'Free User';
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      {dataLoading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!dataLoading
        && <div className="watermark ml-20 mr-20">
          <section className="pt-20" id="reviews">
            <div className="container">
              {err &&
                <div className="alert alert-danger" role="alert">
                  {err}
                </div>
              }

              {success &&
                <div className="alert alert-success" role="alert">
                  {success}
                </div>
              }

              <h5 className="py-10">Analytics - Past 2 Weeks</h5>
              {(usersAnalytics === null || pagesAnalytics === null || userViews === null)
                && <div className="loader-container">
                  <Loader />
                </div>
              }
              {usersAnalytics !== null && pagesAnalytics !== null && userViews !== null
                && <div className="row mt-10 mb-40 text-14">
                  <div className="col-lg-4 col-md-12">
                    <PieChart
                      label="User Types"
                      data={usersAnalytics}
                    />
                    <br />
                    <h5 className="text-18 text-center py-10 mb-20">User Types (Total Users: {sumCount(usersAnalytics)})</h5>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <PieChart
                      label="Page Views"
                      data={pagesAnalytics}
                    />
                    <br />
                    <h5 className="text-18 text-center py-10 mb-20">Page Views (Total Views: {sumCount(pagesAnalytics)})</h5>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <PieChart
                      label="Top Active Users"
                      data={userViews}
                    />
                    <br />
                    <h5 className="text-18 text-center py-10 mb-20">Top 20 Active Users (Total Views: {sumCount(userViews)})</h5>
                  </div>
                </div>
              }

              <h5 className="py-10 pt-40 border-top-light">Upgrade SUPER user by email</h5>
              <div className="row mt-10 mb-40 text-14">

                <div className="col-4 mt-10 mb-10 rounded-100 border-light admin-bar">
                  <div className="px-10 py-5 bg-white js-form-dd js-liverSearch">
                    <div className="text-light-1">
                      <input
                        autoComplete="off"
                        type="search"
                        placeholder="Enter email address"
                        className="js-search js-dd-focus"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-5 dropdown js-dropdown js-category-active">
                  <div
                    className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 lh-12 w-100 d-flex justify-content-between"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                    data-bs-offset="0,10"
                  >
                    <span className="js-dropdown-title px-15">
                      {MEMBERSHIP.find(v => v.value === membership).label}
                    </span>
                    <i className="icon icon-chevron-sm-down text-7 ml-10" />
                  </div>
                  <div className="toggle-element -dropdown dropdown-menu">
                    <div className="text-13 y-gap-15 js-dropdown-list">
                      {MEMBERSHIP.map((option, index) => (
                        <div key={`pri-${index}`}>
                          <button
                            className={`d-block js-dropdown-link ${
                              membership === option.value ? "text-blue-1 " : ""
                            }`}
                            onClick={() => {
                              setMembership(option.value);
                              document.querySelector(".js-dropdown-link").textContent = option.label;
                            }}
                          >
                            {option.label}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="button-item mt-10 mb-10 admin-bar">
                    <button
                      className="button -dark-1 py-15 px-40 h-full col-12 rounded-4 bg-blue-1 text-white"
                      onClick={() => submitSuperUser()}
                    >
                      <i className="icon-check mr-10" />
                      Update
                    </button>
                  </div>
                </div>

              </div>

              <h5 className="py-10 pt-40 border-top-light">Check user status</h5>
              <div className="row text-14 mb-40">
                <div className="col-9 mt-10 mb-10 bg-white rounded-100 border-light admin-bar">
                  <div className="px-15 py-5 js-form-dd js-liverSearch">
                    <div className="text-light-1">
                      <input
                        autoComplete="off"
                        type="search"
                        placeholder="Enter email address"
                        className="js-search js-dd-focus"
                        value={queryEmail}
                        onChange={e => setQueryEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="button-item mt-10 mb-10 admin-bar">
                    <button
                      className="button -dark-1 py-15 px-40 h-full col-12 rounded-4 bg-blue-1 text-white"
                      onClick={() => queryUserByEmail()}
                    >
                      <i className="icon-search mr-10" />
                      Search
                    </button>
                  </div>
                </div>
                {queryUser && !queryUser.status && <div className="col-9 py-20 px-20">
                    Email: <span className="fw-500">{queryUser.email}</span>
                    <br />
                    Name: <span className="fw-500">{queryUser.name}</span>
                    <br />
                    User ID: <span className="fw-500">{queryUser.uid}</span>
                    <br />
                    Membership: <span className="fw-500">{generateMembershipStr(queryUser.membership)} ({queryUser.membership ?? 0})</span>
                    <br />
                    Expiry: <span className="fw-500">{generateExpiryStr(queryUser.expiry)}</span>
                  </div>
                }
                {queryUser && queryUser.status && <div className="col-9 py-20 px-20">
                    <span className="fw-500">{queryUser.status}</span>
                  </div>
                }
              </div>

              <h5 className="py-10 pt-40 border-top-light">Refresh Cache</h5>
              {refreshLoading
                && <Loader />
              }
              {!refreshLoading && <div className="row text-14 mt-20">
                <div className="col-6">
                  <button
                    className="button -dark-1 py-15 px-40 h-full col-12 rounded-4 bg-blue-1 text-white"
                    onClick={refreshLandingPageData}
                  >
                    <i className="icon-play mr-10" />
                    Refresh Landing Page Data
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button -dark-1 py-15 px-40 h-full col-12 rounded-4 bg-blue-1 text-white"
                    onClick={refreshUpcomingData}
                  >
                    <i className="icon-play mr-10" />
                    Refresh Upcoming Properties Data
                  </button>
                </div>
              </div>}

            </div>
          </section>
        </div>
      }

      <div className="footer-block" />
      <DefaultFooter />
    </>
  );
};

AdminPage.propTypes = {
  user: PropTypes.object,
};

AdminPage.defaultProps = {
  user: null,
};

export default AdminPage;
