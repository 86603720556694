import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { COLORS } from "@/utils/colors";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
);

const PieChart = ({
  label,
  data,
}) => {
  const x = Object.keys(data).filter(o => data[o] > 0);
  const y = x.map(o => data[o]);

  const chartData = {
    labels: x,
    datasets: [
      {
        label: label,
        data: y,
        backgroundColor: COLORS,
        hoverBackgroundColor: COLORS,
      },
    ],
  };

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
  };

  return <Pie data={chartData} options={options} />;
};

PieChart.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};


export default PieChart;
