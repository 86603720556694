import { Link } from "react-router-dom";

const ScrollUpButton = ({ onPrevPage, dark }) => {
  return (
    <div className="lp-scroller-up-container d-flex justify-content-center">
      <Link
        onClick={onPrevPage}
        className="lp-scroller-up rotate-180 py-10 px-40"
      >
        <i className={`icon-chevron-sm-down ${dark ? 'text-blue-1' : 'text-light'}`} />
      </Link>
    </div>
  );
};

export default ScrollUpButton;
