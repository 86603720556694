import { generateAllFilterString } from "@/utils/filter";
import { generateDateTimeString } from "@/utils/time";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";

const HistoryGroup = ({ options, searches, setSearches, selectSearch }) => {
  const now = new Date().getTime();

  const content = searches?.map(s => {
    const filterStrList = generateAllFilterString(options, s.filter);
    return [
      filterStrList.join('<br />'),
      filterStrList.length > 0
        ? `${filterStrList[0].substring(0, 25)}${filterStrList[0].length > 25 ? '…' : ''}`
        : 'No filter selection'
    ];
  });

  const removeSearchAtIndex = (e, i) => {
    e.stopPropagation();
    setSearches(searches.filter((_, idx) => idx !== i));
  };

  const removeAllSearch = (e) => {
    setSearches([]);
  };

  return (
    <div className={`${searches.length > 0 ? 'mb-10' : ''} history-row px-40`}>
      {searches.map((search, i) => (
        <div
          key={i}
          className="px-20 py-5 bg-blue-2 text-12 cursor-pointer history-card history-w mr-20 mt-10"
          onClick={() => selectSearch(search)}
          data-tooltip-id="search-history-tooltip"
          data-tooltip-html={content[i][0]}
          data-tooltip-variant="dark"
          data-tooltip-place="bottom"
        >
          <span className="d-flex justify-content-between">
            Searched {generateDateTimeString(now, search.ts)}
            <i
              className="icon-close py-5"
              onClick={e => removeSearchAtIndex(e, i)}
            />
          </span>

          <span className="text-12 fw-500 d-none md:d-block">
            <i
              className="icon-chevron-sm-down py-5 pr-5 text-10"
              onClick={e => e.stopPropagation()}
              data-tooltip-id="search-history-tooltip"
              data-tooltip-html={content[i][0]}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            />
            {content[i][1]}
          </span>

          <span className="text-13 fw-500 md:d-none">
            {content[i][1]}
          </span>
        </div>
      ))}

      {/* {searches?.length > 0
        && <div
          className="pl-10 pr-10 py-5 bg-blue-2 rounded-100 text-12 cursor-pointer history-card mt-10"
          key="search-close"
        >
          <span className="d-flex justify-content-between">
            <i
              className="icon-close py-5"
              onClick={e => removeAllSearch(e)}
              data-tooltip-id="search-history-tooltip"
              data-tooltip-html="Remove all history"
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            />
          </span>
        </div>
      } */}

      <Tooltip id="search-history-tooltip" style={{ zIndex: 999 }} />
    </div>
  );
};

HistoryGroup.propTypes = {
  options: PropTypes.array.isRequired,
  searches: PropTypes.array.isRequired,
  setSearches: PropTypes.func.isRequired,
  selectSearch: PropTypes.func.isRequired,
};

export default HistoryGroup;
