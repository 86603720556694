import { useRef } from "react";
import { noSelectClass } from "@/utils/user";
import Factsheet from "@/components/project/Factsheet";
import PropertyMap from "@/components/project/PropertyMap";
import PerformancePanel from "@/components/project/PerformancePanel";

const OverviewTab = ({
  user,
  result,
  perfResults,
  resultPropLocation,
  screenDim,
  isMaximized
}) => {
  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  return (
    <>
      <section className="pt-0" id="reviews">
        <div className={`px-5 ${noSelectClass(user)}`}>
          <div className="row y-gap-10 justify-between">

            <div className="col-12 row x-gap-50 y-gap-10 py-0">

              <div className="row justify-between items-end">
                <div className="col-auto">
                  <div className="row x-gap-20  items-center">
                    <div className="col-auto">
                      <h1 className="text-16 sm:text-20 fw-600 mt-10">
                        {result['Project Name']}
                      </h1>
                    </div>
                  </div>
                  <div className="row x-gap-20 y-gap-0 items-center">
                    <div className="col-auto">
                      <div className="d-flex items-center text-12 text-light-1">
                        <i className="icon-location-2 text-14 mr-5" />
                        {result['Street']}
                      </div>
                    </div>
                    <div className="col-auto d-flex align-content-start flex-wrap py-0">
                      {result['tenure_available']?.map(tenure => (
                        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
                          {tenure}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

            <div className={`col-${chartColGrid} text-blue-1`}>
              <PerformancePanel
                data={perfResults}
              />
            </div>

            {chartColGrid === 6
              && <div className={`col-${chartColGrid} text-blue-1`}>
                <div className="mt-5" style={{ height: perfResults ? 173 : 201 } }>
                  <PropertyMap
                    project={resultPropLocation}
                    zoom={8.8}
                  />
                </div>
              </div>
            }

            {chartColGrid !== 6
              && <div className="col-12">
                <div className="proj-gallery">
                  <PropertyMap
                    project={resultPropLocation}
                  />
                </div>
              </div>
            }

            <Factsheet
              info={result}
              screenDim={screenDim}
              isMaximized={isMaximized}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewTab;
