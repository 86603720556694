import { noSelectClass } from "@/utils/user";
import CompareView from "@/components/project/CompareView";

const CompareTab = ({
  id,
  user,
  compsResults,
  screenDim,
  isMaximized
}) => {
  return (
    <>
      <section className={`pb-40 ${noSelectClass(user)}`}>
        <CompareView
          id={id}
          user={user}
          data={compsResults}
          screenDim={screenDim}
          isMaximized={isMaximized}
        />
      </section>
    </>
  );
};

export default CompareTab;
