const ContactInfo = () => {
  const contactContent = [
    {
      id: 1,
      title: "Email",
      icon: "icon-email-2",
      action: "mailto:hello@realsmart.sg",
      text: "hello@realsmart.sg",
    },
    {
      id: 2,
      title: "Facebook",
      icon: "icon-facebook",
      action: "https://www.facebook.com/profile.php?id=61564099801135",
      text: "Facebook Page",
    },
    {
      id: 3,
      title: "Instagram",
      icon: "icon-instagram",
      action: "https://www.instagram.com/realsmart.sg/",
      text: "Instagram Page",
    },
  ];
  return (
    <div className="row">
      {contactContent.map((item) => (
        <div className="col-xl-3 col-lg-4 col-md-12" key={item.id}>
          <div className={"text-14 mt-20"}>{item.icon ? <i className={`${item.icon} mr-10`} /> : ''}{item.title}</div>
          <a href={item.action} className="text-18 fw-500 text-blue-1 mt-5">
            {item.text}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ContactInfo;
