const ProfitableGroup = ({
  item,
  abortedCount
}) => {

  const getTrendClass = (trend) => {
    if (trend === 0) {
      return '';
    }
    return trend > 0 ? 'text-green-2' : 'text-red-1';
  };

  const getProfitableClass = (item) => {
    const profitable = item?.['perc_profitable'] ?? 0;
    if (profitable > 0) return getTrendClass(1);
    if (profitable < 0) return getTrendClass(-1);
    return getTrendClass(0);
  };

  return (
    <div className="px-15 py-5 mt-10 proj-perf">
      <div className="d-flex items-center text-blue-1 text-12">
        <i className="icon-usd fw-600 mr-10"></i>
        <div className="fw-600">Profitability</div>
      </div>

      <div className="row x-gap-50 y-gap-0 text-12 pt-5">
        <div className="col-12">
          <div className="">
            Profitable %: <span className={getProfitableClass(item)}>{
              (item?.['perc_profitable'] ?? 0).toFixed(0)
            }%</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Profitable Transactions: <span className="text-green-2">{
              item?.['num_profitable_transactions'] ?? 0
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            &gt; 6% Annualized: <span className="text-green-2">{
              item?.['num_profitable_above_6_perc'] ?? 0
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Unprofitable Transactions: <span className="text-red-1">{
              (item?.['num_unprofitable'] ?? 0) - abortedCount
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Total Transactions: <span className="text-dark-1">{
              item?.['total_num_transactions'] ?? 0
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Total Profits: <span className={getTrendClass(item['total_profits'])}>{
              item['total_profits'] < 0 ? '-' : ''
            }${
              (Math.abs(item['total_profits'] ?? 0)).toLocaleString()
            }</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitableGroup;
