import { useRef } from "react";
import PropTypes from "prop-types";
import Map, { Marker } from "react-map-gl/maplibre";
import { Tooltip } from "react-tooltip";

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const HOME_ICON = "M13.22,2.984c-1.125,0-2.504,0.377-3.53,1.182C8.756,3.441,7.502,2.984,6.28,2.984c-2.6,0-4.714,2.116-4.714,4.716c0,0.32,0.032,0.644,0.098,0.96c0.799,4.202,6.781,7.792,7.46,8.188c0.193,0.111,0.41,0.168,0.627,0.168c0.187,0,0.376-0.041,0.55-0.127c0.011-0.006,1.349-0.689,2.91-1.865c0.021-0.016,0.043-0.031,0.061-0.043c0.021-0.016,0.045-0.033,0.064-0.053c3.012-2.309,4.6-4.805,4.6-7.229C17.935,5.1,15.819,2.984,13.22,2.984z M12.544,13.966c-0.004,0.004-0.018,0.014-0.021,0.018s-0.018,0.012-0.023,0.016c-1.423,1.076-2.674,1.734-2.749,1.771c0,0-6.146-3.576-6.866-7.363C2.837,8.178,2.811,7.942,2.811,7.7c0-1.917,1.554-3.47,3.469-3.47c1.302,0,2.836,0.736,3.431,1.794c0.577-1.121,2.161-1.794,3.509-1.794c1.914,0,3.469,1.553,3.469,3.47C16.688,10.249,14.474,12.495,12.544,13.966z";

const pinStyle = {
  cursor: 'pointer',
  fill: '#3554d1',
  stroke: 'none',
};

const homePinStyle = {
  ...pinStyle,
  fill: '#DE3163',
};

const DEFAULT_ZOOM = 9;

const PropertyMap = ({
  project,
  zoom = DEFAULT_ZOOM
}) => {
  const markers = [
    project
  ];

  const mapRef = useRef(null);

  return (
    <div className="proj-loc-map">
      <Map
        onMouseMove={(e) => {
          e.target.getCanvas().style.cursor = 'default';
        }}
        ref={mapRef}
        // maxBounds={[minLng, minLat, maxLng, maxLat]}
        maxBounds={[103.3, 1.0, 104.3, 1.7]}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        style={{
          width: "100%",
          height: "100%",
        }}
        attributionControl={false}
        initialViewState={{
          latitude: 1.3521,
          longitude: 103.8198,
          zoom
        }}
        scrollZoom={false}
        dragPan={false}
      >
          
        {markers.map((location, i) => (
          <Marker
            key={i}
            latitude={location.lat}
            longitude={location.lng}
            anchor="bottom"
          >
            <div
              data-tooltip-id="prop-map-tooltip"
              data-tooltip-html={project.name}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            >
              <svg
                height={60}
                viewBox="0 0 24 24"
                style={homePinStyle}
              >
                <path d={ICON} />
                <circle cx="12" cy="10" r="8" fill="#fff" />
                <g transform="scale(0.6),translate(10,6)">
                  <path d={HOME_ICON} className="mapMarkerIcon" />
                </g>
              </svg>
            </div>
          </Marker>
        ))}

      </Map>

      <Tooltip id="prop-map-tooltip" style={{ zIndex: 600 }} />

    </div>
  );
};

PropertyMap.propTypes = {
  project: PropTypes.object.isRequired,
};

export default PropertyMap;
