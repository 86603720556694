import { downloadFileUrl, logAnalytics } from "@/utils/api";
import {
  AGG_TYPE_AVG,
  AGG_TYPE_COUNT,
  INTERVAL_TYPE_MONTH,
  INTERVAL_TYPE_YEAR,
  MULTICHART_SUBTYPE_BARS,
  MULTICHART_SUBTYPE_LINES,
  PERIOD_TYPE_10Y,
  PERIOD_TYPE_12M,
  PERIOD_TYPE_ALL,
  RESOLUTION_TYPE_HIGH,
  RESOLUTION_TYPE_LOW,
  VALUE_TYPE_COUNT,
  VALUE_TYPE_PERC,
  VALUE_TYPE_PRICE,
  generateMultiPropDistributionChart,
  generateMultiPropMultiChart
} from "@/utils/chart";
import { useEffect, useState } from "react";
import MainFilterSearchBox from "../compare/FilterBox";
import Loader from "../common/Loader";
import { convertPropertyData, deepCopy } from "@/utils/convert";
import { dateStrToDateObj, dateStrToMsec, formatShortDate } from "@/utils/time";
import { noSelectClass } from "@/utils/user";
import { Tooltip } from "react-tooltip";
import MultiChart from "@/components/compare/MultiChart";
import MultiChartSelector from "@/components/compare/MultiChartSelector";
import DistributionChart from "@/components/compare/DistributionChart";
import MainChart from "@/components/compare/MainChart";
import PerformancePanel from "./PerformancePanel";
import ProfitableGroup from "./ProfitableGroup";
import GeneralGroup from "./GeneralGroup";
import TransactionGroup from "./TransactionGroup";
import RentalGroup from "./RentalGroup";
import LocationGroup from "./LocationGroup";
import { getPropertyFileName } from "@/utils/areas";

const DATE_FIELD = 'Sale Date';

const PERIOD_SELECTIONS = [
  {
    label: 'All Time',
    value: PERIOD_TYPE_ALL,
  },
  {
    label: 'Past 12 Months',
    value: PERIOD_TYPE_12M,
  },
  {
    label: 'Past 10 Years',
    value: PERIOD_TYPE_10Y,
  },
];

const LEFT_Y_SELECTIONS = [
  {
    label: 'Transaction Volume',
    value: 'vol',
    agg: AGG_TYPE_COUNT,
    valueType: VALUE_TYPE_COUNT,
  }
];

const RIGHT_Y_SELECTIONS = [
  {
    label: 'Average PSF ($psf)',
    value: 'apsf',
    agg: AGG_TYPE_AVG,
    valueType: VALUE_TYPE_PRICE,
  }
];

const INTERVAL_SELECTIONS = [
  {
    label: 'Year',
    value: INTERVAL_TYPE_YEAR,
    prefix: 'Annual',
  },
  {
    label: 'Month',
    value: INTERVAL_TYPE_MONTH,
    prefix: 'Monthly',
  },
];

const RESOLUTION_SELECTIONS = [
  {
    label: 'Low Resolution',
    value: RESOLUTION_TYPE_LOW,
  },
  {
    label: 'High Resolution',
    value: RESOLUTION_TYPE_HIGH,
  },
];

const generateLabelPrefix = (interval) => {
  const currInterval = INTERVAL_SELECTIONS.find(v => v.value === interval);
  return currInterval.prefix;
};

const DEFAULT_MULTCHART_DATA = {
  labels: [],
  bars: [],
  lines: [],
};

const DEFAULT_PROFIT_DISTRIBUTION_DATA = {
  labels: [],
  data: [],
  colors: [],
};

const CompareView = ({
  id,
  user,
  data,
  screenDim,
  isMaximized
}) => {
  const [period, setPeriod] = useState(PERIOD_SELECTIONS[0].value);
  const [leftY, setLeftY] = useState(LEFT_Y_SELECTIONS[0].value);
  const [rightY, setRightY] = useState(RIGHT_Y_SELECTIONS[0].value);
  const [interval, setTimeInterval] = useState(INTERVAL_SELECTIONS[0].value);
  const [resolution, setResolution] = useState(RESOLUTION_SELECTIONS[0].value);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const [properties, setProperties] = useState([]);

  const [inited, setInited] = useState(false);
  const [rawResults, setRawResults] = useState({});
  const [profitResults, setProfitResults] = useState({});
  const [abortedCount, setAbortedCount] = useState({});
  const [returns1y, setReturns1y] = useState({});
  const [multiChartData, setMultiChartData] = useState(DEFAULT_MULTCHART_DATA);
  const [profitDistributionData, setProfitDistributionData] = useState(DEFAULT_PROFIT_DISTRIBUTION_DATA);
  const [detailResults, setDetailResults] = useState([]);

  useEffect(() => {
    if (id !== inited) {
      setInited(id);

      const finalData = data ?? { details: {}, profits: {}, transactions: {} };
      const ids = finalData ? Object.keys(finalData.details) : [];
      const newDetailsResults = [
        ...detailResults
      ];
      const newTxResults = {
        ...rawResults
      };
      const newProfitResults = {
        ...profitResults
      };
      const newAbortedCount = {
        ...abortedCount
      };
      setProperties(ids);
      ids.forEach(prop => {
        newDetailsResults.push(convertPropertyData(finalData['details'][prop]));
        newTxResults[prop] = finalData['transactions'][prop];
        newProfitResults[prop] = finalData['profits'][prop];
        newAbortedCount[prop] = finalData['profits'][prop].filter(row => row['Type of Sale'] === 'New Sale' && row['Annualized Profit Perc'] === 0).length;
      });
      setDetailResults(newDetailsResults);
      setRawResults(newTxResults);
      setMultiPropMultiChart(period, interval, newTxResults);
      setProfitResults(newProfitResults);
      setProfitDistributionChart(newProfitResults, resolution);
      setAbortedCount(newAbortedCount);
      setLoading(false);
    }
  }, [data, id]);

  const loadPropData = (projectName, streetName) => {
    setLoading(true);
    setErr(null);
    const prop = `${projectName},${streetName}`;
    const cloudFilename = getPropertyFileName(projectName, streetName);

    downloadFileUrl('properties/details', cloudFilename, 'json', (url) => {
      fetch(url)
      .then(resp => resp.json()).then(data => {
        const detail = convertPropertyData(data?.result);
        if (data?.perf) {
          detail['realsmart_score'] = data.perf['realsmart_score'];
          detail['num_profitable_transactions_1y'] = data.perf['num_profitable_transactions'];
          detail['num_unprofitable_1y'] = data.perf['num_unprofitable'];
        }
        const newDetailsResults = [
          ...detailResults,
          detail
        ];
        setDetailResults(newDetailsResults);
        setLoading(false);
      }).catch(err => {
        setErr(err);
        setDataLoading(false);
      });
    }, (err) => {
      setErr(err);
      setDataLoading(false);
    });

    downloadFileUrl('properties/full', cloudFilename, 'json', (url) => {
      fetch(url)
      .then(resp => resp.json()).then(json => {
        const newTxResults = {
          ...rawResults,
          [prop]: json.t
        };
        const newProfitResults = {
          ...profitResults,
          [prop]: json.p
        };
        const newAbortedCount = {
          ...abortedCount,
          [prop]: json.p.filter(row => row['Type of Sale'] === 'New Sale' && row['Annualized Profit Perc'] === 0).length
        };
        const past1yMsec = new Date(`${new Date().getFullYear() - 1}-01-01`).getTime();
        const past1yTx = json.p.filter(row => dateStrToMsec(row['Sale Date']) >= past1yMsec);
        if (past1yTx.length > 0) {
          const returnAvg = past1yTx.reduce((sub, row) => sub + row['Annualized Profit Perc'], 0) / past1yTx.length;
          const newReturns = {
            ...returns1y,
            [prop]: Math.round(returnAvg * 100) / 100
          };
          setReturns1y(newReturns);
        }
        setRawResults(newTxResults);
        setMultiPropMultiChart(period, interval, newTxResults);
        setProfitResults(newProfitResults);
        setProfitDistributionChart(newProfitResults, resolution);
        setAbortedCount(newAbortedCount);
        setLoading(false);
      }).catch(err => {
        setErr(err);
        setTableLoading(false);
      });
    }, (err) => {
      setTxResults([]);
      setTableLoading(false);
    });
  };

  const addProperty = (name, address) => {
    const item = `${name},${address}`;
    if (!properties.includes(item)) {
      const updatedProperties = [ ...properties, item ];
      setProperties(updatedProperties);
      // loadData([`${name},${address}`]);
      loadPropData(name, address);
      logAnalytics(updatedProperties);
    }
  };

  const removeProperty = (item) => {
    if (properties.includes(item)) {
      // remove from properties list
      const updatedProperties = properties.filter(p => p !== item);
      setProperties(updatedProperties);

      // remove from raw data
      const updatedRawResults = deepCopy(rawResults);
      delete updatedRawResults[item];
      setRawResults(updatedRawResults);

      // remove from multi-chart
      setMultiPropMultiChart(period, interval, updatedRawResults);

      // remove from profit data
      const updatedProfitResults = deepCopy(profitResults);
      delete updatedProfitResults[item];
      setProfitResults(updatedProfitResults);

      // remove from profit distribution chart
      setProfitDistributionChart(updatedProfitResults, resolution);

      // remove aborted count
      const updatedAbortedCount = deepCopy(abortedCount);
      delete updatedAbortedCount[item];
      setAbortedCount(updatedAbortedCount);

      // remove returns 1y
      const updatedReturns = deepCopy(returns1y);
      delete updatedReturns[item];
      setReturns1y(updatedReturns);

      // remove from detail property data
      const propName = item.split(',')[0];
      const propStreet = item.split(',')[1];
      setDetailResults(detailResults.filter(r => r['Project Name'] !== propName && r['Street'] !== propStreet));
    }
  };

  const currentLeftY = LEFT_Y_SELECTIONS.find(v => v.value === leftY);
  const currentRightY = RIGHT_Y_SELECTIONS.find(v => v.value === rightY);

  const setMultiPropMultiChart = (period, interval, rawResults) => {
    // format transaction data for charts
    const formattedResults = {};
    Object.keys(rawResults).forEach(r => {
      formattedResults[r] = rawResults[r].map(d => ({
        d: dateStrToDateObj(d[DATE_FIELD]),
        apsf: d['Unit Price ($ PSF)'],
        vol: d['Unit Price ($ PSF)'],
      }));
    });

    // generate multi-chart results
    setMultiChartData(generateMultiPropMultiChart({
      [MULTICHART_SUBTYPE_BARS]: {
        field: leftY,
        agg: currentLeftY.agg,
        valueType: currentLeftY.valueType,
      },
      [MULTICHART_SUBTYPE_LINES]: {
        field: rightY,
        agg: currentRightY.agg,
        valueType: currentRightY.valueType,
      },
    }, period, interval, formattedResults));
  };

  const setProfitDistributionChart = (profitResults, resolution) => {
    setProfitDistributionData(generateMultiPropDistributionChart(
      'Annualized Profit Perc',
      VALUE_TYPE_PERC,
      profitResults,
      resolution
    ));
  };

  const onSearchLocation = (searchTerm) => {
    addProperty(searchTerm.name, searchTerm.address);
  };

  const changePeriod = (value) => {
    setPeriod(value);
    setMultiPropMultiChart(value, interval, rawResults);
  };

  const changeInterval = (value) => {
    setTimeInterval(value);
    setMultiPropMultiChart(period, value, rawResults);
  };

  const getFlatProfitResults = () => {
    const results = [];
    if (profitDistributionData?.colors && profitResults && profitDistributionData.colors.length === Object.keys(profitResults).length) {
      const colors = profitDistributionData.colors;
      Object.keys(profitResults).forEach(key => {
        const prop = profitResults[key];
        const chunks = key.split(',');
        const name = chunks[0];
        const address = chunks[1];
        const color = colors.find(c => c.name === name && c.address === address);
        prop.forEach(row => {
          results.push({
            name,
            address,
            'Sale Date': row['Sale Date'],
            'Annualized Profit Perc': row['Annualized Profit Perc'],
            color: color.color,
          });
        });
      });
    }
    return results;
  };

  const flatProfitResults = getFlatProfitResults();

  const isLandscape = screenDim.width > screenDim.height;

  const chartHeight = (isLandscape && isMaximized) ? 250 : (screenDim.height < 800 ? null : 250);

  const chartColGrid = isLandscape
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  return (
    <>
      {!loading
          && <div className="py-10">
          <div className="container">
            <div className="row">
              <div className="col-12 px-5">
                <MainFilterSearchBox
                  onSearchLocation={onSearchLocation}
                  buttonLabel="Add"
                  hideClickHint
                  disableEnter
                />
              </div>
            </div>
          </div>
        </div>
      }

      {!loading
        && <section className="noselect watermark">
          <div className="container px-5">
            {properties.map(p => (
              <span className="p-tag text-10 rounded-100 px-10 mr-5" key={`comp-container-${p}`}>
                <span
                  data-tooltip-id="proptag-tooltip"
                  data-tooltip-content={p.split(',')[1]}
                  data-tooltip-variant="dark"
                  data-tooltip-place="bottom"
                >
                  {p.split(',')[0]}
                </span>
                <i
                  className="icon-close cursor-pointer pl-10"
                  onClick={() => removeProperty(p)}
                  data-tooltip-id="proptag-tooltip"
                  data-tooltip-content="Remove property"
                  data-tooltip-variant="dark"
                  data-tooltip-place="bottom"
                />
              </span>
            ))}
          </div>
          <Tooltip id="proptag-tooltip" />
        </section>
      }

      {err
        && <section className="layout-pt-sm watermark">
          <div className="container">
            <div className="alert alert-danger" role="alert">
              {err}
            </div>
          </div>
        </section>
      }

      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!loading && properties.length > 0
        && <>
          <section className={`layout-pb-md ${noSelectClass(user)} watermark`}>
            <div className="px-5">
              <div className="row">

                <div className={`col-${chartColGrid} text-center noselect`}>
                  <h5 className="py-10 text-15 mt-10 mb-10">
                    {currentLeftY.label} / {currentRightY.label}
                  </h5>
                  <MultiChart
                    data={multiChartData}
                    tooltipTitleCallback={
                      (tooltipItems) => {
                        const type = tooltipItems[0].dataset.type;
                        const title = tooltipItems[0].label;
                        if (type === 'line') {
                          return `${generateLabelPrefix(interval)} ${currentRightY.label} for ${title}`;
                        } else if (type === 'bar') {
                          return `${generateLabelPrefix(interval)} ${currentLeftY.label} for ${title}`;
                        }
                        return `${title}`;
                      }
                    }
                    tooltipLabelCallback={
                      (tooltipItem) => {
                        const type = tooltipItem.dataset.type;
                        const datasetLabel = tooltipItem.dataset.label || '';
                        const value = tooltipItem.raw;
                        if (type === 'line') {
                          if (rightY === 'apsf') {
                            return `${datasetLabel}: $${value}`;
                          }
                        } else if (type === 'bar') {
                          if (leftY === 'vol') {
                            return `${datasetLabel}: ${value.toFixed(0)}`;
                          }
                        }
                        return `${datasetLabel}: ${value}`;
                      }
                    }
                    yLeftLabel={currentLeftY.label}
                    yRightLabel={currentRightY.label}
                    height={chartHeight}
                  />
                  <div className="mt-10">
                    {profitDistributionData?.colors.map(p => (
                      <span
                        key={`comp-col-px-${p.color}`}
                        className="c-tag text-10 px-10 ml-5"
                        style={{
                          backgroundColor: p.color
                        }}
                        data-tooltip-id="proptag-tooltip"
                        data-tooltip-content={p.address}
                        data-tooltip-variant="dark"
                        data-tooltip-place="top"
                      >
                        {p.name}
                      </span>
                    ))}
                  </div>
                  <div className="compare-chart-selector px-5 mt-10 mb-10">
                    <MultiChartSelector
                      period={period}
                      setPeriod={changePeriod}
                      periodOptions={PERIOD_SELECTIONS}
                      leftY={leftY}
                      setLeftY={setLeftY}
                      leftYOptions={LEFT_Y_SELECTIONS}
                      rightY={rightY}
                      setRightY={setRightY}
                      rightYOptions={RIGHT_Y_SELECTIONS}
                      interval={interval}
                      setTimeInterval={changeInterval}
                      intervalOptions={INTERVAL_SELECTIONS}
                    />
                  </div>
                </div>

                <div className={`col-${chartColGrid} text-center noselect`}>
                  <h5 className="py-10 text-15 mt-10 mb-10">Annualized Profit (%) Distribution</h5>
                  <DistributionChart
                    data={profitDistributionData}
                    tooltipTitleCallback={
                      (context) => `Annualized ${parseFloat(context[0].label) <= 0 ? 'Loss:' : 'Gain:'} up to ${context[0].label}%`
                    }
                    tooltipLabelCallback={
                      (context) => `${context.dataset.label || ''}: ${context.raw.toFixed(0)} transactions`
                    }
                    xAxisLabel="Annualized Profit (%)"
                    yAxisLabel="Number of Transactions"
                    height={chartHeight}
                  />
                  
                  <div className="mt-10">
                    {profitDistributionData?.colors.map(p => (
                      <span
                        key={`comp-col-pfd-${p.color}`}
                        className="c-tag text-10 px-10 ml-5"
                        style={{
                          backgroundColor: p.color
                        }}
                        data-tooltip-id="proptag-tooltip"
                        data-tooltip-content={p.address}
                        data-tooltip-variant="dark"
                        data-tooltip-place="top"
                      >
                        {p.name}
                      </span>
                    ))}
                  </div>

                  <div className="compare-chart-selector px-10 py-10 mb-20">
                    <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
                      <div
                        className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                        data-bs-offset="0,10"
                      >
                        <span className="js-dropdown-title">
                          <span className="text-blue-1 fw-600"></span>
                          <span className="js-dropdown-res">{
                            RESOLUTION_SELECTIONS.find(v => v.value === resolution).label
                          }</span>
                        </span>
                        <i className="icon icon-chevron-sm-down text-7 ml-10" />
                      </div>
                      <div className="toggle-element -dropdown dropdown-menu">
                        <div className="text-13 y-gap-15 js-dropdown-list">
                          {RESOLUTION_SELECTIONS.map(option => (
                            <div key={`comp-res-sel-${option.value}`}>
                              <button
                                className={`d-block js-dropdown-link ${
                                  resolution === option.value ? "text-blue-1 " : ""
                                }`}
                                onClick={() => {
                                  setResolution(option.value);
                                  document.querySelector(".js-dropdown-res").textContent = option.label;
                                  setProfitDistributionChart(profitResults, option.value);
                                }}
                              >
                                {option.label}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`col-${chartColGrid} text-center noselect`}>
                  <h5 className="py-10 text-15 mt-10 mb-10">Annualized Profit (%)</h5>
                  <MainChart
                    label="Annualized Profit (%)"
                    data={
                      flatProfitResults.map(row => ({
                        x: dateStrToDateObj(row['Sale Date']).getTime(),
                        y: row['Annualized Profit Perc'],
                      }))
                    }
                    colors={flatProfitResults.map(row => row.color)}
                    tooltipTitle="Annualized Profit (%)"
                    tooltipLabelCallback={
                      (context) => {
                        const date = new Date(context.raw.x);
                        const formattedDate = formatShortDate(date);
                        const name = flatProfitResults[context.dataIndex].name;
                        return `${name}: ${context.raw.y.toFixed(1)}% (${formattedDate})`;
                      }
                    }
                    yAxisLabel="Annualized Profit (%)"
                    height={chartHeight}
                  />
                  <div className="mt-10">
                    {profitDistributionData?.colors.map(p => (
                      <span
                        key={`comp-pdd-col-${p.color}`}
                        className="c-tag text-10 px-10 ml-5"
                        style={{
                          backgroundColor: p.color
                        }}
                        data-tooltip-id="proptag-tooltip"
                        data-tooltip-content={p.address}
                        data-tooltip-variant="dark"
                        data-tooltip-place="bottom"
                      >
                        {p.name}
                      </span>
                    ))}
                  </div>
                </div>

              </div>
              </div>
          </section>

          <section className={`layout-pb-md ${noSelectClass(user)} border-top-light pt-20`}>
            <div className="d-flex proj-comp-col-grp">
              {
                detailResults.map(details => (
                  <div className="p-2">
                    <div className="text-center mb-5 proj-comp-col">
                      <h1 className="text-16">{details['Project Name']}</h1>
                      <h5 className="text-10 text-light-1">{details['Street']}</h5>
                    </div>

                    <PerformancePanel
                      data={details}
                      returns={returns1y[`${details['Project Name']},${details['Street']}`]}
                      minimize
                    />

                    <GeneralGroup item={details} />

                    <ProfitableGroup
                      item={details}
                      abortedCount={abortedCount[`${details['Project Name']},${details['Street']}`] ?? 0}
                    />

                    <TransactionGroup item={details} />

                    <RentalGroup item={details} />

                    <LocationGroup item={details} />
                  </div>
                ))
              }
            </div>
          </section>
        </>
      }
    </>
  );
};

export default CompareView;
