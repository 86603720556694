import { MARKET_SEGMENT_ID_REVERSE_MAP } from "@/utils/areas";
import { NA } from "@/utils/convert";

const LocationGroup = ({ item }) => {
  const generateMarketSegmentName = (segment) => {
    if (segment in MARKET_SEGMENT_ID_REVERSE_MAP) {
      return MARKET_SEGMENT_ID_REVERSE_MAP[segment];
    }
    return NA;
  };

  return (
    <div className="px-15 py-5 mt-10 proj-perf">
      <div className="d-flex items-center text-blue-1 text-12">
        <i className="icon-usd fw-600 mr-10"></i>
        <div className="fw-600">Location</div>
      </div>

      <div className="row x-gap-50 y-gap-0 text-12 pt-5">
        <div className="col-12">
          <div className="">
            Market Segment: <span>{
              generateMarketSegmentName(item?.['Market Segment'])
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Area: <span className="text-dark-1">{item?.['Planning Area']}</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Region: <span className="text-dark-1">{item?.['Planning Region']}</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            District: <span className="text-dark-1">{item?.['Postal District']}</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Postal Sector: <span className="text-dark-1">{item?.['Postal Sector']}</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default LocationGroup;
