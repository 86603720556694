import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const isLoggedIn = (user) => user && Object.keys(user).length !== 0;

export const isGuest = (user) => user && Object.keys(user).length === 0;

const isValidExp = (exp) => exp <= 0 || (exp * 1000) > new Date().getTime();

export const isTierPremium = (tier) => tier >= 200;

export const isFreeTier = (claims) => !claims
  || !claims.exp
  || !claims.m
  || !isValidExp(claims.exp)
  || claims.m < 100;

export const isTrialTier = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 100
  && claims.m < 800;

export const isProTier = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 200
  && claims.m < 800;
  
export const isSuperTier = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 800
  && claims.m < 900;

export const isAdminTier = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 900;

export const isNonFreeUser = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 100;

export const isExperimentalFreeUser = (claims) => claims
  && isValidExp(claims.exp)
  && claims.m >= 10;

export const getUserClaims = (user) => user?.claims;

export const isPremium = (user) => isNonFreeUser(user?.claims);

export const isFreeUser = (user) => isFreeTier(user?.claims);

const generateRedirectQuery = (pathname, search) => {
  if (!search)
    return `next=${encodeURIComponent(pathname)}`;
  return `next=${encodeURIComponent(pathname + '?' + search)}`;
};

export const generateRefreshUrl = (pathname, search) => `/refresh?${generateRedirectQuery(pathname, search)}`;

export const continueIfNotGuest = (user, onVerified, navigate, pathname, search) => {
  if (isLoggedIn(user)) {
    onVerified();
  } else if (isGuest(user)) {
    navigate(`/login?${generateRedirectQuery(pathname, search)}`);
  }
};

export const continueIfAllowedUser = (user, onVerified, navigate, pathname, search) => {
  if (isLoggedIn(user)) {
    onVerified(); // allow all registered users
    
    // const claims = getUserClaims(user);
    // if (isNonFreeUser(claims) || isExperimentalFreeUser(claims)) {
    //   onVerified();
    // } else {
    //   if (!search) {
    //     navigate(`/pending?next=${encodeURIComponent(pathname)}`);
    //   } else {
    //     navigate(`/pending?next=${encodeURIComponent(pathname + '?' + search)}`);
    //   }
    // }
  } else if (isGuest(user)) {
    navigate(`/login?${generateRedirectQuery(pathname, search)}`);
  }
};

export const continueIfAdmin = (user, onVerified, navigate) => {
  if (isLoggedIn(user)) {
    const claims = getUserClaims(user);
    if (isAdminTier(claims)) {
      onVerified();
    } else {
      navigate('/');
    }
  } else if (isGuest(user)) {
    navigate('/');
  }
};

export const getUserDisplayName = (user) => user?.multiFactor?.user?.displayName ?? 'Unknown';

export const getUserEmail = (user) => user?.multiFactor?.user?.email ?? 'N.A.';

export const getUserPhotoUrl = (user) => user?.multiFactor?.user?.photoURL ?? '/img/general/sg.png';

export const getUserID = (user) => user?.multiFactor?.user?.uid ?? user?.results?.claims?.user_id;

export const noSelectClass = (user) => isNonFreeUser(getUserClaims(user)) ? '' : 'noselect' ;

export const checkMediaQuery = () => {
  const mediaQuery = window.matchMedia("(max-width: 768px) and (orientation: portrait)");
  return mediaQuery.matches;
};

export const checkMobile = () => {
  const mediaQueryPortrait = window.matchMedia("(max-width: 768px)");
  const mediaQueryLandscape = window.matchMedia("(max-height: 768px)");
  return mediaQueryPortrait.matches || mediaQueryLandscape.matches;
};

export const FIREBASE_UI_AUTH_CONFIG = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google',
      customParameters: {
        prompt: 'select_account'
      },
      scopes: [
        'email'
      ],
    },
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   scopes: [
    //     'email'
    //   ],
    // },
  ],
  tosUrl: '/terms?t=tos',
  privacyPolicyUrl: '/terms?t=privacy',
  callbacks: {
    signInSuccessWithAuthResult: () => false,
    uiShown: () => {
      const tosElement = document.querySelector('.firebaseui-tos');
      if (tosElement) {
        tosElement.innerHTML = `<strong>This free service is available for personal use only.</strong><br/><br/>By continuing you agree to our <a href="javascript:void(0)" class="firebaseui-link firebaseui-tos-link" target="_blank">Terms of Service</a> and <a href="javascript:void(0)" class="firebaseui-link firebaseui-pp-link" target="_blank">Privacy Policy</a>.`;
      }
    }
  },
};
