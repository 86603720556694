import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import LocationSearch from "../common/sidebar/ProjectSearch";
import { DEBOUNCE_TIMING, quickQueryProps } from "@/utils/api";

const LandingFilterBox = (props) => {
  const {
    onSearchLocation,
    hideClickHint,
  } = props;
  const [results, setResults] = useState([]);
  const [isSuggested, setIsSuggested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const onSearch = (value) => {
    setLoading(true);
    quickQueryProps(value, 10, (data) => {
      let results = data.d.map(r => ({
        id: encodeURIComponent(`${r['Project Name']},${r['Street']}`),
        name: r['Project Name'],
        address: r['Street']
      }));

      if (results.length === 0 && data.s) {
        results = data.s.map(r => ({
          id: encodeURIComponent(`${r['Project Name']},${r['Street']}`),
          name: r['Project Name'],
          address: r['Street']
        }));
        setIsSuggested(true);
      }

      setResults(results);
      setLoading(false);
    }, (err) => {
      setLoading(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
  };

  const onSelect = (item) => {
    setSelectedItem(item.name);
    onSearchLocation(item);
  };

  const onSearchListing = () => {
    if (results?.length > 0) {
      setSelectedItem(results[0].name);
      onSearchLocation(results[0]);
    }
  };

  return (
    <>
      <div className="land-search mt-10 px-20 fade-intro">
        <div className="button-grid items-center d-flex justify-content-between rounded-8 border-blue-1">
          <div className="p-2 flex-fill">
            <LocationSearch
              searchValue={searchValue}
              selectedItem={selectedItem}
              onInput={onInput}
              onSelect={onSelect}
              loading={loading}
              locationSearchContent={results}
              onKeyDown={onSearchListing}
              hideClickHint={hideClickHint}
              isSuggested={isSuggested}
              textColor="blue-1"
              shortHeight
            />
          </div>

          <div className="button-item h-full mr-10 bg-blue-1 text-white px-10 rounded-100">
            <i className="icon-search text-12" />
          </div>
        </div>
      </div>
    </>
  );
};

LandingFilterBox.propTypes = {
  onSearchLocation: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  hideClickHint: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
};

LandingFilterBox.defaultProps = {
  buttonIcon: null,
  buttonLabel: null,
};

export default LandingFilterBox;
