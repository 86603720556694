import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const getOptions = (xLabel, yLabel) => ({
  responsive: true,
  maintainAspectRatio: false,
  
  scales: {      
    x: {
      type: 'linear',
      position: 'bottom',
      ticks: {
        callback: function (value) {
          const date = new Date(value);
          const options = { year: 'numeric', month: 'short', day: 'numeric' };
          return date.toLocaleDateString(undefined, options);
        },
      },
      title: xLabel ? {
        display: true,
        text: xLabel,
        font: {
          size: 10,
        },
        padding: {
          top: 10,
        },
      }: {},
    },
    y: {
      title: yLabel ? {
        display: true,
        text: yLabel,
        font: {
          size: 10,
        },
        padding: {
          right: 10,
        },
      } : {},
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },

    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "#1967d2",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 4,
    },
  },
});

const generateChartData = (label, data, colors) => ({
  datasets: [
    {
      label,
      data,
      backgroundColor: colors ?? 'rgba(53,84,209,1)',
    },
  ],
});

const AnnotatedMainChart = ({
  label,
  data,
  colors,
  tooltipTitle,
  tooltipLabelCallback,
  annotationLine,
  xAxisLabel,
  yAxisLabel,
  height
}) => {
  const chartData = generateChartData(label, data, colors);

  const options = getOptions(xAxisLabel, yAxisLabel);

  const chartOption = {
    ...options,
    plugins: {
      ...options.plugins,
      tooltip: {
        callbacks: {
          title: () => tooltipTitle,
          label: (context) => tooltipLabelCallback(context),
        }
      },
    },
  };

  if (annotationLine) {
    const horizontalLinePlugin = {
      id: 'horizontalLine',
      afterDraw: (chart) => {
        const ctx = chart.ctx;
        const yScale = chart.scales.y;
        const yValue = annotationLine.value;
        if (yScale) {
          const yPixel = yScale.getPixelForValue(yValue);
      
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(chart.chartArea.left, yPixel);
          ctx.lineTo(chart.chartArea.right, yPixel);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#3554D1';
          ctx.stroke();
      
          ctx.font = '10px Arial';
          ctx.fillStyle = '#3554D1';
          ctx.textAlign = 'left';
          ctx.fillText(annotationLine.label, chart.chartArea.left + 10, yPixel - 6);
          ctx.restore();
        }
      }
    };

    chartOption.plugins.customLine = horizontalLinePlugin;

    if (chartData.datasets[0].data.length > 0) {
      const chartMaxY = Math.max(annotationLine.value, Math.max(...chartData.datasets[0].data.map(d => d.y)));
      const maxY = Math.ceil((chartMaxY + 100) / 100) * 100;
      chartOption.scales.y = {
        ...chartOption.scales.y,
        max: maxY,
      };
    }

    ChartJS.register(horizontalLinePlugin);
  }

  return (
    <div className="widget-content">
      <Scatter
        options={chartOption}
        data={chartData}
        height={height}
      />
    </div>
  );
};

AnnotatedMainChart.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  tooltipLabelCallback: PropTypes.func.isRequired,
  colors: PropTypes.array,
  annotationLine: PropTypes.object,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

AnnotatedMainChart.defaultProps = {
  colors: null,
  annotationLine: null,
};

export default AnnotatedMainChart;
