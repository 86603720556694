import DefaultHeader from "@/components/header/default-header";
import DefaultFooter from "@/components/footer/default";
// import LoginWithSocial from "@/components/common/LoginWithSocial";
// import LoginForm from "@/components/common/LoginForm";

import MetaComponent from "@/components/common/MetaComponent";
import LoginComponent from "./LoginComponent";

const metadata = {
  title: "REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const LogIn = ({ session, updateTokenResult }) => {
  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader hideUserButton />

      <LoginComponent session={session} />

      <DefaultFooter />
    </>
  );
};

export default LogIn;
