export const PROFIT_TABLE_HEADERS = [
  {
    Header: 'Project',
    accessor: 'id',
    fixed: "left",
    disableSortBy: true,
    // pageLevelSort: true,
    Cell: (row) => <a
      className="text-blue-1"
      href={`/project/${encodeURIComponent(row.cell.value)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value.split(',')[0]}
    </a>
  },
  {
    Header: 'Address',
    accessor: 'Address',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 250,
    Cell: (row) => <a
      className="text-blue-1"
      href={`https://maps.google.com/?q=${row.cell.value}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value}
    </a>
  },
  {
    Header: 'Sale Date',
    accessor: 'Sale Date',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 110,
  },
  {
    Header: 'Type of Sale',
    accessor: 'Type of Sale',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
  {
    Header: 'Transacted Price ($)',
    accessor: 'Transacted Price ($)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 160,
    Cell: (row) => <div>${row.cell.value?.toLocaleString()}</div>
  },
  {
    Header: 'Unit Price ($psf)',
    accessor: 'Unit Price ($ PSF)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value?.toLocaleString()}</div>
  },
  {
    Header: 'Property Type',
    accessor: 'Property Type',
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Area (sft)',
    accessor: 'Area (SQFT)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>{row.cell.value?.toLocaleString()}</div>
  },
  {
    Header: 'Type of Area',
    accessor: 'Type of Area',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
  {
    Header: 'Profit ($)',
    accessor: 'Profit',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 120,
    Cell: (row) => <div>${row.cell.value?.toLocaleString()}</div>
  },
  {
    Header: 'Annualized Profit %',
    accessor: 'Annualized Profit Perc',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 100,
    Cell: (row) => <div>{row.cell.value?.toFixed(1)}%</div>
  },
  {
    Header: 'Purchaser Address Indicator',
    accessor: 'Purchaser Address Indicator',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80
  },
  {
    Header: 'Prev Transacted (Years)',
    accessor: 'Prev Transacted (Years)',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>{row.cell.value?.toFixed(1)}</div>
  },
  {
    Header: 'Tenure',
    accessor: 'Tenure',
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Tenure Start Date',
    accessor: 'tenure_start_date',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 110,
  },
  {
    Header: 'Completion Date',
    accessor: 'Completion Date',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 110,
  },
  {
    Header: 'Area',
    accessor: 'Planning Area',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 120,
  },
  {
    Header: 'Region',
    accessor: 'Planning Region',
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Market Segment',
    accessor: 'Market Segment',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
  {
    Header: 'Postal Code',
    accessor: 'Postal Code',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
  {
    Header: 'Postal District',
    accessor: 'Postal District',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 80,
  },
  {
    Header: 'Units',
    accessor: 'Number of Units',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>{row.cell.value?.toLocaleString()}</div>
  },
];
