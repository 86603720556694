import { useState, useEffect, useRef } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import PropTypes from "prop-types";

import Header11 from "@/components/header/header-11";
import MetaComponent from "@/components/common/MetaComponent";
import Map, {
  Source,
  Layer,
  Popup,
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl/maplibre";
import * as turf from '@turf/turf';
import Sidebar from "@/components/common/Sidebar";
import Loader from "@/components/common/Loader";
import HeatmapSelector from "@/components/heatmap/HeatmapSelector";
import {
  downloadFileUrl,
  logNameSearch,
  logPageView
} from "@/utils/api";
import {
  checkMobile,
  continueIfAllowedUser,
  generateRefreshUrl,
  noSelectClass
} from "@/utils/user";
import {
  NA,
  calculateAgeYear,
  deepCopy,
  getPropTypeFilterSelected
} from "@/utils/convert";
import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FIELD_REGIONS,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_INPUT,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SCHOOL_INPUT,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS,
  clearFilters,
  hasAnyValue,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import {
  DEFAULT_MAP_THEME,
  MARKET_SEGMENTS,
  MARKET_SEGMENT_ID_MAP,
  REGIONS,
  convertIntToPropertyType,
  convertIntToTenureType,
  getMapStyleFromTheme,
  getTenureValue,
  isRegion,
  transformMapboxStyleRequest,
  unravelId
} from "@/utils/areas";
import MapStyleSelector from "@/components/common/MapStyleSelector";
import TableView from "@/components/upcoming/TableView";
import {
  getMarkerIcon,
  homePinStyle,
  radiusLabelStyle,
  radiusMaskStyle
} from "@/utils/map";
import { dateStrToMsec } from "@/utils/time";

const metadata = {
  title: "Property Map | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const OPTIMAL_CIRCLE_MOBILE = 14;
const OPTIMAL_CIRCLE_DESKTOP = 15;

const HEATMAP_CIRCLE_SCALE = 0.42;

const PROP_TYPE_APARTMENT = 'Apartment';
const PROP_TYPE_SEMI_DETACH = 'Semi-Detach House';
const PROP_TYPE_DETACHED = 'Detached House';
const PROP_TYPE_TERRACE = 'Terrace';
const PROP_TYPE_CONDO = 'Condominium';

const ICON_TYPE_HOUSE = 'house';
const ICON_TYPE_APARTMENT = 'apartment';

const pinStyle = {
  cursor: 'pointer',
  fill: '#DE3163',
  stroke: 'none',
};

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const PROPERTY_ICON = "M 12 2 A 1 1 0 0 0 11.289062 2.296875 L 1.203125 11.097656 A 0.5 0.5 0 0 0 1 11.5 A 0.5 0.5 0 0 0 1.5 12 L 4 12 L 4 20 C 4 20.552 4.448 21 5 21 L 9 21 C 9.552 21 10 20.552 10 20 L 10 14 L 14 14 L 14 20 C 14 20.552 14.448 21 15 21 L 19 21 C 19.552 21 20 20.552 20 20 L 20 12 L 22.5 12 A 0.5 0.5 0 0 0 23 11.5 A 0.5 0.5 0 0 0 22.796875 11.097656 L 12.716797 2.3027344 A 1 1 0 0 0 12.710938 2.296875 A 1 1 0 0 0 12 2 z";

const APARTMENT_ICON = "M246.125,195.063v-82.688h-63v82.688h-7.875V17.823h-23.625V2.125h-47.25v15.698H80.75v177.24h-7.875V80.875h-63v114.188H2 v11.813h252v-11.813H246.125z M47.281,177.344H35.469v-11.813h11.813V177.344z M47.281,145.844H35.469v-11.813h11.813V145.844z M47.281,114.344H35.469v-11.813h11.813V114.344z M119.141,174.391h-11.813v-11.813h11.813V174.391z M119.141,142.891h-11.813 v-11.813h11.813V142.891z M119.141,111.391h-11.813V99.578h11.813V111.391z M119.141,79.891h-11.813V68.078h11.813V79.891z M150.641,174.391h-11.813v-11.813h11.813V174.391z M150.641,142.891h-11.813v-11.813h11.813V142.891z M150.641,111.391h-11.813 V99.578h11.813V111.391z M150.641,79.891h-11.813V68.078h11.813V79.891z M220.531,177.344h-11.813v-11.813h11.813V177.344z M220.531,145.844h-11.813v-11.813h11.813V145.844z";

const PRICE_MAGNIFIER = 100000;

const FIELD_6PC_PROFIT = 'p6';
const FIELD_PROFIT = 'px';
const FIELD_TRANSACTIONS = 'tx';
const FIELD_FAIR_PRICE = 'fx';

const FILTER_OPTIONS = [
  FILTER_PROPERTY_INPUT,
  FILTER_SCHOOL_INPUT,
  FILTER_COMPLETION_DATE,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROPERTY_TYPE,
  FILTER_TENURE,
  FILTER_PROPERTY_AGE,
  FILTER_PROFITABLE,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_SIZE,
  FILTER_HDB_BUYERS,
  FILTER_TOTAL_UNITS,
  FILTER_LAUNCH_PERC,
  FILTER_PSF_RENTAL_6M,
  FILTER_LOCATION
];

// From 0 - 100 for age
const MAX_AGE_FILTER = 100;
const MIN_AGE_FILTER = 0;

// From $0 - $10m, increment of 100k
const MAX_PRICE_FILTER = 100;
const MIN_PRICE_FILTER = 0;

const MAX_PROFITABLE_FILTER = 100;
const MIN_PROFITABLE_FILTER = 0;

const HeatmapPage = ({ user, session }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [tierMsg, setTierMsg] = useState(null);
  const [tierBlocked, setTierBlocked] = useState(false);
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [idMapping, setIdMapping] = useState({});
  const [zoom, setZoom] = useState(24);
  const [popupInfo, setPopupInfo] = useState(null);
  const [persistPopup, setPersistPopup] = useState(false);
  const [priceDataAvailable, setPriceDataAvailable] = useState(false);
  const [flyToLock, setFlyToLock] = useState(false);
  const [mapStyle, setMapStyle] = useState(DEFAULT_MAP_THEME);
  const mapRef = useRef();

  const [upcomingData, setUpcomingData] = useState([]);
  const [upcomingPopupInfo, setUpcomingPopupInfo] = useState(null);
  const [freezePopup, setFreezePopup] = useState(false);

  const [pri, setPri] = useState('profit');
  const [sec, setSec] = useState('transactions');
  const [mode, setMode] = useState('heatmap');

  const [filterSelected, setFilterSelected] = useState(initializeFilters(FILTER_OPTIONS, params));

  const [schoolSearched, setSchoolSearched] = useState([]);
  const [showSchoolLabel, setShowSchoolLabel] = useState(null);
  
  const [radiusMaskCoords, setRadiusMaskCoords] = useState(null);

  const getPriPropertyName = () => {
    if (pri === 'profit') return FIELD_PROFIT;
    if (pri === 'annual6pc') return FIELD_6PC_PROFIT;
    if (pri === 'fairPrice') return FIELD_FAIR_PRICE;
    // if (pri === 'psf') return FIELD_PSF;
    return FIELD_PROFIT;
  };

  const getSecPropertyName = () => {
    if (sec === 'transactions') return FIELD_TRANSACTIONS;
    return FIELD_TRANSACTIONS;
  };

  const getPlaceName = (place) => place?.properties?.id?.split(',')?.[0];

  const getStreetName = (place) => place?.properties?.id?.split(',')?.[1];

  const HEATMAP_COLOR_FAIR = [
    'case',
    ['==', ['get', getPriPropertyName()], -1],
    'rgb(94, 94, 94)',
    ['<', ['get', getPriPropertyName()], 0],
    ['interpolate', ['linear'], ['get', getPriPropertyName()], -100, 'rgb(244, 109, 67)', 0, 'rgb(255, 20, 20)'],
    ['>', ['get', getPriPropertyName()], 0],
    ['interpolate', ['linear'], ['get', getPriPropertyName()], 0, 'rgb(25, 255, 25)', 100, 'rgb(0, 113, 0)'],
    'gray'
  ];
  
  const HEATMAP_COLOR_PROFIT = [
    'case',
    ['==', ['get', getPriPropertyName()], -1], 'rgb(94, 94, 94)',
    [
      'interpolate',
      ['linear'],
      ['get', getPriPropertyName()],
      0,
      'rgb(255, 20, 20)',
      30,
      'rgb(244, 109, 67)',
      50,
      'rgb(235, 171, 52)',
      70,
      'rgb(255, 228, 112)',
      80,
      'rgb(173, 255, 47)',
      90,
      'rgb(25, 255, 25)',
      100,
      'rgb(0, 113, 0)'
    ]
  ];

  const circleColor = pri === 'fairPrice' ? HEATMAP_COLOR_FAIR : HEATMAP_COLOR_PROFIT;

  const circleLayer = {
    'id': 'point',
    'type': 'circle',
    // 'minzoom': 14,
    'paint': {
      // Size circle radius by secondary magnitude and zoom level
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        ['interpolate', ['linear'], ['get', getSecPropertyName()], 0, 6, 2000, 30 * HEATMAP_CIRCLE_SCALE],
        15,
        ['interpolate', ['linear'], ['get', getSecPropertyName()], 0, 20, 2000, 100 * HEATMAP_CIRCLE_SCALE]
      ],
      // Color circle by primary magnitude
      'circle-color': circleColor,
      // Transition from heatmap to circle layer by zoom level
      'circle-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0,
        14,
        1
      ]
    }
  };

  const onZoomEnd = (evt) => {
    const zoom = evt.viewState.zoom;
    setZoom(zoom);
  };

  const getPointFeature = (evt) => {
    const features = mapRef?.current?.getMap().queryRenderedFeatures(evt.point, { layers: ['point'] });
    const feature = features?.[0];
    return feature;
  };

  const onViewClick = (evt) => {
    if (mode !== 'heatmap') return;
    if (flyToLock) return;
    const feature = getPointFeature(evt);
    if (!feature) {
      setPopupInfo(null);
      setPersistPopup(false);
    } else {
      setPopupInfo(feature);
      setPersistPopup(true);
    }
  };

  const onViewPoint = (evt) => {
    if (mode !== 'heatmap') return;
    if (flyToLock) return;
    const feature = getPointFeature(evt);
    if (feature) {
      if (persistPopup && feature.properties?.id !== popupInfo?.properties?.id) {
        setPopupInfo(feature);
        setPersistPopup(false);  
      } else if (!persistPopup) {
        setPopupInfo(feature);
        setPersistPopup(false);
      }
    } else if (!persistPopup) {
      setPopupInfo(null);
    }
  };

  const unpackCsv = (data) => ({
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "ts": data.t,
    "features": data.split('\n').map(line => {
      const elements = line.split('|');
      const lat = elements[1];
      const lng = elements[2];
      return {
        "type": "Feature",
        "properties": {
          "id": unravelId(elements[0]), // project name + street
          "px": parseInt(elements[10]) > 0 ? parseFloat(elements[3]) : -1,  // profitability %
          "tx": parseInt(elements[4]),  // transaction count
          "p6": parseInt(elements[5]),  // annualized gain > 6%
          "am": elements[6],  // tenure date
          "cd": elements[7],  // completion year
          "fp": parseFloat(elements[8]),  // fair price
          "fd": parseFloat(elements[9]),  // fair price - avg price
          "fx": parseFloat(elements[8]) > 0 ? (parseFloat(elements[9]) / parseFloat(elements[8]) * 100) : -1,
          "rx": parseInt(elements[10]), // resale transaction count
          "tp": elements[11],  // property types
          "ms": elements[12] !== '' ? MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(elements[12])].label] : null,  // market segment
          "pa": elements[13] !== '' ? REGIONS[elements[13].split(',')[0]]['areas'][elements[13].split(',')[1]] : null,  // planning area
          "dd": elements[14],  // district
          "tt": elements[15],  // tenure type
          // "hp": parseFloat(elements[18]), // HDB buyer percentage
          // "lt": elements[19],  // last transacted date
          // "tu": parseInt(elements[20]), // total units
          // "lp": parseFloat(elements[21]), // sold on launch percentage
          // "ar": parseFloat(elements[22]), // past 6 months average PSF rental price
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            lng,
            lat,
            0
          ]
        }
      }
    })
  });

  const generateIdMapFromUnpackedData = (data) => {
    const mapping = {};
    data.features.forEach((feature, i) => {
      mapping[feature.properties.id] = i;
    });
    return mapping;
  };

  const unpackPrice = (data) => {
    const newRawData = deepCopy(rawData);
    newRawData.features = data.d.map((d, i) => ({
      ...newRawData.features[i],
      properties: {
        ...newRawData.features[i].properties,
        "dt": d,  // list of 100k intervals to transaction count [[interval index, count]]
        "hp": parseFloat(data.a[i][0]), // HDB buyer percentage
        "lt": data.a[i][1],  // last transacted date
        "tu": parseInt(data.a[i][2]), // total units
        "lp": parseFloat(data.a[i][3]), // sold on launch percentage
        "ar": parseFloat(data.a[i][4]), // past 6 months average PSF rental price
        "mz": parseInt(data.a[i][5]),  // min size
        "nz": parseInt(data.a[i][6]),  // max size
      }
    }));
    return newRawData;
  };

  const loadDefaultMap = () => {
    setLoading(true);
    setErr(null);
    downloadFileUrl('map', 'dmap9', 'json', (url) => {
      fetch(url)
      .then(resp => resp.text())
      .then(txt => {
        const mapJson = unpackCsv(txt);
        setData(mapJson);
        setRawData(mapJson);
        setIdMapping(generateIdMapFromUnpackedData(mapJson));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErr('Failed to load map');
      })
    }, (err) => {
      setLoading(false);
      setErr('Failed to get map URL');
    });
  };

  const loadPriceMap = (ageFilter, priceFilter, psfFilter, profitableFilter,
    completionDateFilter, propTypeFilter, tenureFilter, sizeFilter, locationFilter,
    lastTransactDateFilter, hdbFilter, totalUnitsFilter, launchSoldFilter, psfRentalFilter) => {
    setLoading(true);
    setErr(null);
    downloadFileUrl('map', 'pmap9', 'json', (url) => {
      fetch(url)
      .then(resp => resp.json())
      .then(json => {
        if (rawData.features.length !== json.d.length) {
          setErr('Failed to load correct map');
        } else {
          const mapJson = unpackPrice(json);
          setRawData(mapJson);
          filterData(mapJson, ageFilter, priceFilter, psfFilter, profitableFilter,
            completionDateFilter, propTypeFilter, tenureFilter, sizeFilter, locationFilter,
            lastTransactDateFilter, hdbFilter, totalUnitsFilter, launchSoldFilter, psfRentalFilter);
        }
        setPriceDataAvailable(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErr('Failed to load map');
      })
    }, (err) => {
      setLoading(false);
      setErr('Failed to get map URL');
    });
  };

  useEffect(() => {
    continueIfAllowedUser(user, () => {
      loadDefaultMap();
      logPageView('MAP', session);
    }, navigate, pathname);
  }, [user]);

  const filterRequirePriceData = (priceFilter) => (priceFilter[0] !== MIN_PRICE_FILTER || priceFilter[1] !== MAX_PRICE_FILTER);

  const filterData = (rawData, ageFilter, priceFilter, psfFilter, profitableFilter,
    completionDateFilter, propTypeFilter, tenureFilter, sizeFilter, locationFilter,
    lastTransactDateFilter, hdbFilter, totalUnitsFilter, launchSoldFilter, psfRentalFilter) => {
    // handle age filter
    // const ageFilterList = getFilteredAges(ageFilter);
    const updatedData = deepCopy(rawData);
    const now = new Date();
    const referenceDate = new Date(updatedData.ts);
    const refDiff = now.getFullYear() - referenceDate.getFullYear();

    // updatedData.features = updatedData.features.filter(f => {
    //   const age = calculateAgeYear(f.properties.am, f.properties.cd, refDiff);
    //   return ageFilterList.some(a => a.null
    //     ? age === null
    //     : (!a.min || age >= a.min) && (!a.max || age <= a.max)
    //   );
    // });

    if (ageFilter[0] !== MIN_AGE_FILTER || ageFilter[1] !== MAX_AGE_FILTER) {
      updatedData.features = updatedData.features.filter(f => {
        const age = calculateAgeYear(f.properties.am, f.properties.cd, refDiff);
        if (ageFilter[0] !== MIN_AGE_FILTER && (age === null || age < ageFilter[0])) {
          return false;
        }
        if (ageFilter[1] !== MAX_AGE_FILTER && age > ageFilter[1]) {
          return false;
        }
        return true;
      });
    }

    // handle price filter
    if (priceFilter[0] !== MIN_PRICE_FILTER || priceFilter[1] !== MAX_PRICE_FILTER) {
      updatedData.features = updatedData.features.map(f => {
        let count = 0;
        for (let i = 0; i < f.properties.dt.length; i++) {
          const tier = f.properties.dt[i][0];
          const exceededMax = priceFilter[1] !== MAX_PRICE_FILTER && tier > priceFilter[1];
          const exceededMin = priceFilter[0] !== MIN_PRICE_FILTER && tier < priceFilter[0];
          if (exceededMax || exceededMin) {
            if (exceededMax) break;
          } else {
            count += f.properties.dt[i][1];
          }
        }
        const newF = deepCopy(f);
        newF.properties.tx = count;
        return newF;
      }).filter(f => f.properties.tx > 0);
    }

    // handle psf filter
    if (psfFilter !== null && (psfFilter[0] !== null || psfFilter[1] !== null)) {
      const minPsf = parseFloat(psfFilter[0]);
      const maxPsf = parseFloat(psfFilter[1]);
      updatedData.features = updatedData.features.filter(f => {
        if (!f.properties.fp || !f.properties.fd) return false;
        const psf = f.properties.fp - f.properties.fd;
        if (minPsf !== null && psf < minPsf) return false;
        if (maxPsf !== null && psf > maxPsf) return false;
        return true;
      });
    }

    if (profitableFilter[0] !== MIN_PROFITABLE_FILTER || profitableFilter[1] !== MAX_PROFITABLE_FILTER) {
      updatedData.features = updatedData.features.filter(f => {
        if (f.properties[FIELD_PROFIT] === -1
          || f.properties.rx <= 0
          || f.properties[FIELD_PROFIT] === null
          || f.properties[FIELD_PROFIT] === undefined
        ) {
          return false;
        }

        const profit = f.properties[FIELD_PROFIT];
        if (profitableFilter[0] !== MIN_PROFITABLE_FILTER && profit < profitableFilter[0]) {
          return false;
        }
        if (profitableFilter[1] !== MAX_PROFITABLE_FILTER && profit > profitableFilter[1]) {
          return false;
        }
        return true;
      });
    }

    if (completionDateFilter[0] !== null || completionDateFilter[1] !== null) {
      const startYear = completionDateFilter[0]?.getFullYear();
      const endYear = completionDateFilter[1]?.getFullYear();
      updatedData.features = updatedData.features.filter(f => {
        const completedYear = !!f.properties.cd ? parseInt(f.properties.cd) : f.properties.cd;
        if (!!endYear && (!completedYear || completedYear > endYear)) return false;
        if (!!startYear && completedYear < startYear) return false;
        return true;
      });
    }

    if (propTypeFilter.length !== 0) {
      updatedData.features = updatedData.features.filter(f => {
        const types = new Set(convertIntToPropertyType(f.properties.tp));
        return propTypeFilter.some(t => types.has(t));
      });
    }

    if (tenureFilter.length !== 0) {
      updatedData.features = updatedData.features.filter(f => {
        const types = new Set(convertIntToTenureType(f.properties.tt)
          .map(t => t.includes(' yrs ') ? parseInt(t.split(' yrs ')[0]).toString() : t));
        return tenureFilter.some(t => types.has(t));
      });
    }

    if (sizeFilter !== null && (sizeFilter[0] !== null || sizeFilter[1] !== null)) {
      const filterMin = !!sizeFilter[0] ? parseInt(sizeFilter[0]) : null;
      const filterMax = !!sizeFilter[1] ? parseInt(sizeFilter[1]) : null;
      updatedData.features = updatedData.features.filter(f => {
        const minSize = f.properties.mz;
        const maxSize = f.properties.nz;
        if (filterMax && minSize > filterMax) return false;
        if (filterMin && maxSize < filterMin) return false;
        return true;
      });
    }

    if (locationFilter.some(a => a.size > 0)) {
      updatedData.features = updatedData.features.filter(f => {
        if (locationFilter[0].size > 0) {
          const area = f.properties.pa;
          return locationFilter[0].has(area);
        }
        if (locationFilter[1].size > 0) {
          const district = f.properties.dd;
          return locationFilter[1].has(district);
        }
        if (locationFilter[2].size > 0) {
          const region = f.properties.ms;
          return locationFilter[2].has(region);
        }
      });
    }

    if (hdbFilter[0] !== 0 || hdbFilter[1] !== 100) {
      updatedData.features = updatedData.features.filter(f => {
        if (hdbFilter[0] !== 0 && f.properties.hp < hdbFilter[0]) return false;
        if (hdbFilter[1] !== 100 && f.properties.hp > hdbFilter[1]) return false;
        return true;
      });
    }

    if (totalUnitsFilter[0] !== 0 || totalUnitsFilter[1] !== 100) {
      updatedData.features = updatedData.features.filter(f => {
        if (totalUnitsFilter[0] !== 0 && f.properties.tu < totalUnitsFilter[0]) return false;
        if (totalUnitsFilter[1] !== 100 && f.properties.tu > totalUnitsFilter[1]) return false;
        return true;
      });
    }

    if (launchSoldFilter[0] !== 0 || launchSoldFilter[1] !== 100) {
      updatedData.features = updatedData.features.filter(f => {
        if (launchSoldFilter[0] !== 0 && f.properties.lp < launchSoldFilter[0]) return false;
        if (launchSoldFilter[1] !== 100 && f.properties.lp > launchSoldFilter[1]) return false;
        return true;
      });
    }

    if (psfRentalFilter[0] !== 0 || psfRentalFilter[1] !== 10) {
      updatedData.features = updatedData.features.filter(f => {
        if (f.properties.ar === -1) return false;
        if (psfRentalFilter[0] !== 0 && f.properties.ar < psfRentalFilter[0]) return false;
        if (psfRentalFilter[1] !== 10 && f.properties.ar > psfRentalFilter[1]) return false;
        return true;
      });
    }

    if (lastTransactDateFilter[0] !== null || lastTransactDateFilter[1] !== null) {
      const startYear = lastTransactDateFilter[0]?.getTime();
      const endYear = lastTransactDateFilter[1]?.getTime();
      updatedData.features = updatedData.features.filter(f => {
        const transactedDate = dateStrToMsec(f.properties.lt);
        if (!!startYear && transactedDate < startYear) return false;
        if (!!endYear && transactedDate > endYear) return false;
        return true;
      });
    }

    setData(updatedData);
    setLoading(false);
  };

  const hideMobileFilter = () => {
    if (document.getElementById('listingPropSidebar').classList.contains('show')) {
      document.getElementById('prop-close-button').click();
    }
  };

  const onSearchLocation = (searchTerm) => {
    const id = `${searchTerm.name},${searchTerm.address}`;
    const feature = rawData.features[idMapping[id]];
    setPersistPopup(true);
    setPopupInfo(feature);
    mapRef?.current?.flyTo({
      center: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
      zoom: 16,
    });
    hideMobileFilter();
    logNameSearch('MAP', session, searchTerm.name);
  };

  const onSearchClick = (searchTerm) => {};

  const onSchoolLocation = (school) => {
    setSchoolSearched([{ ...school, type: 'Schools' }]);
    setShowSchoolLabel(school);
    setRadiusMaskCoords({
      longitude: school.lng,
      latitude: school.lat
    });

    hideMobileFilter();
    flyTo(
      [school.lng, school.lat],
      checkMobile() ? OPTIMAL_CIRCLE_MOBILE : OPTIMAL_CIRCLE_DESKTOP
    );
  };

  const onSchoolClick = (searchTerm) => {};

  const resetLocations = () => {};

  const onMoveStart = () => {
    setFlyToLock(true);
  };

  const onMoveEnd = () => {
    setFlyToLock(false);
  };

  const onResetFilter = () => {
    const newFilterSelected = clearFilters(FILTER_OPTIONS);
    setFilterSelected(newFilterSelected);
    const newAgeFilter = [MIN_AGE_FILTER, MAX_AGE_FILTER];
    const newPriceFilter = [MIN_PRICE_FILTER, MAX_PRICE_FILTER];
    const newProfitableFilter = [MIN_PROFITABLE_FILTER, MAX_PROFITABLE_FILTER];
    const newPsfFilter = null;

    const newCompletionDateFilter = [null, null];
    const newPropTypeFilter = [];
    const newTenureFilter = [];
    const newSizeFilter = null;
    const newLocationFilter = [new Set(), new Set(), new Set()];

    const newLastTransactDateFilter = [null, null];
    const newHdbFilter = [0, 100];
    const newTotalUnitsFilter = [0, 100];
    const newLaunchSoldFilter = [0, 100];
    const newPsfRentalFilter = [0, 10];

    filterData(rawData, newAgeFilter, newPriceFilter, newPsfFilter, newProfitableFilter,
      newCompletionDateFilter, newPropTypeFilter, newTenureFilter, newSizeFilter, newLocationFilter,
      newLastTransactDateFilter, newHdbFilter, newTotalUnitsFilter, newLaunchSoldFilter, newPsfRentalFilter);
  };

  const onApplyFilter = () => {
    let newAgeFilter = [MIN_AGE_FILTER, MAX_AGE_FILTER];
    let newPriceFilter = [MIN_PRICE_FILTER, MAX_PRICE_FILTER];
    let newPsfFilter = null;
    let newProfitableFilter = [MIN_PROFITABLE_FILTER, MAX_PROFITABLE_FILTER];

    let newCompletionDateFilter = [null, null];
    let newPropTypeFilter = [];
    let newTenureFilter = [];
    let newSizeFilter = null;
    let newLocationFilter = [new Set(), new Set(), new Set()];

    let newLastTransactDateFilter = [null, null];
    let newHdbFilter = [0, 100];
    let newTotalUnitsFilter = [0, 100];
    let newLaunchSoldFilter = [0, 100];
    let newPsfRentalFilter = [0, 10];

    let hasFilterRequirePrice = false;

    if (hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      const hasMax = hasMaxRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      const minAge = hasMin ? filterSelected[FILTER_PROPERTY_AGE.field].value.min : MIN_AGE_FILTER;
      const maxAge = hasMax ? filterSelected[FILTER_PROPERTY_AGE.field].value.max : MAX_AGE_FILTER;
      newAgeFilter = [minAge, maxAge];
    }

    if (hasFilterSelected(FILTER_AVG_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      const minPrice = hasMin ? (filterSelected[FIELD_MIN_PRICE] / PRICE_MAGNIFIER) : MIN_PRICE_FILTER;
      const maxPrice = hasMax ? (filterSelected[FIELD_MAX_PRICE] / PRICE_MAGNIFIER) : MAX_PRICE_FILTER;
      newPriceFilter = [minPrice, maxPrice];
    }

    if (hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      const minPsf = hasMin ? filterSelected[FIELD_MIN_UNIT_PRICE] : null;
      const maxPsf = hasMax ? filterSelected[FIELD_MAX_UNIT_PRICE] : null;
      newPsfFilter = [minPsf, maxPsf];
    }

    if (hasFilterSelected(FILTER_PROFITABLE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      const hasMax = hasMaxRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      const minProfit = hasMin ? filterSelected[FILTER_PROFITABLE.field].value.min : MIN_PROFITABLE_FILTER;
      const maxProfit = hasMax ? filterSelected[FILTER_PROFITABLE.field].value.max : MAX_PROFITABLE_FILTER;
      newProfitableFilter = [minProfit, maxProfit];
    }

    if (hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)) {
      newCompletionDateFilter = [
        filterSelected[FILTER_COMPLETION_DATE.field[0]],
        filterSelected[FILTER_COMPLETION_DATE.field[1]]
      ];
    }

    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      newPropTypeFilter = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
    }

    if (hasFilterSelected(FILTER_TENURE, filterSelected)) {
      newTenureFilter = Object.keys(filterSelected[FILTER_TENURE.field])
        .filter(o => filterSelected[FILTER_TENURE.field][o])
        .map(o => getTenureValue(o));
    }

    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      const minSize = hasMin ? filterSelected[FIELD_MIN_SIZE] : null;
      const maxSize = hasMax ? filterSelected[FIELD_MAX_SIZE] : null;
      newSizeFilter = [minSize, maxSize];
      hasFilterRequirePrice = true;
    }

    if (hasFilterSelected(FILTER_LOCATION, filterSelected)) {
      if (hasAnyValue(filterSelected[FIELD_AREAS])) {
        newLocationFilter = [
          new Set(Object.keys(filterSelected[FIELD_AREAS])
            .filter(area => filterSelected[FIELD_AREAS][area] && !isRegion(area))),
          [],
          []
        ];
      } else if (hasAnyValue(filterSelected[FIELD_DISTRICTS])) {
        newLocationFilter = [
          [],
          new Set(Object.keys(filterSelected[FIELD_DISTRICTS])
            .filter(district => filterSelected[FIELD_DISTRICTS][district])),
          []
        ];
      } else if (hasAnyValue(filterSelected[FIELD_REGIONS])) {
        newLocationFilter = [
          [],
          [],
          new Set(Object.keys(filterSelected[FIELD_REGIONS])
            .filter(region => filterSelected[FIELD_REGIONS][region])
            .map(region => MARKET_SEGMENT_ID_MAP[region]))
        ];
      }
    }

    if (hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)) {
      newLastTransactDateFilter = [
        filterSelected[FILTER_MAP_TRANSACTION_DATE.field[0]],
        filterSelected[FILTER_MAP_TRANSACTION_DATE.field[1]]
      ];
      hasFilterRequirePrice = true;
    }

    if (hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      const hasMax = hasMaxRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      const minVal = hasMin ? filterSelected[FILTER_HDB_BUYERS.field].value.min : 0;
      const maxVal = hasMax ? filterSelected[FILTER_HDB_BUYERS.field].value.max : 100;
      newHdbFilter = [minVal, maxVal];
      hasFilterRequirePrice = true;
    }

    if (hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      const hasMax = hasMaxRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      const minVal = hasMin ? filterSelected[FILTER_TOTAL_UNITS.field].value.min : 0;
      const maxVal = hasMax ? filterSelected[FILTER_TOTAL_UNITS.field].value.max : 100;
      newTotalUnitsFilter = [minVal, maxVal];
      hasFilterRequirePrice = true;
    }

    if (hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      const hasMax = hasMaxRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      const minVal = hasMin ? filterSelected[FILTER_LAUNCH_PERC.field].value.min : 0;
      const maxVal = hasMax ? filterSelected[FILTER_LAUNCH_PERC.field].value.max : 100;
      newLaunchSoldFilter = [minVal, maxVal];
      hasFilterRequirePrice = true;
    }

    if (hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      const hasMax = hasMaxRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      const minVal = hasMin ? filterSelected[FILTER_PSF_RENTAL_6M.field].value.min : 0;
      const maxVal = hasMax ? filterSelected[FILTER_PSF_RENTAL_6M.field].value.max : 10;
      newPsfRentalFilter = [minVal, maxVal];
      hasFilterRequirePrice = true;
    }

    if (!priceDataAvailable && (filterRequirePriceData(newPriceFilter) || hasFilterRequirePrice)) {
      loadPriceMap(newAgeFilter, newPriceFilter, newPsfFilter, newProfitableFilter,
        newCompletionDateFilter, newPropTypeFilter, newTenureFilter, newSizeFilter, newLocationFilter,
        newLastTransactDateFilter, newHdbFilter, newTotalUnitsFilter, newLaunchSoldFilter, newPsfRentalFilter);
    } else {
      filterData(rawData, newAgeFilter, newPriceFilter, newPsfFilter, newProfitableFilter,
        newCompletionDateFilter, newPropTypeFilter, newTenureFilter, newSizeFilter, newLocationFilter,
        newLastTransactDateFilter, newHdbFilter, newTotalUnitsFilter, newLaunchSoldFilter, newPsfRentalFilter);
    }

    hideMobileFilter();
  };

  // upcoming related

  const getType = (prop) => {
    if (prop.a) return PROP_TYPE_APARTMENT;
    if (prop.s) return PROP_TYPE_SEMI_DETACH;
    if (prop.h) return PROP_TYPE_DETACHED;
    if (prop.e) return PROP_TYPE_TERRACE;
    if (prop.c) return PROP_TYPE_CONDO;
    return null;
  };

  const getIcon = (prop) => {
    if (prop.type === PROP_TYPE_APARTMENT || prop.type === PROP_TYPE_CONDO) return ICON_TYPE_APARTMENT;
    if (prop.type === PROP_TYPE_SEMI_DETACH || prop.type === PROP_TYPE_DETACHED || prop.type === PROP_TYPE_TERRACE) return ICON_TYPE_HOUSE;
    return ICON_TYPE_APARTMENT
  };

  const getLabel = (prop) => {
    if (prop.type === PROP_TYPE_APARTMENT) return 'APT';
    if (prop.type === PROP_TYPE_SEMI_DETACH) return 'SEMI-D';
    if (prop.type === PROP_TYPE_DETACHED) return 'DETACH';
    if (prop.type === PROP_TYPE_TERRACE) return 'TCE';
    if (prop.type === PROP_TYPE_CONDO) return 'CONDO';
    return 'EC';
  };

  const getPinColor = (prop) => {
    if (prop.type === PROP_TYPE_APARTMENT) return '#008009';
    if (prop.type === PROP_TYPE_SEMI_DETACH) return '#7e53f9';
    if (prop.type === PROP_TYPE_DETACHED) return '#927238';
    if (prop.type === PROP_TYPE_TERRACE) return '#003399';
    if (prop.type === PROP_TYPE_CONDO) return '#3554d1';
    return '#DE3163';
  };

  const getPinStyle = (prop) => {
    const style = { ...pinStyle };
    style.fill = getPinColor(prop);
    return style;
  };

  const formatProjectName = (name) => name.replace('â??', '\'');

  const loadUpcomingData = () => {
    setLoading(true);
    setErr(null);
    downloadFileUrl('map', 'upl0', 'json', (url) => {
      fetch(url)
      .then(resp => resp.json())
      .then(json => {
        setUpcomingData(json.map(d => ({
          ...d,
          project: formatProjectName(d.id.split(',')[0]),
          street: d.id.split(',')[1],
          lat: parseFloat(d.l.split(',')[0]),
          lng: parseFloat(d.l.split(',')[1]),
          type: getType(d),
        })));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErr('Failed to load map');
      })
    }, (err) => {
      setLoading(false);
      setErr('Failed to get map URL');
    });
  };

  useEffect(() => {
    if (mode === 'upcoming' && Object.keys(upcomingData).length === 0) {
      loadUpcomingData();
    }
  }, [mode]);

  const canHover = window.matchMedia('(hover: hover)').matches;

  const flyTo = (coordinates, zoom) => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    map.flyTo({
      center: coordinates,
      essential: true,
      zoom: zoom,
    });
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <Header11 user={user} />

      {err
        && <section className="layout-pt-sm">
          <div className="container">
            <div className="alert alert-danger" role="alert">
              {err}
            </div>
          </div>
        </section>
      }

      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {tierMsg !== null && tierMsg !== -1 
        && <div className="alert alert-warning map-alert text-center" role="alert">
          You have {tierMsg ?? 0} free map view{tierMsg > 1 ? 's' : ''} remaining as a free user. <strong><u><a href="/pricing">Become a PRO user</a></u></strong> to enjoy unlimited map view{tierMsg > 1 ? 's' : ''}!
          Click <strong><u><Link className="cursor-pointer" to={generateRefreshUrl(pathname)}>here</Link></u></strong> if you are already a PRO user
        </div>
      }

      {!loading && !tierBlocked
        && <section className={noSelectClass(user)}>
          <div className="heatmap">
            <Map
              ref={mapRef}
              maxBounds={[103.3, 1.0, 104.3, 1.7]}
              mapStyle={getMapStyleFromTheme(mapStyle)}
              style={{
                width: "100%",
                height: "100%",
              }}
              initialViewState={{
                longitude: 103.8198,
                latitude: 1.3521,
                zoom: 10
              }}
              attributionControl={false}
              onZoomEnd={evt => onZoomEnd(evt)}
              onMouseMove={evt => onViewPoint(evt)}
              onClick={evt => onViewClick(evt)}
              interactiveLayerIds={['heatmap']}
              onMoveStart={onMoveStart}
              onMoveEnd={onMoveEnd}
              transformRequest={transformMapboxStyleRequest}
            >
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />

              {mode === 'heatmap' && data && (
                <Source type="geojson" data={data}>
                  {/* <Layer {...heatmapLayer} /> */}
                  <Layer {...circleLayer} />
                </Source>
              )}

              {radiusMaskCoords
                && <>
                  <Source id="mask-data" type="geojson" data={turf.featureCollection([
                    turf.polygon([[
                      [-180, -90],
                      [-180, 90],
                      [180, 90],
                      [180, -90],
                      [-180, -90]
                    ], turf.circle(
                      [ radiusMaskCoords.longitude, radiusMaskCoords.latitude ], 1,
                      { units: 'kilometers' }
                    ).geometry.coordinates[0]])
                  ])}>
                    <Layer {...radiusMaskStyle} />
                  </Source>

                  <Source id="mask-label" type="geojson" data={{
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        geometry: {
                          type: 'Point',
                          coordinates: turf.destination(
                            [ radiusMaskCoords.longitude, radiusMaskCoords.latitude ], 1, 0,
                            { units: 'kilometers' }
                          ).geometry.coordinates
                        },
                        properties: {
                          label: 'approx 1km radius'
                        }
                      }
                    ]
                  }}>
                    <Layer {...radiusLabelStyle} />
                  </Source>
                </>
              }

              {schoolSearched.map((school, i) => (
                <Marker
                  key={`sch-${i}`}
                  latitude={school.lat}
                  longitude={school.lng}
                  anchor="bottom"
                >
                  <div>
                    <svg
                      height={60}
                      viewBox="0 0 24 24"
                      style={homePinStyle}
                    >
                      <path d={ICON} />
                      <circle cx="12" cy="10" r="8" fill="#fff" />
                      <g transform="scale(0.5),translate(14,9)">
                        <path d={getMarkerIcon(school)} className="mapMarkerIcon" />
                      </g>
                    </svg>
                  </div>
                </Marker>
              ))}

              {showSchoolLabel
                && <Popup
                  className="hide-popup-close-button ptr-none"
                  anchor="top"
                  latitude={showSchoolLabel.lat}
                  longitude={showSchoolLabel.lng}
                  onClose={() => {
                    setShowSchoolLabel(null);
                    setSchoolSearched([]);
                    setRadiusMaskCoords(null);
                  }}
                >
                  <div className={`px-10 ${noSelectClass(user)} watermark popup-dialog ptr-auto text-center`}>
                    <p className="text-14 pb-5 text-dark fw-600">
                      {showSchoolLabel.name}
                    </p>
                    {showSchoolLabel.street
                      && <p className="text-12 text-blue-1 fw-600 mb-5">
                        {showSchoolLabel.street}
                      </p>
                    }
                  </div>
                </Popup>
              }

              {mode === 'heatmap' && zoom > 10 && popupInfo && (
                <Popup
                  anchor="top"
                  longitude={Number(popupInfo.geometry.coordinates[0])}
                  latitude={Number(popupInfo.geometry.coordinates[1])}
                  closeOnClick={false}
                >
                  <div className={`px-10 ${noSelectClass(user)} watermark popup-dialog`}>
                    <p className="text-10 mb-5 text-center">&lt; Click on marker to persist popup &gt;</p>

                    <p className="text-15 text-dark fw-600">
                      {getPlaceName(popupInfo) ?? NA}
                    </p>
                    <p className="text-12 pb-5 text-blue-1 fw-600">
                      {getStreetName(popupInfo) ?? NA}
                    </p>

                    <p className="text-12 pt-5">
                      Type: <span className="text-dark">{convertIntToPropertyType(popupInfo.properties["tp"]).join(', ')}</span>
                      <br />
                      Tenure: <span className="text-dark">{convertIntToTenureType(popupInfo.properties.tt).join(', ')}</span>
                      <br />
                      Completion: <span className="text-dark">{popupInfo.properties.cd}</span>
                      <br />
                      {'Profitable %'}: <span className="text-dark">{
                        popupInfo.properties[FIELD_PROFIT] !== -1 ? (
                          popupInfo.properties.rx > 0
                          ? `${(popupInfo.properties[FIELD_PROFIT] ?? 0).toFixed(1)}%`
                          : NA
                        ) : NA
                      }</span>
                      <br />
                      {'Total Transactions'}: <span className="text-dark">{
                        (popupInfo.properties[FIELD_TRANSACTIONS] ?? 0).toFixed(0)
                      }</span>
                      {popupInfo.properties[FIELD_6PC_PROFIT] !== -1
                        && <>
                          <br />
                          {'> 6% Annualized Gain'}: <span className="text-dark">{
                            (popupInfo.properties[FIELD_6PC_PROFIT] ?? 0).toFixed(0)
                          }</span>
                        </>
                      }
                      {popupInfo.properties[FIELD_FAIR_PRICE] !== -1
                        && <>
                          {/* <br />
                          {'Fair PSF'}: ${
                            (popupInfo.properties.fp ?? 0).toFixed(2)
                          } */}
                          <br />
                          {'Avg PSF (Past 3 mths)'}: <span className="text-dark">${
                            (popupInfo.properties.fp - popupInfo.properties.fd).toFixed(2)
                          } {popupInfo.properties.fd > 0
                            ? <span className="text-green-2">(Underpriced)</span>
                            : <span className="text-red-1">(Overpriced)</span>
                            }</span>
                        </>
                      }
                    </p>

                    <Link
                      className="text-blue-1 unfocused"
                      to={`/project/${encodeURIComponent(popupInfo.properties.id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here to view project
                    </Link>
                  </div>
                </Popup>
              )}

              {/* upcoming map */}

              {mode === 'upcoming' && upcomingData.map((location, i) => (
                <Marker
                  key={i}
                  latitude={location.lat}
                  longitude={location.lng}
                  anchor="bottom"
                  onClick={e => {
                    e.originalEvent.stopPropagation();
                    setFreezePopup(true);
                    setUpcomingPopupInfo(location);
                  }}
                >
                  <div
                    ref={(el) => {
                      if (el && canHover) {
                        const isNearBottom = false; //checkMarkerPosition(el);
                        // el.style.opacity = isNearBottom ? '0.5' : '1';
                        el.style.pointerEvents = isNearBottom ? 'none' : 'auto';
                      }
                    }}
                    onMouseEnter={(event) => {
                      if (location !== popupInfo) {
                        setFreezePopup(false);
                        setUpcomingPopupInfo(location);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!freezePopup) {
                        setUpcomingPopupInfo(null)
                      }
                    }}
                  >
                    <svg
                      height={60}
                      viewBox="0 0 24 24"
                      style={getPinStyle(location)}
                    >
                      <path d={ICON} />
                      <circle cx="12" cy="10" r="8" fill="#fff" />
                      <>
                        <text x='12' y='15' textAnchor="middle" fill={getPinColor(location)} style={{ fontSize: 3, fontWeight: 700 }} >
                          {getLabel(location)}
                        </text>
                        {
                          getIcon(location) === ICON_TYPE_APARTMENT
                            ? <g transform="scale(0.04),translate(168,78)">
                                <path d={APARTMENT_ICON} className="mapMarkerIcon" />
                              </g>
                            : <g transform="scale(0.4),translate(18,7)">
                                <path d={PROPERTY_ICON} className="mapMarkerIcon" />
                              </g>
                        }
                      </>
                    </svg>
                  </div>
                </Marker>
              ))}

              {mode === 'upcoming' && upcomingPopupInfo && (
                <Popup
                  className="hide-popup-close-button ptr-none"
                  anchor="top"
                  latitude={upcomingPopupInfo.lat}
                  longitude={upcomingPopupInfo.lng}
                  onClose={() => {
                    setUpcomingPopupInfo(null);
                  }}
                >
                  <div className={`px-10 ${noSelectClass(user)} watermark popup-dialog ptr-auto`}>
                    <p className="text-12 mb-10">&lt; Click on marker to persist popup &gt;</p>

                    <p className="text-15 pb-5 text-dark fw-600">
                      {upcomingPopupInfo.project}
                    </p>
                    <p className="text-12 pb-5 text-blue-1 fw-600 mb-5">
                      {upcomingPopupInfo.street} (D{upcomingPopupInfo.d})
                    </p>

                    <p className="text-12 pt-10 border-top-light">
                      {/* Developer: {popupInfo.n} */}
                      {upcomingPopupInfo.type
                        && <>
                          Type: {upcomingPopupInfo.type}
                          <br />
                        </>
                      }
                      Total Units: {upcomingPopupInfo.t}
                      {upcomingPopupInfo.a
                        && <>
                          <br />
                          No. of Apartment: {upcomingPopupInfo.a}
                        </>
                      }
                      {upcomingPopupInfo.s
                        && <>
                          <br />
                          No. of Semi-Detach: {upcomingPopupInfo.s}
                        </>
                      }
                      {upcomingPopupInfo.h
                        && <>
                          <br />
                          No. of Detached House: {upcomingPopupInfo.h}
                        </>
                      }
                      {upcomingPopupInfo.e
                        && <>
                          <br />
                          No. of Terrace: {upcomingPopupInfo.e}
                        </>
                      }
                      {upcomingPopupInfo.c
                        && <>
                          <br />
                          No. of Condo: {upcomingPopupInfo.c}
                        </>
                      }
                      {upcomingPopupInfo.y
                        && <>
                          <br />
                          Expected TOP Year: {upcomingPopupInfo.y}
                        </>
                      }
                    </p>

                    <Link
                      className="text-blue-1 unfocused ptr-auto"
                      to={`http://maps.google.com/maps?q=${upcomingPopupInfo.l}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      onTouchStart={(e) => e.stopPropagation()}
                    >
                      Show in Google Map
                    </Link>
                  </div>
                </Popup>
              )}

            </Map>

            <div className="heatmap-selector noselect">
              <HeatmapSelector
                pri={pri}
                setPri={setPri}
                sec={sec}
                setSec={setSec}
                mode={mode}
                setMode={setMode}
                // PROPERTY_AGES={PROPERTY_AGES}
                // ageFilter={ageFilter}
                // minAge={MIN_AGE_FILTER}
                // maxAge={MAX_AGE_FILTER}
                // setAgeFilter={setAgeSelection}
                // priceFilter={priceFilter}
                // minPrice={MIN_PRICE_FILTER}
                // maxPrice={MAX_PRICE_FILTER}
                // setPrice={setPriceSelection}
              />

              {mode === 'upcoming'
                && <TableView
                  properties={upcomingData}
                />
              }
            </div>

            <div className="maptheme-selector">
              <MapStyleSelector
                theme={mapStyle}
                setTheme={setMapStyle}
              />
            </div>

            {/* <div className="map-legend text-10">
              <span className="low">LOW</span>
              <span className="high">HIGH</span>
            </div> */}

            <div
              className="offcanvas offcanvas-start filter-bar-nopad"
              tabIndex="-1"
              id="listingPropSidebar"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasLabel">
                  Search & Filter Properties
                </h5>
                <button
                  id="prop-close-button"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>

              <div className="offcanvas-body">
                <aside className="sidebar y-gap-40 xl:d-block">
                  <Sidebar
                    onSearchLocation={onSearchLocation}
                    onSearchClick={onSearchClick}
                    resetLocations={resetLocations}
                    filters={filterSelected}
                    setFilters={setFilterSelected}
                    options={FILTER_OPTIONS}
                    disableSearchEnter
                    onSchoolLocation={onSchoolLocation}
                    onSchoolClick={onSchoolClick}
                    disableSchoolEnter
                  />
                </aside>
              </div>

              <div className="row ml-10 mr-10 mt-10 mb-10">
                <div className="col-3">
                  <button
                    className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                    onClick={onResetFilter}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-9">
                  <button
                    className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                    onClick={onApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </div>
           
          </div>
        </section>
      }

    </>
  );
};

HeatmapPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

HeatmapPage.defaultProps = {
  user: null,
};

export default HeatmapPage;
