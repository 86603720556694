const Block1 = () => {
  return (
    <>
      <div className="col-lg-5">
        <h2 className="text-30 fw-600">About REALSMART.SG</h2>
        <p className="text-dark-1 mt-60 lg:mt-40 md:mt-20">
          Realsmart enables everyone to make data informed choices when buying or selling property.
          <br /><br />
          For life&apos;s biggest purchase and mortgages that affect your future, trust hard data—because data doesn&apos;t lie, and financial stress isn&apos;t worth the gamble.
          <br /><br />
          Behind Realsmart, we are a team of engineers from some of the world&apos;s largest tech companies. We aim to improve efficiency and transparency in this industry. We contribute our skills in building this free platform to help you make smarter property purchases.
          <br /><br />
          Take control by simply looking up data yourself and you would avoid mistakes that cost hundreds of thousands, even millions of $$$.
          <br /><br />
          Have a request for new features or just want to chat?
          <br />Send an email over to <a className="text-blue-1 fw-500" href="mailto:hello@realsmart.sg">hello@realsmart.sg</a>
          <br /><br />
        </p>
      </div>

      <div className="col-lg-6">
        <img
          src="/img/general/skyline.png"
          alt="image"
          className="rounded-4 w-100 mt-50 mb-50"
        />
      </div>
    </>
  );
};

export default Block1;
