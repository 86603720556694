import {
  NA,
  calculateAge,
  calculateLeaseRemain
} from "@/utils/convert";

const GeneralGroup = ({ item }) => {

  const ellipsize = (str, len) => str.slice(0, len) + (str.length > len ? '…' : '');

  return (
    <div className="px-15 py-5 mt-10 proj-perf proj-comp-perf">
      <div className="d-flex items-center text-blue-1 text-12">
        <i className="icon-city-2 fw-600 mr-10"></i>
        <div className="fw-600">General</div>
      </div>

      <div className="row x-gap-50 y-gap-0 text-12 pt-5">
        <div className="col-12">
          <div className="">
            Developer: {item['Developer'] ? ellipsize(item['Developer'], 38) : NA}
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Type: {item['property_types']
              ? ellipsize(item['property_types']?.join(', '), 42)
              : NA
            }
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Completion: {item['latest_completion_date'] ?? NA}
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Total Units: {
              (!item?.['Total Number of Units in Project'] || item['Total Number of Units in Project'] < 0)
                ? NA : item['Total Number of Units in Project']
            }
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Unit Size: {
              item['min_sqft_size'] && item['max_sqft_size']
                ? (
                  item['min_sqft_size'] !== item['max_sqft_size']
                    ? `${
                        item['min_sqft_size'].toLocaleString()
                      } - ${
                        item['max_sqft_size'].toLocaleString()
                      } sft`
                    : `${
                        item['min_sqft_size'].toLocaleString()
                      } sft`
                )
                : NA
            }
          </div>
        </div>

        <div className="col-12">
          <div className="">
          Tenure: {item['tenure_available']
            ? ellipsize(item['tenure_available']?.join(', '), 40)
            : NA
          }
          </div>
        </div>

        <div className="col-12">
          <div className="">
          Age: {calculateAge(item['latest_tenure_start_date'], item['latest_completion_date'])}
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Lease Remaining: {
              calculateLeaseRemain(
                item['tenure_years_available'],
                item['latest_tenure_start_date']
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralGroup;
