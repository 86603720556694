import { useState } from 'react';
import PropTypes from "prop-types";

import CustomTable from '../transactions/CustomTable';
import { NA, formatDistanceLabel } from '@/utils/convert';

const tableConfig = {
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const CompareTable = ({
  data,
  property,
  goToProperty
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Project',
      accessor: 'id',
      disableGlobalFilter: true,
      Cell: (row) =>
        row.cell.value === property.id
          ? <div className="fw-500">
              {row.cell.value.split(',')[0]}
            </div>
          : <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row.cell.value.split(',')[0], row.cell.value.split(',')[1])}
              // href={`/project/${encodeURIComponent(`${row.cell.value.split(',')[0]},${row.cell.value.split(',')[1]}`)}`}
              // target="_blank"
              // rel="noreferrer noopener"
            >
              {row.cell.value.split(',')[0]}
            </a>
    },
    {
      Header: 'Distance',
      accessor: 'distance',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>
        {formatDistanceLabel(row.cell.value ?? 0, true)}
      </div>
    },
    {
      Header: 'Profitable (%)',
      accessor: 'perc_profitable',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div className={
        isNaN(parseFloat(row.cell.value)) || row.cell.value === property.perc_profitable ? 'text-dark'
          : row.cell.value > property.perc_profitable ? 'text-green-2' : 'text-red-1'
      }>
        {/* {isNaN(parseFloat(row.cell.value)) ? NA : `${row.cell.value.toFixed(1)}%`} */}
        {isNaN(parseFloat(row.cell.value)) ? '0.0%' : `${row.cell.value.toFixed(1)}%`}
      </div>
    },
    {
      Header: 'Avg PSF (Last 3mths)',
      accessor: 'avg_psf_last3mths',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div className={
        isNaN(parseFloat(row.cell.value)) || row.cell.value === property.avg_psf_last3mths ? 'text-dark'
          : row.cell.value < property.avg_psf_last3mths ? 'text-green-2' : 'text-red-1'
      }>
        {isNaN(parseFloat(row.cell.value)) ? NA : `$${row.cell.value.toFixed(2)}`}
      </div>
    },
    {
      Header: 'Property Types',
      accessor: 'property_types',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
      Cell: (row) => <div>
        {row.cell.value?.join(', ')}
      </div>
    },
    {
      Header: 'HDB Buyers %',
      accessor: 'perc_HDB_buyer',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>
        {row.cell.value !== null && row.cell.value !== undefined ? `${row.cell.value.toFixed(0)}%` : ''}
      </div>
    },
    {
      Header: 'Completion',
      accessor: 'latest_completion_date',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Tenure Available',
      accessor: 'tenure_available',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
      Cell: (row) => <div>
        {row.cell.value?.split(',').filter(v => !!v && v !== 'nan').join(', ')}
      </div>
    },
    {
      Header: 'Total Units',
      accessor: 'total_units',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>
        {row.cell.value !== -1 ? row.cell.value?.toLocaleString() : '-'}
      </div>
    },
    {
      Header: '% Sold at Launch',
      accessor: 'perc_sold_at_launch',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>
        {row.cell.value !== null && row.cell.value !== undefined ? `${row.cell.value.toFixed(0)}%` : '-'}
      </div>
    },
    {
      Header: 'Count Similar Primary Schools',
      accessor: 'Primary Schools',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    {
      Header: 'Count Similar Subways',
      accessor: 'Subways',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    // {
    //   Header: 'Similar Property Types',
    //   accessor: 'Property Types',
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 80,
    //   Cell: (row) =>
    //     row.cell.value === undefined ? <div className="text-dark">-</div>
    //       : (
    //         row.cell.value ? <div className="text-green-2">✓</div>
    //           : <div className="text-red-1">X</div>
    //       )
    // },
    // {
    //   Header: 'Similar Tenure',
    //   accessor: 'Tenure',
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 80,
    //   Cell: (row) =>
    //     row.cell.value === undefined ? <div className="text-dark">-</div>
    //       : (
    //         row.cell.value ? <div className="text-green-2">✓</div>
    //           : <div className="text-red-1">X</div>
    //       )
    // },
    // {
    //   Header: 'Similar Total Units',
    //   accessor: 'Units',
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 80,
    //   Cell: (row) =>
    //     row.cell.value === undefined ? <div className="text-dark">-</div>
    //       : (
    //         row.cell.value ? <div className="text-green-2">✓</div>
    //           : <div className="text-red-1">X</div>
    //       )
    // },
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const tableData = [
    property,
    ...data
  ];

  return (
    <CustomTable
      data={tableData}
      tableConfig={tableConfig}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
    />
  );
};

CompareTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CompareTable;
