import { calculateDist, formatDistanceLabel } from "@/utils/convert";

const NearbySchools = ({
  schools,
  blockMarker,
  onSelect,
  onSchoolSelect,
  highlightList,
  isMaximized
}) => {
  const generateName = (name) => {
    if (name?.indexOf(' Primary ') !== -1) {
      const chunks = name?.split(' Primary ');
      if (chunks.length === 2) {
        return (
          <span>{chunks[0]}<span className="text-green-2 fw-500"> Primary </span>{chunks[1]}</span>
        );
      }
    }
    return name;
  };

  const isXl = window.matchMedia("(min-width: 1200px)").matches;

  const showItem = (val) => highlightList.length === 0
    || highlightList.find(item => (item.id && val.id) ? (item.id === val.id) : (item.name === val.name));

  const lists = [
    {
      title: 'Primary Schools within 1km',
      list: schools.origin.filter((sch) => sch.level === 'primary')
    },
    {
      title: 'Secondary Schools within 1km',
      list: schools.origin.filter((sch) => sch.level === 'secondary')
    }
  ];

  const isSameProperty = (location1, location2) => location2.type !== 'school'
    && location1.name === location2.name && location1.postal === location2.postal;

  const isSameSchool = (location1, location2) => location2.type === 'school'
    && location1.id === location2.id;

  const isOpaque = (prop, school) => !blockMarker
    || blockMarker.find(p => isSameProperty(prop, p))
    || blockMarker.find(s => isSameSchool(school, s));

  return (
    <div className="pt-10 mb-50">
      {schools.origin.length === 0
        && <div className="py-20 text-14 fw-500 text-center">No schools nearby</div>
      }

      {lists.filter(l => l.list?.length > 0).map(l => (
        <div className="row mt-20 mb-10">
          <div className="d-flex items-center">
            <i className="icon-compass text-20 mr-10"></i>
            <div className="text-20 fw-500">{l.title}</div>
          </div>

          {l.list.map((subsection) => (
            <div className={isXl && isMaximized ? 'col-4' : 'col-lg-6 col-md-12'}>
              <div className="">
                <div className="mb-20 md:mb-10 mt-20">
                  <div
                    className="d-flex items-center mb-10 hover-blue-row cursor-pointer"
                    onClick={() => {
                      onSchoolSelect({
                        ...subsection,
                        type: 'school',
                        zoom: 17
                      });
                    }}
                  >
                    <div className="ml-10 text-14 fw-500">{subsection.name}</div>
                  </div>

                  <div
                    className={`row y-gap-10 x-gap-0 px-10 ${
                      isXl ? 'surrounding-fh' : ''
                    }`}
                  >
                    {subsection.props.map((val, i) => (
                      <div
                        className="col-12 border-top-light"
                        key={i}
                        style={{ opacity: isOpaque(val, subsection) ? 1 : 0.2 }}
                      >
                        <div
                          className="row items-center justify-between"
                        >
                          <div className="col-9">
                            <div
                              className="text-13 hover-blue-row cursor-pointer"
                              onClick={() => {
                                onSelect({
                                  ...val,
                                  type: 'prop',
                                  schools: schools.props.find(p => isSameProperty(val, p)).schools
                                });
                              }}
                            >
                              {val.address} <span className="text-blue-1 pl-10">{val.postal}</span>
                            </div>
                          </div>

                          <div className="col-3">
                            <div className="text-13 text-right">
                              {formatDistanceLabel(val.distance > 1000 ? 1000 : val.distance)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NearbySchools;
