import { Link } from "react-router-dom";
import Slider from "react-slick";
import ScrollDownButton from "./ScrollDownButton";
import ScrollUpButton from "./ScrollUpButton";
import { useEffect, useRef } from "react";

const FEATURES = [
  // {
  //   id: 1,
  //   // image: '/img/general/prop_list.jpg',
  //   image: '/img/general/fp_props.png',
  //   title: 'Search for Property in Singapore',
  //   desc: 'REALSMART provides the most comprehensive list of private properties and profitability details, all right at your fingertips',
  //   link: '/search',
  //   linkLabel: 'Property Search',
  //   linkIcon: 'icon-search',
  //   dealyAnimation: "100",
  // },
  {
    id: 2,
    // image: '/img/general/prop_map.jpg',
    image: '/img/general/fp_map.png',
    title: 'Property Heatmap',
    desc: 'Search for the most profitable properties in Singapore at a single glance with REALSMART Map View',
    link: '/map',
    linkLabel: 'Map View',
    linkIcon: 'icon-nearby',
    dealyAnimation: "200",
  },
  {
    id: 3,
    // image: '/img/general/prop_compare.jpg',
    image: '/img/general/fp_compare.png',
    title: 'Compare Multiple Properties',
    desc: 'Compare different properties in Singapore to make the most informed decision on your next property investment',
    link: '/map',
    linkLabel: 'Compare Properties',
    linkIcon: 'icon-city-2',
    dealyAnimation: "300",
  },
  {
    id: 4,
    // image: '/img/general/prop_units.jpg',
    image: '/img/general/fp_units.png',
    title: 'Deepdive into Property Units',
    desc: 'REALSMART gives you a birdeye view of all units for properties you are interested in and understand the full history of the unit',
    link: '/map',
    linkLabel: 'Units View',
    linkIcon: 'icon-home',
    dealyAnimation: "100",
  },
  {
    id: 5,
    // image: '/img/general/prop_profit.jpg',
    image: '/img/general/fp_profits.png',
    title: 'Discover Profitable Properties',
    desc: 'Track all profitable transactions for any property in Singapore to find the next potentially big annualized gain',
    link: '/profits',
    linkLabel: 'Profits Search',
    linkIcon: 'icon-price-label',
    dealyAnimation: "200",
  },
  {
    id: 6,
    // image: '/img/general/prop_tx.jpg',
    image: '/img/general/fp_transacts.png',
    title: 'Search All Transactions',
    desc: 'Quickly find any private property transactions to analyze market trends',
    link: '/transactions',
    linkLabel: 'Transaction Search',
    linkIcon: 'icon-ticket',
    dealyAnimation: "300",
  },
  {
    id: 7,
    // image: '/img/general/prop_rent.jpg',
    image: '/img/general/fp_rent.png',
    title: 'Find Rental Data',
    desc: 'REALSMART gives you all available rental data for private properties in Singapore for the best insights to rental trends',
    link: '/rentals',
    linkLabel: 'Rental Search',
    linkIcon: 'icon-man',
    dealyAnimation: "100",
  },
  // {
  //   id: 8,
  //   // image: '/img/general/prop_nearby.jpg',
  //   image: '/img/general/fp_nearby.png',
  //   title: 'Find Amenities Near Property',
  //   desc: 'See amenities around any private properties conveniently with REALSMART',
  //   link: '/search',
  //   linkLabel: 'Nearby Amenities',
  //   linkIcon: 'icon-destination',
  //   dealyAnimation: "100",
  // },
];

const SETTINGS = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipe: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
};

const MapPage = ({ onNextPage, onPrevPage }) => {
  const sliderRef = useRef(null);

  // useEffect(() => {
  //   sliderRef.current?.slickPlay();
  // }, []);

  return (
    <div className="lp-full">
      <div className="lp-features-container">
        <div
          className="lp-features"
          // onTouchMove={e => e.stopPropagation()}
          onTouchStart={(e) => {
            // Store the initial touch position
            e.currentTarget.touchStartX = e.touches[0].clientX;
            e.currentTarget.touchStartY = e.touches[0].clientY;
          }}
          onTouchMove={(e) => {
            const touchEndX = e.touches[0].clientX;
            const touchEndY = e.touches[0].clientY;
            
            const deltaX = Math.abs(touchEndX - e.currentTarget.touchStartX);
            const deltaY = Math.abs(touchEndY - e.currentTarget.touchStartY);
        
            // Stop propagation if horizontal movement is greater than vertical
            if (deltaX > deltaY) {
              e.stopPropagation();
            }
          }}
        >
          <Slider ref={sliderRef} {...SETTINGS}>
            {FEATURES.map(item => (
              <div
                className="-type-1 bg-white rounded-4 pt-40 pb-30 px-40"
                key={item.id}
                data-aos="fade"
                data-aos-delay={item.dealyAnimation}
              >
                <div className="lp-feature-item">
                  <div className="lp-feature-item-container">
                    <img src={item.image} alt="image" className="size-60" loading="lazy" />
                    <h4 className="text-18 fw-500 text-blue-1 mt-20 text-center">{item.title}</h4>
                    <p className="lh-18 text-14 text-dark-1 text-center mt-10">
                      {item.desc}
                    </p>
                    <Link
                      to={item.link}
                      className="button -sm -dark-1 bg-blue-1 text-white text-12 px-20 py-10"
                    >
                      <i className={`${item.linkIcon} mr-10`} />
                      {item.linkLabel}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <ScrollUpButton onPrevPage={onPrevPage} dark />
        <ScrollDownButton onNextPage={onNextPage} dark />
      </div>
    </div>
  );
};

export default MapPage;
