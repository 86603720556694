import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { setNullForZeroSeries } from "@/utils/chart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

const getOptions = (xLabel, yLabel) => ({
  responsive: true,
  maintainAspectRatio: false,

  scales: {      
    x: {
      title: xLabel ? {
        display: true,
        text: xLabel,
        font: {
          size: 10,
        },
        padding: {
          top: 10,
        },
      }: {},
    },
    y: {
      title: yLabel ? {
        display: true,
        text: yLabel,
        font: {
          size: 10,
        },
        padding: {
          right: 10,
        },
      } : {},
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },

    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "#1967d2",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 4,
    },
  },
});

const DistributionChart = ({
  data,
  tooltipTitleCallback,
  tooltipLabelCallback,
  xAxisLabel,
  yAxisLabel,
  height
}) => {
  const chartData = {
    labels: data.labels,
    datasets: [...data.data.map(d => {
        return {
          type: 'bar',
          label: d.name,
          data: setNullForZeroSeries(d.data),
          backgroundColor: d.color,
        }
      }),
      ...data.data.map(d => {
        return {
          type: 'line',
          label: d.name,
          data: setNullForZeroSeries(d.data),
          fill: false,
          backgroundColor: d.color,
          borderColor: d.color,
          borderWidth: 2,
          tension: 0.4,
        }
      })
    ]
  };

  const options = getOptions(xAxisLabel, yAxisLabel);

  return (
    <div className="widget-content">
      <Bar
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            tooltip: {
              callbacks: {
                title: (context) => tooltipTitleCallback(context),
                label: (context) => tooltipLabelCallback(context),
              }
            },
          },
        }}
        data={chartData}
        height={height}
      />
    </div>
  );
};

DistributionChart.propTypes = {
  data: PropTypes.object.isRequired,
  tooltipTitleCallback: PropTypes.func.isRequired,
  tooltipLabelCallback: PropTypes.func.isRequired,
};

export default DistributionChart;
