import PropTypes from "prop-types";

import {
  convertTHeaderToApiCol,
  getPropTypeFilterSelected,
  includeProjectTHeader
} from "@/utils/convert";
import {
  getRentalsTable,
  logRentalSearchFilter
} from "@/utils/api";
import { RENTAL_TABLE_HEADERS } from "@/utils/rentalHeaders";
import {
  FIELD_END_DATE,
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FIELD_NAME,
  FIELD_START_DATE,
  FILTER_DATE,
  FILTER_LEASE_DATE,
  FILTER_PROPERTY_INPUT,
  FILTER_RENTAL_PROPERTY_TYPE,
  FILTER_RENT_AREA,
  FILTER_RENT_PRICE,
  FILTER_RENT_PSF,
  hasFilterSelected,
  hasValidInput,
  isValidDate
} from "@/utils/filter";
import TableSearchPage from "../tablepage";

const metadata = {
  title: "Rental Search | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const DEFAULT_COLUMNS = RENTAL_TABLE_HEADERS;
const REQUIRED_COLUMNS = includeProjectTHeader(convertTHeaderToApiCol(DEFAULT_COLUMNS));

const FILTER_OPTIONS = [
  FILTER_PROPERTY_INPUT,
  FILTER_LEASE_DATE,
  FILTER_RENTAL_PROPERTY_TYPE,
  FILTER_RENT_PRICE,
  FILTER_RENT_PSF,
  FILTER_RENT_AREA,
];

const GROUP_OPTIONS = [
  { label: 'Project & Street', value: 'project', options: ['Project Name', 'Street Name'], accessors: ['id', 'Street Name'] },
];

const DEFAULT_SORT_ORDER = {
  'Lease Commencement Date': 'desc',
  'count': 'desc',
};

const RentalSearchPage = ({ user, session }) => {

  const createFilter = (filterSelected) => {
    const filters = {}

    if (hasFilterSelected(FILTER_DATE, filterSelected)) {
      if (isValidDate(filterSelected[FIELD_START_DATE]) && isValidDate(filterSelected[FIELD_END_DATE])) {
        filters['Lease Commencement Date'] = `d:bt:${filterSelected[FIELD_START_DATE].getTime()}:${filterSelected[FIELD_END_DATE].getTime()}`;
      } else if (isValidDate(filterSelected[FIELD_START_DATE])) {
        filters['Lease Commencement Date'] = `d:gte:${filterSelected[FIELD_START_DATE].getTime()}`;
      } else {
        filters['Lease Commencement Date'] = `d:lte:${filterSelected[FIELD_END_DATE].getTime()}`;
      }
    }

    if (hasFilterSelected(FILTER_RENTAL_PROPERTY_TYPE, filterSelected)) {
      filters['Property Type'] = getPropTypeFilterSelected(filterSelected, FILTER_RENTAL_PROPERTY_TYPE);
    }
    
    if (hasFilterSelected(FILTER_RENT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      if (hasMin && hasMax) {
        filters['Monthly Rent ($)'] = `i:bt:${filterSelected[FIELD_MIN_PRICE]}:${filterSelected[FIELD_MAX_PRICE]}`;
      } else if (hasMin) {
        filters['Monthly Rent ($)'] = `i:gte:${filterSelected[FIELD_MIN_PRICE]}`;
      } else {
        filters['Monthly Rent ($)'] = `i:lte:${filterSelected[FIELD_MAX_PRICE]}`;
      }
    }

    if (hasFilterSelected(FILTER_RENT_PSF, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      if (hasMin && hasMax) {
        filters['est_rental_psf_sgd'] = `i:bt:${filterSelected[FIELD_MIN_UNIT_PRICE]}:${filterSelected[FIELD_MAX_UNIT_PRICE]}`;
      } else if (hasMin) {
        filters['est_rental_psf_sgd'] = `i:gte:${filterSelected[FIELD_MIN_UNIT_PRICE]}`;
      } else {
        filters['est_rental_psf_sgd'] = `i:lte:${filterSelected[FIELD_MAX_UNIT_PRICE]}`;
      }
    }

    if (hasFilterSelected(FILTER_RENT_AREA, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      if (hasMin) {
        filters['Floor Area (SQFT) Lower'] = `i:gte:${filterSelected[FIELD_MIN_SIZE]}`;
      }
      if (hasMax) {
        filters['Floor Area (SQFT) Upper'] = `i:lte:${filterSelected[FIELD_MAX_SIZE]}`;
      }
    }

    // handle property or street name search
    if (filterSelected.id) {
      filters['conditions'] = generateProjectStreetConditional(filterSelected.id);
    } else if (hasFilterSelected(FILTER_PROPERTY_INPUT, filterSelected) && filterSelected[FIELD_NAME]?.length > 2) {
      filters['__RENTSEARCH__'] = filterSelected[FIELD_NAME];
    }

    return filters;
  };

  const generateProjectStreetConditional = (id) => {
    const chunks = id.split(',');
    const searchTerm = {
      name: chunks[0],
      address: chunks[1],
    };
    const condition = [
      {
        and: {
          'Project Name': `:eq:${searchTerm.name}`,
          'Street Name': `:eq:${searchTerm.address}`,
        }
      }
    ];
    if (searchTerm.name.toLowerCase() === searchTerm.address.toLowerCase()) {
      condition.push({
        and: {
          'Project Name': `:eq:N.A.`,
          'Street Name': `:eq:${searchTerm.address}`,
        }
      });
    }
    return [
      {
        or: condition,
      }
    ]
  };

  return (
    <TableSearchPage
      user={user}
      session={session}
      metadata={metadata}
      DEFAULT_COLUMNS={DEFAULT_COLUMNS}
      REQUIRED_COLUMNS={REQUIRED_COLUMNS}
      FILTER_OPTIONS={FILTER_OPTIONS}
      GROUP_OPTIONS={GROUP_OPTIONS}
      DEFAULT_SORT_ORDER={DEFAULT_SORT_ORDER}
      fetchApi={getRentalsTable}
      cacheUrl="https://realsmart.global.ssl.fastly.net/rentals10.json"
      pageCode="r"
      pageLabel="RENTAL"
      createFilter={createFilter}
      logApi={logRentalSearchFilter}
      title="Search Transactions"
    />
  );
};

RentalSearchPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

RentalSearchPage.defaultProps = {
  user: null,
};

export default RentalSearchPage;
