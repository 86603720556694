import { getCount, getMean, getSum, round0, round1, round2, sortAsc } from "./aggregation";
import { getColor } from "./colors";
import { MONTHS } from "./time";

export const INTERVAL_TYPE_MONTH = 'mth';
export const INTERVAL_TYPE_YEAR = 'yr';

export const PERIOD_TYPE_ALL = 'all';
export const PERIOD_TYPE_12M = '12mth';
export const PERIOD_TYPE_2Y = '2y';
export const PERIOD_TYPE_3Y = '3y';
export const PERIOD_TYPE_5Y = '5y';
export const PERIOD_TYPE_10Y = '10y';

export const DIMENSION_DATE = 'd';

export const AGG_TYPE_SUM = 'sum';
export const AGG_TYPE_AVG = 'avg';
export const AGG_TYPE_COUNT = 'count';

export const MULTICHART_SUBTYPE_BARS = 'bars';
export const MULTICHART_SUBTYPE_LINES = 'lines';

export const VALUE_TYPE_PRICE = 'price';
export const VALUE_TYPE_COUNT = 'count';
export const VALUE_TYPE_PERC = 'perc';

export const RESOLUTION_TYPE_HIGH = 20;
export const RESOLUTION_TYPE_LOW = 10;

/**
  Handle multiple properties
  Data will be in format:
    {
      "{project name},{street}": {
        "d": {date object},
        "x": {dimension 1},
        "y": {dimension 2},
      }
    }

  Dimensions provided will be:
    {
      "bars": {
        "field": {field name},
        "agg": {aggregation type},
      },
    },
    {
      "lines": {
        "field": {field name},
        "agg": {aggregation type},
      },
    }
*/

const getMinFromArr = (field, arr, defaultValue) => arr?.length > 0
  ? Math.min(...arr.map(a => a[field]))
  : defaultValue;

const getMaxFromArr = (field, arr, defaultValue) => arr?.length > 0
  ? Math.max(...arr.map(a => a[field]))
  : defaultValue;

const getMinFromMultiArr = (field, arrs, defaultValue) => arrs?.length > 0
  ? Math.min(...arrs.map(a => getMinFromArr(field, a, defaultValue)))
  : defaultValue;

const getMaxFromMultiArr = (field, arrs, defaultValue) => arrs?.length > 0
  ? Math.max(...arrs.map(a => getMaxFromArr(field, a, defaultValue)))
  : defaultValue;

const isEmptyResultMap = (resultMap) => Object.keys(resultMap).length === 0 || Object.keys(resultMap).every(r => resultMap[r].length === 0);

const getMultiArrFromResultMap = (resultMap) => Object.keys(resultMap).map(r => resultMap[r]);

const getLargestNegativeValue = (field, arrs) => {
  let largest = Number.NEGATIVE_INFINITY;
  arrs.forEach(a => a.filter(d => d[field] < 0).forEach(d => {
    largest = Math.max(largest, d[field]);
  }));
  return largest === Number.NEGATIVE_INFINITY ? 0 : largest;
};

const getLargestNegativeValueSingleArr = (arr) => {
  let largest = Number.NEGATIVE_INFINITY;
  arr.filter(d => d < 0).forEach(d => {
    largest = Math.max(largest, d);
  });
  return largest === Number.NEGATIVE_INFINITY ? 0 : largest;
};

const checkSameYear = (datapoint, currYear) => datapoint[DIMENSION_DATE].getFullYear() === currYear;

const checkSameMonth = (datapoint, currYear, currMonth) => datapoint[DIMENSION_DATE].getMonth() === currMonth && checkSameYear(datapoint, currYear);

const extractDataForDimension = (dimension, interval, arr, currYear, currMonth) => {
  if (interval === INTERVAL_TYPE_MONTH) return arr.filter(d => checkSameMonth(d, currYear, currMonth)).map(d => d[dimension]);
  if (interval === INTERVAL_TYPE_YEAR) return arr.filter(d => checkSameYear(d, currYear)).map(d => d[dimension]);
};

const aggregateDataForDimension = (aggType, arr, nonZeroOnly) => {
  let value = 0;
  if (aggType === AGG_TYPE_SUM) value = getSum(arr);
  if (aggType === AGG_TYPE_AVG) value = getMean(arr);
  if (aggType === AGG_TYPE_COUNT) value = getCount(arr);
  if (nonZeroOnly && value === 0) return null;
  return value;
};

const generateBarOrLineData = (dimension, interval, resultMap, currYear, currMonth) => {
  const agg = dimension.agg;
  const field = dimension.field;
  const nonZeroOnly = !!dimension.nonZeroOnly;
  const data = {};
  Object.keys(resultMap).forEach(r => {
    const extractedData = extractDataForDimension(field, interval, resultMap[r], currYear, currMonth);
    const aggData = aggregateDataForDimension(agg, extractedData, nonZeroOnly);
    data[r] = aggData;
  });
  return data;
};

const formatValueFromType = (valueType, value) => {
  if (valueType === VALUE_TYPE_COUNT) return round0(value);
  if (valueType === VALUE_TYPE_PERC) return round1(value);
  if (valueType === VALUE_TYPE_PRICE) return round2(value);
};

const generateMultiForInterval = (dimensions, interval, resultMap, presetMinDate = null, presetMaxDate = null) => {
  // if min/max date provided, use that instead, if not just assume is full range (all time)
  const now = new Date();
  const multiArr = getMultiArrFromResultMap(resultMap);
  let minDate = presetMinDate ? presetMinDate : getMinFromMultiArr(DIMENSION_DATE, multiArr, now);
  let maxDate = presetMaxDate ? presetMaxDate : getMaxFromMultiArr(DIMENSION_DATE, multiArr, now);

  if (typeof minDate === 'number') minDate = new Date(minDate);
  if (typeof maxDate === 'number') maxDate = new Date(maxDate);

  const labels = [];
  const bars = {};
  const lines = {};
  const propList = [];
  const colors = [];

  // initialize all properties
  Object.keys(resultMap).forEach(r => {
    bars[r] = [];
    lines[r] = [];
    propList.push(r);
  });

  // construct the time series
  const currDate = new Date(minDate.getTime());
  while (currDate.getFullYear() <= maxDate.getFullYear()) {
    const currYear = currDate.getFullYear();
    const currMonth = currDate.getMonth();

    // create label
    const monthLabel = MONTHS[currMonth];
    if (interval === INTERVAL_TYPE_MONTH) {
      labels.push(`${monthLabel} ${currYear}`);
    } else if (interval === INTERVAL_TYPE_YEAR) {
      labels.push(currYear);
    }

    // handle bars
    const barValueType = dimensions[MULTICHART_SUBTYPE_BARS].valueType;
    const currBar = generateBarOrLineData(dimensions[MULTICHART_SUBTYPE_BARS], interval, resultMap, currYear, currMonth);
    Object.keys(currBar).forEach(r => {
      bars[r].push(formatValueFromType(barValueType, currBar[r]));
    });

    // handle lines
    const lineValueType = dimensions[MULTICHART_SUBTYPE_LINES].valueType;
    const currLines = generateBarOrLineData(dimensions[MULTICHART_SUBTYPE_LINES], interval, resultMap, currYear, currMonth);
    Object.keys(currLines).forEach(r => {
      lines[r].push(formatValueFromType(lineValueType, currLines[r]));
    });

    // increment by interval
    if (interval === INTERVAL_TYPE_MONTH) {
      if (currDate.getFullYear() === maxDate.getFullYear() && currDate.getMonth() === maxDate.getMonth()) {
        break;
      }
      currDate.setMonth(currDate.getMonth() + 1);
    } else if (interval === INTERVAL_TYPE_YEAR) {
      currDate.setFullYear(currDate.getFullYear() + 1);
    }
  }

  // generate full data by properties
  const barsData = [];
  const linesData = [];
  propList.forEach((p, i) => {
    const chunks = p.split(',');
    const name = chunks[0];
    const address = chunks[1];
    const color = getColor(i);
    barsData.push({
      name,
      address,
      data: bars[p],
      color,
    });
    linesData.push({
      name,
      address,
      data: lines[p],
      color,
    });
    colors.push({
      name,
      address,
      color,
    });
  });

  return {
    labels,
    bars: barsData,
    lines: linesData,
    colors,
  };
};

/**
 * Generate multichart labels and data from map of results, given user selected priod type, interval and dimensions
 */
export const generateMultiPropMultiChart = (dimensions, period, interval, resultMap) => {
  if (isEmptyResultMap(resultMap)) {
    return {
      labels: [],
      bars: [],
      lines: [],
      colors: [],
    };
  }

  if (period === PERIOD_TYPE_ALL) {
    return generateMultiForInterval(dimensions, interval, resultMap);
  }

  if (period === PERIOD_TYPE_10Y) {
    const end = new Date();
    const start = new Date();
    start.setFullYear(end.getFullYear() - 10);
    return generateMultiForInterval(dimensions, interval, resultMap, start, end);
  }

  if (period === PERIOD_TYPE_12M) {
    const end = new Date();
    const start = new Date();
    start.setMonth(end.getMonth() - 12);
    return generateMultiForInterval(dimensions, interval, resultMap, start, end);
  }
};

const getMinIntervalForValueType = (valueType) => {
  if (valueType === VALUE_TYPE_COUNT) return 1;
  if (valueType === VALUE_TYPE_PERC) return 0.1;
  if (valueType === VALUE_TYPE_PRICE) return 0.01;
};

const createDistributionRange = (valueType, minX, maxX, maxNeg, maxBins = 10) => {
  // distribution range in x-axis takes the upper bound of the histogram bin
  const range = [];
  const diff = maxX - minX;
  const interval = Math.max(diff / maxBins, getMinIntervalForValueType(valueType));
  let negativeInterval = 0;
  let currX = minX;

  if (minX < 0) {
    const negDiff = maxNeg - minX;
    const negBins = Math.floor((negDiff / diff) * maxBins);
    if (negDiff === 0 || negBins === 0) {
      currX = 0;
      range.push(formatValueFromType(valueType, maxNeg));
    } else {
      negativeInterval = Math.max(negDiff / negBins, getMinIntervalForValueType(valueType));
    }
  }

  while (currX <= maxX) {
    const isNegative = currX < 0;
    currX += isNegative ? negativeInterval : interval;
    range.push(formatValueFromType(valueType, currX));
  }

  return range;
};

const generateDistributionData = (range, arr) => {
  const sortedArr = sortAsc(arr);
  const data = Array(range.length).fill(0);
  if (sortedArr.length === 0) return data;
  let curr = 0;
  for (let i = 0; i < range.length; i++) {
    while (curr < sortedArr.length && sortedArr[curr] <= range[i]) {
      data[i] += 1;
      curr += 1;
    }
  }
  return data.map(d => d === 0 ? null : d);
};

export const generateMultiPropDistributionChart = (dimension, valueType, resultMap, maxBins) => {
  if (isEmptyResultMap(resultMap)) {
    return {
      labels: [],
      data: [],
      colors: [],
    };
  }

  const multiArr = getMultiArrFromResultMap(resultMap);
  const minX = getMinFromMultiArr(dimension, multiArr, 0);
  const maxX = getMaxFromMultiArr(dimension, multiArr, 0);
  const maxNeg = getLargestNegativeValue(dimension, multiArr);
  const range = createDistributionRange(valueType, minX, maxX, maxNeg, maxBins);

  const colors = [];

  const data = Object.keys(resultMap).map((p, i) => {
    const chunks = p.split(',');
    const name = chunks[0];
    const address = chunks[1];
    const color = getColor(i);
    const arr = resultMap[p].map(v => v[dimension]);

    colors.push({
      name,
      address,
      color,
    });

    return {
      name,
      address,
      color,
      data: generateDistributionData(range, arr),
    };
  });

  return {
    labels: range,
    data,
    colors,
  };
};

export const setNullForZeroSeries = (data) => data.map(value => value === 0 ? null : value);

export const generatePropDistributionChart = (valueType, data) => {
  if (!data || data.length === 0) {
    return {
      labels: [],
      data: [],
    };
  }

  const minX = Math.min(...data);
  const maxX = Math.max(...data);
  const maxNeg = getLargestNegativeValueSingleArr(data);
  const range = createDistributionRange(valueType, minX, maxX, maxNeg);

  return {
    labels: range,
    data: generateDistributionData(range, data),
  };
};