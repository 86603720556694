import { deepCopy } from "@/utils/convert";
import PropTypes from "prop-types";
import { Tooltip } from 'react-tooltip';
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../common/Loader";
import { useEffect, useState } from "react";
import { createPostAPI, createReplyAPI, deletePostAPI, deleteReplyAPI, getPostsAPI, getRepliesAPI, votePostAPI, voteReplyAPI } from "@/utils/api";
import { generateDateString } from "@/utils/time";
import { getUserID } from "@/utils/user";

const MAX_MESSAGE_LENGTH = 300;

const CommentSection = ({ id, user }) => {
  const postLimit = 10;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showPostInput, setShowPostInput] = useState(false);
  const [showSentPost, setShowSentPost] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(null);
  const [showSentReply, setShowSentReply] = useState(null);
  const [showSentReplySucc, setShowSentReplySucc] = useState(false);
  const [postInput, setPostInput] = useState('');
  const [replyInput, setReplyInput] = useState('');
  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState({
    posts: [],
    next_page: false,
  });
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [isLoadingReplies, setIsLoadingReplies] = useState(null);
  const [isLoadingSendPost, setIsLoadingSendPost] = useState(false);
  const [isLoadingSendReply, setIsLoadingSendReply] = useState(false);
  const [lastPostTime, setLastPostTime] = useState(0);
  const uid = getUserID(user);

  const votePost = (idx, id, rating) => {
    // update backend
    votePostAPI(id, rating);  // TODO error
    
    // update locally
    const updatedResults = deepCopy(results);
    updatedResults['posts'][idx]['score'] += (
      rating === 0
        ? ((updatedResults['posts'][idx]['votes.score'] ?? 0) * -1)
        : rating
    );
    updatedResults['posts'][idx]['votes.score'] = rating;
    updatedResults['local_update'] = new Date().getTime();
    setResults(updatedResults);
  };

  const voteReply = (idx, rIdx, id, rating) => {
    // update backend
    voteReplyAPI(id, rating); // TODO error

    // update locally
    const updatedResults = deepCopy(results);
    updatedResults['posts'][idx]['replies'][rIdx]['score'] += (
      rating === 0
       ? ((updatedResults['posts'][idx]['replies'][rIdx]['votes.score'] ?? 0) * -1)
       : rating
    );
    updatedResults['posts'][idx]['replies'][rIdx]['votes.score'] = rating;
    updatedResults['local_update'] = new Date().getTime();
    setResults(updatedResults);
  };

  const createPost = () => {
    const now = new Date().getTime();
    const diff = now - lastPostTime;
    if (diff < 10000) return;

    if (!postInput) return;

    setIsLoadingSendPost(true);
    setLastPostTime(now);

    createPostAPI(id, postInput, () => {
      setIsLoadingSendPost(false);
      setShowSentPost('success');
      setShowPostInput(false);

      // update locally
      const updatedResults = deepCopy(results);
      if (!updatedResults['posts'])
        updatedResults['posts'] = [];
      const now = new Date().getTime();
      updatedResults['posts'].unshift({
        id: `${uid}-localmsg`,
        name: 'I',
        uid,
        created_at: now,
        content: postInput,
        score: 0,
      });
      updatedResults['local_update'] = now;
      setResults(updatedResults);
      setPostInput('');
    }, () => {
      setIsLoadingSendPost(false);
      setShowSentPost('failed');
    });
  };

  const createReply = (idx, postId) => {
    const now = new Date().getTime();
    const diff = now - lastPostTime;
    if (diff < 10000) return;

    if (!replyInput) return;

    setIsLoadingSendReply(postId);
    setLastPostTime(now);

    createReplyAPI(id, postId, replyInput, () => {
      setIsLoadingSendReply(null);
      setShowSentReply(postId);
      setShowSentReplySucc(true);
      setShowReplyInput(null);

      // update locally
      const updatedResults = deepCopy(results);
      if (!updatedResults['posts'][idx]['replies'])
        updatedResults['posts'][idx]['replies'] = [];
      const now = new Date().getTime();
      updatedResults['posts'][idx]['replies'].unshift({
        id: `${uid}-localmsg`,
        name: 'I',
        uid,
        created_at: now,
        content: replyInput,
        score: 0,
      });
      updatedResults['local_update'] = now;
      setResults(updatedResults);
      setReplyInput('');
    }, () => {
      setIsLoadingSendReply(null);
      setShowSentReply(postId);
      setShowSentReplySucc(false);
    });
  };

  const loadMorePosts = () => {
    setIsLoadingPosts(true);

    getPostsAPI(id, {
      limit: postLimit,
      offset,
    }, (data) => {
      setResults({
        posts: [
          ...results.posts,
          ...data.posts,
        ],
        next_page: data.next_page,
        ts: new Date().getTime(),
      });
      setIsLoadingPosts(false);
      setOffset(data.offset + postLimit);
    }, () => {
      setIsLoadingPosts(false);
    });
  };

  const loadMoreReplies = (idx, postId) => {
    setIsLoadingReplies(postId);

    getRepliesAPI(id, postId, {
      limit: postLimit,
      offset: results.posts[idx].offset,
    }, (data) => {
      setIsLoadingReplies(null);
      const updatedResults = deepCopy(results);
      updatedResults.posts[idx].replies.concat(data.replies);
      updatedResults.posts[idx].has_more_replies = data.next_page;
      updatedResults.posts[idx].offset = data.offset + postLimit;
      updatedResults.ts = new Date().getTime();
      setResults(updatedResults);
    }, () => {
      setIsLoadingReplies(null);
    });
  };

  const deletePost = (idx, postId) => {
    if (window.confirm('Delete your post?')) {
      // update backend
      deletePostAPI(postId); // TODO error

      // update locally
      const updatedResults = deepCopy(results);
      updatedResults['posts'].splice(idx, 1);
      updatedResults['local_update'] = new Date().getTime();
      setResults(updatedResults);
    }
  };

  const deleteReply = (idx, rIdx, replyId) => {
    if (window.confirm('Delete your reply?')) {
      // update backend
      deleteReplyAPI(replyId); // TODO error

      // update locally
      const updatedResults = deepCopy(results);
      updatedResults['posts'][idx]['replies'].splice(rIdx, 1);
      updatedResults['local_update'] = new Date().getTime();
      setResults(updatedResults);
    }
  };

  useEffect(() => {
    loadMorePosts();
  }, [id]);

  const now = new Date().getTime();

  const loginWithRedirect = () => {
    navigate(`/login?next=${encodeURIComponent(pathname)}`);
  };

  const onPostInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        createPost();
      }, 188);
    }
  };

  const onReplyInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        createReply();
      }, 188);
    }
  };

  return (
    <div className="modal-table">
      <div className="d-flex flex-row-reverse">
        {!showPostInput
          && <button
            className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-14 text-blue-1"
            onClick={
              uid
                ? () => setShowPostInput(true)
                : () => loginWithRedirect()
            }
          >
            {
              uid ? 'Send Message' : 'Login to Send Message'
            }
          </button>
        }
      </div>

      {showSentPost && !showPostInput
        && <div className={`alert alert-${showSentPost === 'success' ? 'success' : 'danger'} mt-10`} role="alert">
          {
            showSentPost === 'success'
              ? 'Your message has been posted successfully'
              : 'Your message failed to be posted'
          }
        </div>
      }

      {isLoadingSendPost && <Loader />}

      {showPostInput && !isLoadingSendPost
        && <div className="d-flex justify-content-end row">
          <div className="col-12">
            <div className="form-input">
              <textarea
                id="message"
                required rows="4"
                value={postInput}
                onChange={e => setPostInput(e.target.value.slice(0, MAX_MESSAGE_LENGTH))}
                onKeyDown={onPostInputKeyDown}
              >
              </textarea>
              <label htmlFor="message" className="lh-1 text-14 text-light-1">
                Your Message {postInput?.length > 0 ? `(${postInput.length}/${MAX_MESSAGE_LENGTH})` : ''}
              </label>
            </div>
          </div>
          <div className="col-auto">
            <button
              className="button px-24 h-40 -dark-1 bg-blue-1 text-white mt-10 mb-10"
              onClick={() => createPost()}
            >
              Send Message
            </button>
          </div>
        </div>
      }
      
      <div className="mt-20 discussion">
        {results?.posts?.length === 0 && !isLoadingPosts
          && <div className="text-center">
            <span>No message posted yet</span>
          </div>
        }

        {results?.posts?.length === 0 && isLoadingPosts
          && <Loader />
        }

        {results?.posts?.map((post, idx) => (
          <div
            className="py-10"
            key={post.id}
          >
            <div className="d-flex mr-10">
              <div className="p-2 w-100 text-dark-2 row">
                <div className="col-auto d-flex flex-column mb-10">
                  {post.uid !== uid
                    && <div
                      className={`p-2 icon-chevron-sm-down rotate-180 ${
                        post['votes.score'] === -1 ? 'text-light-2' : 'text-blue-1'
                      } text-10 cursor-pointer`}
                      data-tooltip-id={`${post.id}-disc-tooltip`}
                      data-tooltip-content={post['votes.score'] ? "Unvote" : "Upvote"}
                      data-tooltip-variant="dark"
                      onClick={() => votePost(idx, post.id, post['votes.score'] ? 0 : 1)}
                    />
                  }
                  {post.uid === uid
                    && <div
                      className="p-2 icon-chevron-sm-down rotate-180 text-light-2 text-10"
                    />
                  }

                  <span className="text-center text-13 fw-500">{post.score ?? 0}</span>

                  {post.uid !== uid
                    && <div
                      className={`p-2 icon-chevron-sm-down ${
                        post['votes.score'] === 1 ? 'text-light-2' : 'text-blue-1'
                      } text-10 cursor-pointer`}
                      data-tooltip-id={`${post.id}-disc-tooltip`}
                      data-tooltip-content={post['votes.score'] ? "Unvote" : "Downvote"}
                      data-tooltip-variant="dark"
                      onClick={() => votePost(idx, post.id, post['votes.score'] ? 0 : -1)}
                    />
                  }
                  {post.uid === uid
                    && <div
                      className="p-2 icon-chevron-sm-down text-light-2 text-10"
                    />
                  }

                  <Tooltip id={`${post.id}-disc-tooltip`} />
                </div>

                <div className="col-auto">
                  <p className="text-dark-2 fw-500">
                    <span className="text-16 text-blue-1">{post.uid !== uid ? post.name : 'I'}</span>
                    <span className="text-13"> posted {generateDateString(now, post.created_at)}:</span>
                  </p>
                  <p className="text-dark-2 text-15 pr-20">{post.content}</p>
                </div>

              </div>
              
              {uid && showReplyInput !== post.id
                && <div className="p-2 flex-shrink-1 position-relative">
                  <button
                    className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1 mt-10 position-absolute top-50 start-50 translate-middle"
                    onClick={() => setShowReplyInput(post.id)}
                  >
                    Reply
                  </button>
                  {post.uid === uid && post.id !== `${uid}-localmsg`
                    && <button
                      className="button h-20 text-12 text-blue-1"
                      data-tooltip-id={`${post.id}-disc-tooltip`}
                      data-tooltip-content="Delete my post"
                      data-tooltip-variant="dark"
                      onClick={() => deletePost(idx, post.id)}
                    >
                      <i className="icon-trash" />
                    </button>
                  }
                </div>
              }
            </div>

            {(showReplyInput === post.id || showSentReply === post.id)
              && <div className="d-flex justify-content-end ml-50 mr-10 row">
                {showSentReply === post.id && showReplyInput !== post.id
                  && <div className="col-12">
                    <div className={`alert alert-${showSentReplySucc ? 'success' : 'danger'}`} role="alert">
                      {
                        showSentReplySucc
                          ? 'Your reply has been posted successfully'
                          : 'Your reply failed to be posted'
                      }
                    </div>
                  </div>
                }
                {showReplyInput === post.id && isLoadingSendReply !== post.id
                  && <>
                    <div className="col-12">
                      <div className="form-input">
                        <textarea
                          id="message"
                          required rows="4"
                          value={replyInput}
                          onChange={e => setReplyInput(e.target.value.slice(0, MAX_MESSAGE_LENGTH))}
                          onKeyDown={onReplyInputKeyDown}
                        >
                        </textarea>
                        <label htmlFor="message" className="lh-1 text-16 text-light-1">
                          Your Message {replyInput?.length > 0 ? `(${replyInput.length}/${MAX_MESSAGE_LENGTH})` : ''}
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        className="button px-24 h-40 -dark-1 bg-blue-1 text-white mt-10 mb-10"
                        onClick={() => createReply(idx, post.id)}
                      >
                        Reply
                      </button>
                    </div>
                  </>
                }
              </div>
            }

            {isLoadingSendReply === post.id && <Loader />}

            {(post.replies?.length > 0 || post.has_more_replies)
              && <div className="ml-60">
                {post.replies.map((reply, rIdx) => (
                  <div className="d-flex mr-10" key={reply.id}>
                    <div className="p-2 w-100 text-dark-2 border-top-light pt-10 row">
                      <div className="col-auto d-flex flex-column mb-10">
                        {reply.uid !== uid
                          && <div
                            className={`p-2 icon-chevron-sm-down rotate-180 ${
                              reply['votes.score'] === -1 ? 'text-light-2' : 'text-blue-1'
                            } text-10 cursor-pointer`}
                            data-tooltip-id={`${reply.id}-disc-tooltip`}
                            data-tooltip-content={reply['votes.score'] ? "Unvote" : "Upvote"}
                            data-tooltip-variant="dark"
                            onClick={() => voteReply(idx, rIdx, reply.id, reply['votes.score'] ? 0 : 1)}
                          />
                        }
                        {reply.uid === uid
                          && <div
                            className="p-2 icon-chevron-sm-down rotate-180 text-light-2 text-10"
                          />
                        }
        
                        <span className="text-center text-13 fw-500">{reply.score ?? 0}</span>
        
                        {reply.uid !== uid
                            && <div
                            className={`p-2 icon-chevron-sm-down ${
                              reply['votes.score'] === 1 ? 'text-light-2' : 'text-blue-1'
                            } text-10 cursor-pointer`}
                            data-tooltip-id={`${reply.id}-disc-tooltip`}
                            data-tooltip-content={reply['votes.score'] ? "Unvote" : "Downvote"}
                            data-tooltip-variant="dark"
                            onClick={() => voteReply(idx, rIdx, reply.id, reply['votes.score'] ? 0 : -1)}
                          />
                        }
                        {reply.uid === uid
                            && <div
                            className="p-2 icon-chevron-sm-down text-light-2 text-10"
                          />
                        }
        
                        <Tooltip id={`${reply.id}-disc-tooltip`} />
                      </div>
        
                      <div className="col-auto">
                        <p className="text-dark-2 fw-500">
                          <span className="text-15 text-blue-1">{reply.uid !== uid ? reply.name : 'I'}</span>
                          <span className="text-12"> replied {generateDateString(now, reply.created_at)}:</span>
                        </p>
                        <p className="text-dark-2 text-14">{reply.content}</p>
                      </div>

                    </div>

                    {reply.uid === uid && reply.id !== `${uid}-localmsg`
                      && <div className="p-2 flex-shrink-1 mt-20 position-relative">
                        <button
                          className="button h-20 text-12 text-blue-1"
                          data-tooltip-id={`${reply.id}-disc-tooltip`}
                          data-tooltip-content="Delete my reply"
                          data-tooltip-variant="dark"
                          onClick={() => deleteReply(idx, rIdx, reply.id)}
                        >
                          <i className="icon-trash" />
                        </button>
                      </div>
                    }
                  </div>
                ))}

                {post.has_more_replies && !isLoadingReplies
                  && <div className="text-center">
                    <div
                      className="icon-chevron-sm-down text-blue-1 text-12 py-20 cursor-pointer"
                      data-tooltip-id="disc-load-tooltip"
                      data-tooltip-content="Load more replies"
                      data-tooltip-variant="dark"
                      onClick={() => loadMoreReplies(idx, post.id)}
                    />
                  </div>
                }
                {isLoadingReplies === post.id && <Loader />}
              </div>
            }

            <div className="border-top-light"></div>
          </div>
        ))}

        {results?.next_page
          && <div className="text-center py-10">
            {!isLoadingPosts
              && <div
                className="icon-chevron-sm-down text-blue-1 text-12 py-20 cursor-pointer"
                data-tooltip-id="disc-load-tooltip"
                data-tooltip-content="Load more comments"
                data-tooltip-variant="dark"
                onClick={() => loadMorePosts()}
              />
            }

            <Tooltip id="disc-load-tooltip" />

            {isLoadingPosts && <Loader />}
          </div>
        }
      </div>

    </div>
  );
};

CommentSection.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
};

CommentSection.defaultProps = {
  user: null,
};

export default CommentSection;
