import { NA } from "@/utils/convert";

const TransactionGroup = ({ item }) => {
  return (
    <div className="px-15 py-5 mt-10 proj-perf">
      <div className="d-flex items-center text-blue-1 text-12">
        <i className="icon-usd fw-600 mr-10"></i>
        <div className="fw-600">Transactions</div>
      </div>

      <div className="row x-gap-50 y-gap-0 text-12 pt-5">
        <div className="col-12">
          <div className="">
            Sold At Launch (updated monthly): <span className={item['perc_sold_at_launch'] ? 'text-green-2' : 'text-dark-1'}>{
              item['perc_sold_at_launch'] ? `${item['perc_sold_at_launch'].toFixed(0)}%` : NA
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            HDB Buyers: <span className="text-green-2">{
              (item['perc_HDB_buyer'] ?? 0).toFixed(0)
            }%</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Developer Unsold: <span className="text-dark-1">{
              item?.['Total Number of Unsold Units'] ?? 0
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Total Units: <span className="text-dark-1">{
              (!item?.['Total Number of Units in Project'] || item['Total Number of Units in Project'] < 0)
                ? NA : item['Total Number of Units in Project']
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Total Amount Transacted: <span className="text-dark-1">${
              `${item?.['total_amount_transacted_formatted']}`
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Last Transacted: <span className="text-dark-1">{
              item?.['last_transacted_date'] ?? NA
            }</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default TransactionGroup;
