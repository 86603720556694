import { Link } from "react-router-dom";

const ScrollDownButton = ({ onNextPage, dark, showHint }) => {
  return (
    <div className="lp-scroller-down-container d-flex justify-content-center bounce">
      {showHint && <div className="lp-scroller-hint py-5 px-40 text-blue-1 fw-500 text-12">
        learn more
      </div>}
      <Link
        onClick={onNextPage}
        className="lp-scroller-down py-10 px-40"
      >
        <i className={`icon-chevron-sm-down ${dark ? 'text-blue-1' : 'text-light'}`} />
      </Link>
    </div>
  );
};

export default ScrollDownButton;
