import { noSelectClass } from "@/utils/user";
import UnitsView from "@/components/project/UnitsView";

const UnitsTab = ({
  user,
  id,
  txResults,
  hasBlocks,
  isMaximized,
  mediaMatches
}) => {
  return (
    <>
      <section className={`modal-table${isMaximized ? '-full' : ''}`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <UnitsView
            id={id}
            data={txResults}
            user={user}
            hasBlocks={hasBlocks}
            isMaximized={isMaximized}
            mediaMatches={mediaMatches}
          />
        </div>
      </section>
    </>
  );
};

export default UnitsTab;
