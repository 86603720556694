import PropTypes from "prop-types";
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import { Sidebar } from "react-pro-sidebar";

import PlanList from "./profile/PlanList";
import { getUserDisplayName, isAdminTier } from "@/utils/user";

const MobileMenu = ({ user }) => {
  const navigate = useNavigate();

  const onLogout = () => {
    firebase.auth().signOut().then(() => navigate('/login'));
  };

  const onAdmin = () => {
    navigate('/admin');
  };

  return (
    <>
      <div className="pro-header d-flex align-items-center justify-between border-bottom-light">
        <h3>Welcome {getUserDisplayName(user)}</h3>

        <div
          className="fix-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="icon icon-close"></i>
        </div>
      </div>
      
      <Sidebar width="400" backgroundColor="#fff">
        <div className="text-18 fw-500 lh-15 ml-20 mt-20">My Subscription</div>
        <PlanList user={user} />
      </Sidebar>

      <div className="mobile-footer px-20 py-5 border-top-light"></div>

      <div className="pro-footer">
        {isAdminTier(user?.claims)
          && <button
            className="button fw-button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white mb-10"
            onClick={onAdmin}
          >
            Admin
          </button>
        }
        <button
          className="button fw-button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white"
          onClick={onLogout}
        >
          Logout
        </button>
      </div>
    </>
  );
};

MobileMenu.propTypes = {
  user: PropTypes.object,
};

export default MobileMenu;
