import { NA } from "@/utils/convert";

const PerformancePanel = ({
  data,
  returns,
  minimize
}) => {
  const profitable = data?.['realsmart_score'];
  const totalProfitable = data?.['num_profitable_transactions'] ?? data?.['num_profitable_transactions_1y'] ?? 0;
  const totalUnprofitable = data?.['num_unprofitable'] ?? data?.['num_unprofitable_1y'] ?? 0;
  const total = totalProfitable + totalUnprofitable;

  const generateRating = (idx) => {
    const base = idx;
    if (profitable >= base) return 2;
    return profitable >= (base - 0.5) ? 1 : 0;
  };

  const averageAnnualProfit = data?.['returns_1y'] ?? returns;
  const profitableRate = total === 0 ? NA : (totalProfitable / total * 100);

  return (
    <div className="px-5 py-5 mt-5 proj-perf" id="proj-details-perf-box">
      <div className="d-flex items-center text-blue-1 text-12 ml-5">
        <i className="icon-award mr-5"></i>
        <div className="fw-600">Past 1 Year Performance</div>
      </div>

      {!data && !minimize
        && <div className="text-12 ml-10 mt-5 fw-500 text-dark-1">
          Insufficient resale data in past year
        </div>
      }

      <div className="mt-10">
        <div className="d-flex flex-row">
          <div className="p-2 proj-perf-line py-0 pb-10">
            <div className="text-14 text-dark-1 fw-500 lh-12 d-flex align-items-center">
              REALSCORE
              <span className="ml-10 text-10 lh-10 items-center text-white proj-beta noselect rounded-100 fw-500">BETA</span>
            </div>
            <div className="text-10 text-dark-1 lh-12">Profitability rank across Singapore</div>
          </div>
          <div className="p-2 text-14 text-dark-1 py-0 px-0 fw-500">
            <div className="mt-5 d-flex align-items-center">
              <span className={`${minimize ? '' : 'mr-10'} text-dark-1`}>{data && profitable ? profitable?.toFixed(1) : NA}</span>
              {data && !minimize ? (
                <>
                  {[...Array(5).keys()].map(i => (
                    <img className="proj-perf-rate noselect" src={`/img/general/logo_outline_${generateRating(i + 1)}.png`} />
                  ))}
                </>
              ) : ''}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row">
          <div className="p-2 proj-perf-line">
            <div className="text-14 text-dark-1 fw-500 lh-12">
              Returns
            </div>
            <div className="text-10 text-dark-1 lh-12">Average annualized profit in past year</div>
          </div>
          <div className={`p-2 text-14 py-0 px-0 mt-10 ${
            (!data || averageAnnualProfit === null || isNaN(averageAnnualProfit)) ? 'text-dark-1' : (
              averageAnnualProfit > 0 ? 'text-green-2' : 'text-red-1'
            )
          } fw-500`}>{data ? `${(averageAnnualProfit === null || isNaN(averageAnnualProfit)) ? NA : `${averageAnnualProfit.toFixed(1)}%`}` : NA}</div>
        </div>

        <div className="d-flex flex-row">
          <div className="p-2 proj-perf-line">
            <div className="text-14 text-dark-1 fw-500 lh-12">
              Track Record
            </div>
            <div className="text-10 text-dark-1 lh-12">Profitable transactions in past year</div>
          </div>
          <div className="p-2 text-14 py-0 px-0 mt-10 text-dark-1 fw-500">{profitableRate === NA ? NA : `${profitableRate.toFixed(0)}%`}</div>
        </div>
      </div>
    </div>
  );
};

export default PerformancePanel;
