import PropTypes from "prop-types";

const Checkbox = (props) => {
  const { label, value, setValue } = props;

  const onCheckToggle = (e) => {
    const { checked } = e.target;
    setValue(checked);
  };

  return (
    <div className="col-auto col-12">
      <div className="form-checkbox d-flex items-center mt-10">
        <input
          type="checkbox"
          checked={!!value}
          onChange={e => onCheckToggle(e)}
        />
        <div className="form-checkbox__mark">
          <div className="form-checkbox__icon icon-check" />
        </div>
        <div className="text-15 ml-10">{label}</div>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Checkbox;
