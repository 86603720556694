import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
// import RangeSlider from "../common/sidebar/RangeSlider";
import { useState } from "react";

// const PRICE_MAGNIFIER = 100000;

const HeatmapSelector = ({
  pri, setPri,
  sec, setSec,
  mode, setMode,
  // ageFilter, setAgeFilter, minAge, maxAge,
  // priceFilter, minPrice, maxPrice, setPrice,
}) => {
  // const [uiPrice, setUIPrice] = useState(priceFilter);
  // const [uiAge, setUiAge] = useState(ageFilter);
  const [showFilters, setShowFilters] = useState(window.innerWidth > 960);

  const modeOptions = {
    heatmap: 'Heatmap',
    upcoming: 'New / Upcoming',
  };

  const modeTooltip = {
    heatmap: 'Property heatmap of Singapore where you can discover all hot properties at a glance',
    upcoming: 'Birdeye view of upcoming and new property launches in Singapore'
  };

  const priOptions = {
    profit: 'Profitable %',
    fairPrice: 'Fair PSF Price',
    // psf: 'PSF Price ($psf)',
  };

  const priTooltip = {
    profit: 'High profitability appears green, least will be red<br/>Those without profitability data will appear grey',
    fairPrice: 'Underpriced properties appear green, overpriced will be red<br/>Those without fair price data will appear grey',
  };

  const secOptions = {
    transactions: 'Total Transactions'
  };

  const secTooltip = {
    transactions: 'High volume have larger circle'
  };

  // const isAgeTypeSelected = (ageType) => ageFilter[ageType] !== false;

  // const applyPriceFilter = () => {
  //   setPrice(uiPrice);
  // };

  // const resetPriceFilter = () => {
  //   const newPrice = [minPrice, maxPrice];
  //   setUIPrice(newPrice);
  //   setPrice(newPrice);
  // };

  // const applyAgeFilter = () => {
  //   setAgeFilter(uiAge);
  // };

  // const resetAgeFilter = () => {
  //   const newAge = [minAge, maxAge];
  //   setUiAge(newAge);
  //   setAgeFilter(newAge);
  // };

  return (
    <>
      {mode === 'heatmap'
        && <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-20 py-10"
            data-bs-toggle="offcanvas"
            data-bs-target="#listingPropSidebar"
          >
            <span className="js-dropdown-title">
              <i className="icon icon-search text-16" />
              <span className="px-10 text-15 fw-600">SEARCH</span>
            </span>
          </div>
        </div>
      }

      <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
        <div
          className="dropdown__button d-flex items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
          // data-tooltip-id="heatmap-selection-tooltip"
          // data-tooltip-content={modeTooltip[mode]}
          // data-tooltip-variant="dark"
          // data-tooltip-place="left"
        >
          <span className="js-dropdown-title">
            <span className="text-blue-1 fw-600">{modeOptions[mode].toUpperCase()}</span>
          </span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown  dropdown-menu">
          <div className="text-13 y-gap-15 js-dropdown-list">
            {Object.keys(modeOptions).map((option, index) => (
              <div key={`mode-${index}`}>
                <button
                  className={`d-block js-dropdown-link ${
                    mode === option ? "text-blue-1 " : ""
                  }`}
                  data-tooltip-id="heatmap-selection-tooltip"
                  data-tooltip-content={modeTooltip[option]}
                  data-tooltip-variant="dark"
                  data-tooltip-place="left"
                  onClick={() => {
                    setMode(option);
                    if (document.querySelector(".js-dropdown-mode"))
                      document.querySelector(".js-dropdown-mode").textContent = modeOptions[option];
                  }}
                >
                  {modeOptions[option]}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {mode === 'heatmap'
        && <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector filter-mobile-btn">
          <div
            className={`button -sm -dark-1 ${showFilters ? 'bg-blue-1 text-white' : 'bg-white text-blue-1'} border-light rounded-100 text-12 px-10 py-10 ml-10`}
            onClick={() => setShowFilters(!showFilters)}
          >
            <span className="js-dropdown-title">
              <i className="icon icon-menu-2 text-12" />
            </span>
          </div>
        </div>
      }

      {showFilters && mode === 'heatmap'
        && <>
        
        {/* <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="heatmap-selection-tooltip"
            data-tooltip-content="Filter by property age"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-title">
              <span className="js-dropdown-age">Filter by Age</span>
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown dropdown-menu">
            <div className="text-13 y-gap-15 js-dropdown-list">
              <div className="heatmap-s-price" onClick={e => e.stopPropagation()}>
                <RangeSlider
                  value={{
                    value: {
                      min: uiAge[0],
                      max: uiAge[1],
                    }
                  }}
                  setValue={v => setUiAge([v.value.min, v.value.max])}
                  minValue={0}
                  maxValue={100}
                  step={5}
                  unit="year"
                  pluralize
                  minify
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                    onClick={() => resetAgeFilter()}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                    onClick={() => applyAgeFilter()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="heatmap-selection-tooltip"
            data-tooltip-content="Filter by past transaction price"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-title">
              <span className="js-dropdown-price">Filter by Price</span>
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown  dropdown-menu">
            <div className="text-13 y-gap-15 js-dropdown-list">
              <div className="heatmap-s-price" onClick={e => e.stopPropagation()}>
                <RangeSlider
                  value={{
                    value: {
                      min: uiPrice[0] * PRICE_MAGNIFIER,
                      max: uiPrice[1] * PRICE_MAGNIFIER,
                    }
                  }}
                  setValue={v => setUIPrice([
                    Math.floor(v.value.min / PRICE_MAGNIFIER),
                    Math.floor(v.value.max / PRICE_MAGNIFIER)
                  ])}
                  minValue={minPrice * PRICE_MAGNIFIER}
                  maxValue={maxPrice * PRICE_MAGNIFIER}
                  dollarize
                  minify
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                    onClick={() => resetPriceFilter()}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                    onClick={() => applyPriceFilter()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="dropdown__button d-flex items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="heatmap-selection-tooltip"
            data-tooltip-content={secTooltip[sec]}
            data-tooltip-variant="dark"
            data-tooltip-place="left"
          >
            <span className="js-dropdown-title">
              <span className="text-blue-1 fw-600">RADIUS</span>&nbsp;&nbsp; 
              <span className="js-dropdown-sec">{secOptions[sec]}</span>
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown  dropdown-menu">
            <div className="text-13 y-gap-15 js-dropdown-list">
              {Object.keys(secOptions).map((option, index) => (
                <div key={`sec-${index}`}>
                  <button
                    className={`d-block js-dropdown-link ${
                      sec === option ? "text-blue-1 " : ""
                    }`}
                    data-tooltip-id="heatmap-selection-tooltip"
                    data-tooltip-content={secTooltip[option]}
                    data-tooltip-variant="dark"
                    data-tooltip-place="left"
                    onClick={() => {
                      setSec(option);
                      if (document.querySelector(".js-dropdown-sec"))
                        document.querySelector(".js-dropdown-sec").textContent = secOptions[option];
                    }}
                  >
                    {secOptions[option]}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector ml-10">
          <div
            className="dropdown__button d-flex flex-column items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="heatmap-selection-tooltip"
            data-tooltip-html={priTooltip[pri]}
            data-tooltip-variant="dark"
            data-tooltip-place="left"
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <span className="js-dropdown-title text-14">
                <span className="text-blue-1 fw-600">COLOR</span>&nbsp;&nbsp; 
                <span className="js-dropdown-pri">{priOptions[pri]}</span>
              </span>
              <i className="icon icon-chevron-sm-down text-7 ml-10" />
            </div>
            <div className="dropdown-extra-content d-flex d-row">
              <div className="map-legend-mini map-legend-grad p-2">
                <span className="low">LOW</span>
                <span className="high">HIGH</span>
              </div>
              <div className="map-legend-mini map-legend-none p-2 ml-5">NA</div>
            </div>
          </div>
          <div className="toggle-element -dropdown  dropdown-menu">
            <div className="text-13 y-gap-15 js-dropdown-list">
              {Object.keys(priOptions).map((option, index) => (
                <div key={`pri-${index}`}>
                  <button
                    className={`d-block js-dropdown-link ${
                      pri === option ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setPri(option);
                      if (document.querySelector(".js-dropdown-pri"))
                        document.querySelector(".js-dropdown-pri").textContent = priOptions[option];
                    }}
                    data-tooltip-id="heatmap-selection-tooltip"
                    data-tooltip-html={priTooltip[option]}
                    data-tooltip-variant="dark"
                    data-tooltip-place="left"
                  >
                    {priOptions[option]}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

      </>}
      
      <Tooltip id="heatmap-selection-tooltip" style={{ zIndex: 999 }} />
    </>
  );
};

HeatmapSelector.propTypes = {
  pri: PropTypes.string.isRequired,
  setPri: PropTypes.func.isRequired,
  sec: PropTypes.string.isRequired,
  setSec: PropTypes.func.isRequired,
  priceFilter: PropTypes.array.isRequired,
};

export default HeatmapSelector;
