import PropTypes from "prop-types";

import DefaultHeader from "@/components/header/default-header";
import DefaultFooter from "@/components/footer/default";
import NotFound from "@/components/common/NotFound";

import MetaComponent from "@/components/common/MetaComponent";

const metadata = {
  title: "Page Not Found | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const NotFoundPage = ({ user, session }) => {
  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      <NotFound />

      <DefaultFooter />
    </>
  );
};

NotFoundPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

NotFoundPage.defaultProps = {
  user: null,
};

export default NotFoundPage;
