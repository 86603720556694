import PropTypes from "prop-types";
import { useState } from "react";
import UpcomingTable from "./UpcomingTable";

const TableView = ({
  properties,
}) => {
  const [click, setClick] = useState(false);
  const handleCarding = () => setClick((prevState) => !prevState);

  return (
    <>
      <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 map-mode-selector">
        <div
          className="button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-14 px-15 py-10 ml-10"
          onClick={handleCarding}
        >
          <span className="js-dropdown-title">
            <i className="icon icon-menu text-12" />
          </span>
        </div>
      </div>

      {click
        && <div className={`langMenu js-langMenu`}>
          <div className="modal-fullscreen bg-white">
            <div className="modal-content">
              <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
                <p className="text-dark-1 fw-500">
                  <i className="icon-location-pin" />
                  &nbsp;&nbsp;&nbsp;
                  New & Upcoming Private Properties
                </p>
                <button className="pointer" onClick={handleCarding}>
                  <i className="icon-close" />
                </button>
              </div>
              <div className="modal-body py-20">
                <UpcomingTable
                  data={properties}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

TableView.propTypes = {
  properties: PropTypes.array.isRequired,
};

export default TableView;
