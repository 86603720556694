import { useState } from 'react';
import PropTypes from "prop-types";

import CustomTable from '../transactions/CustomTable';
import { convertDisplayDate, dateStrToMsec } from '@/utils/time';
import { NA, convertFloorAreaRange } from '@/utils/convert';

const tableConfig = {
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const DEFAULT_COLUMNS = [
  {
    Header: 'Lease Commence',
    accessor: 'commence_date',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
  },
  {
    Header: 'Address',
    accessor: 'Street Name',
    disableGlobalFilter: true,
    disableSortBy: true,
    Cell: (row) => <a
      className="text-blue-1"
      href={`https://maps.google.com/?q=${row.cell.value}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {row.cell.value}
    </a>
  },
  {
    Header: 'Property Type',
    accessor: 'Property Type',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'No of Bedroom',
    accessor: 'No of Bedroom',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{(!row.cell.value || row.cell.value === -1) ? NA : row.cell.value.toFixed(0) }</div>
  },
  {
    Header: 'Monthly Rent ($)',
    accessor: 'Monthly Rent ($)',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
  },
  {
    Header: 'Floor Area (sft)',
    accessor: 'Floor Area (SQFT)',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>{convertFloorAreaRange(row.cell.value).toLocaleString()}</div>
  },
  {
    Header: 'Rental ($psf)',
    accessor: 'est_rental_psf_sgd',
    disableGlobalFilter: true,
    width: 80,
    Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
  }
];

const RentalTable = ({ data }) => {
  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={
        data.map(row => ({
          ...row,
          'commence_date': dateStrToMsec(row['Lease Commencement Date']),
        })).sort((a, b) => b.commence_date - a.commence_date)
      }
      tableConfig={tableConfig}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
    />
  );
};

RentalTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RentalTable;
