import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const getOptions = (xLabel, yLabel) => ({
  responsive: true,
  maintainAspectRatio: false,
  
  scales: {      
    x: {
      type: 'linear',
      position: 'bottom',
      ticks: {
        callback: function (value) {
          const date = new Date(value);
          const options = { year: 'numeric', month: 'short', day: 'numeric' };
          return date.toLocaleDateString(undefined, options);
        },
      },
      title: xLabel ? {
        display: true,
        text: xLabel,
        font: {
          size: 10,
        },
        padding: {
          top: 10,
        },
      }: {},
    },
    y: {
      title: yLabel ? {
        display: true,
        text: yLabel,
        font: {
          size: 10,
        },
        padding: {
          right: 10,
        },
      } : {},
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },

    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "#1967d2",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 4,
    },
  },
});

const generateChartData = (label, data, colors) => ({
  datasets: [
    {
      label,
      data,
      backgroundColor: colors ?? 'rgba(53,84,209,1)',
    },
  ],
});

const MainChart = ({
  label,
  data,
  colors,
  tooltipTitle,
  tooltipLabelCallback,
  xAxisLabel,
  yAxisLabel,
  height
}) => {
  const chartData = generateChartData(label, data, colors);

  const options = getOptions(xAxisLabel, yAxisLabel);

  const chartOption = {
    ...options,
    plugins: {
      ...options.plugins,
      tooltip: {
        callbacks: {
          title: () => tooltipTitle,
          label: (context) => tooltipLabelCallback(context),
        }
      },
    },
  };

  return (
    <div className="widget-content">
      <Scatter
        options={chartOption}
        data={chartData}
        height={height}
      />
    </div>
  );
};

MainChart.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  tooltipLabelCallback: PropTypes.func.isRequired,
  colors: PropTypes.array,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

MainChart.defaultProps = {
  colors: null,
};

export default MainChart;
