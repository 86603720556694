import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import PropTypes from "prop-types";
import { setNullForZeroSeries } from "@/utils/chart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
);

const MultiChart = ({
  data,
  yLeftLabel,
  yRightLabel,
  tooltipTitleCallback,
  tooltipLabelCallback,
  height
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  
    scales: {
      'y-left': {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
        },
        title: {
          display: true,
          text: yLeftLabel,
        },
      },
      'y-right': {
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true,
        },
        title: {
          display: true,
          text: yRightLabel,
        },
      },
    },
  
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
  
      tooltips: {
        position: "nearest",
        mode: "index",
        intersect: false,
        yPadding: 10,
        xPadding: 10,
        caretSize: 4,
        backgroundColor: "#1967d2",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 4,
      },
    },
  };

  const chartData = {
    labels: data.labels,
    datasets: [
      ...data.lines.map(lineData => ({
        type: 'line',
        yAxisID: 'y-right',
        label: lineData.name,
        data: setNullForZeroSeries(lineData.data),
        backgroundColor: lineData.color,
        borderColor: lineData.color,
        borderWidth: 2,
      })),
      ...data.bars.map(barData => ({
        type: 'bar',
        yAxisID: 'y-left',
        label: barData.name,
        data: setNullForZeroSeries(barData.data),
        backgroundColor: barData.color,
      }))
    ],
  };

  return (
    <div className="widget-content">
      <Chart
        type="bar"
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            tooltip: {
              callbacks: {
                title: (context) => tooltipTitleCallback(context),
                label: (context) => tooltipLabelCallback(context),
              }
            },
          },
        }}
        data={chartData}
        height={height}
      />
    </div>
  );
};

MultiChart.propTypes = {
  data: PropTypes.object.isRequired,
  tooltipTitleCallback: PropTypes.func.isRequired,
  tooltipLabelCallback: PropTypes.func.isRequired,
  yLeftLabel: PropTypes.string.isRequired,
  yRightLabel: PropTypes.string.isRequired,
};

export default MultiChart;
