import PropTypes from "prop-types";
import CustomTable from '../transactions/CustomTable';
import { useEffect, useState } from "react";

const tableConfig = {
  placeholder: 'Search project...',
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const RentalTable = ({
  results,
  pageLimit, setPageLimit,
  customSort, onCustomSort,
  defaultColumns, fullColumns, setFullColumns, columns, setColumns,
  groupOptions, selectedGroupOption, setSelectedGroupOption, originalColumns,
  showHistory,
}) => {
  const defaultStyle = { height: `calc(100svh - ${showHistory ? 328 : 258}px)`, minHeight: '360px' };
  const [style, setStyle] = useState(defaultStyle);

  useEffect(() => {
    const updateStyle = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      if (isLandscape && window.innerHeight < 600) {
        setStyle({ height: '100%' });
      } else {
        setStyle(defaultStyle);
      }
    };

    updateStyle();

    window.addEventListener('resize', updateStyle);

    return () => window.removeEventListener('resize', updateStyle);
  }, [showHistory]);

  return (
    <CustomTable
      name="rental"
      data={results}
      tableConfig={tableConfig}
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
      customSort={customSort}
      onCustomSort={onCustomSort}
      defaultColumns={defaultColumns}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      style={style}
      groupOptions={groupOptions}
      selectedGroupOption={selectedGroupOption}
      setSelectedGroupOption={setSelectedGroupOption}
      originalColumns={originalColumns}
      hidePage
      showFilterButton
    />
  );
};

RentalTable.propTypes = {
  results: PropTypes.array,
  filterAreas: PropTypes.object,
  setFilterAreas: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  loadTransactions: PropTypes.func.isRequired,
  scrollToTx: PropTypes.func.isRequired,
  pageLimit: PropTypes.number,
  setPageLimit: PropTypes.func,
  customSort: PropTypes.object,
  onCustomSort: PropTypes.func,
  defaultColumns: PropTypes.array,
  fullColumns: PropTypes.array,
  setFullColumns: PropTypes.func,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  groupOptions: PropTypes.array,
  selectedGroupOption: PropTypes.object,
  setSelectedGroupOption: PropTypes.func,
  showHistory: PropTypes.bool,
};

RentalTable.defaultProps = {
  groupOptions: null,
  selectedGroupOption: null,
  setSelectedGroupOption: null,
  showHistory: false,
};

export default RentalTable;
