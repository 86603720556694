import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Loader from "@/components/common/Loader";
import StyledFirebaseAuth from "@/pages/others/login/StyledFirebaseAuth";
import ScrollDownButton from "./ScrollDownButton";
import { logLogin } from "@/utils/api";
import { FIREBASE_UI_AUTH_CONFIG } from "@/utils/user";


const LoginPage = ({ onNextPage, session }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [autoLogin, setAutoLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const paramErr = params.get('err');
  const next = params.get('next');

  const redirectHome = () => {
    setTimeout(() => {
      navigate('/map');
    }, 300);
  };

  const redirectNext = () => {
    setTimeout(() => {
      navigate(next);
    }, 300);
  };

  const redirect = () => {
    if (next) {
      redirectNext();
    } else {
      redirectHome();
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth()
          .setPersistence(autoLogin
              ? firebase.auth.Auth.Persistence.LOCAL
              : firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            setLoading(true);
            logLogin(session, user);
          })
          .catch((err) => {
            setErr(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  if (err === null && paramErr !== err) {
    setErr(paramErr);
  }

  return (
    <>
      <div className="px-20 py-20 sm:px-10 sm:py-10">
        {err
          && <div className="alert alert-danger" role="alert">
            {err}
          </div>
        }

        {!loading
          && <div className="row y-gap-10">
            <div className="row ml-0 mr-0">
              <div className="col-12">
                <div className="text-center">
                  <div className="landing-mini-logo d-flex justify-content-center">
                    <h1 className="mb-20 ml-10 mr-10">REALSMART.SG</h1>
                    <img className="" src="/img/general/logo-rs-light.png" />
                    <span className="text-10 fw-700 landing-beta">BETA</span>
                  </div>
                  <h2 className="text-16 fw-500">Free forever.<br />Get real estate data and insights on REALSMART.</h2>
                  <img className="mt-20 mb-20" height={78} width={319} src="/img/general/skyline.png" />
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-center text-14">
                    To secure your session, please login with:
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-10 mb-10">
                    <StyledFirebaseAuth uiConfig={FIREBASE_UI_AUTH_CONFIG} firebaseAuth={firebase.auth()} />
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div className="form-checkbox mt-5">
                        <input
                          type="checkbox"
                          name="name"
                          checked={autoLogin}
                          onChange={e => setAutoLogin(e.target.checked)}
                        />
                        <div className="form-checkbox__mark">
                          <div className="form-checkbox__icon icon-check" />
                        </div>
                      </div>
                      <div className="text-15 lh-15 text-light-1 ml-10">
                        Log me in automatically
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        }

        {loading
          && <Loader />
        }
        
          <ScrollDownButton onNextPage={onNextPage} showHint dark />
      </div>
    </>
  );
};

export default LoginPage;
