import { noSelectClass } from "@/utils/user";
import CommentSection from "@/components/project/CommentSection";

const DiscussionTab = ({
  user,
  id,
}) => {
  return (
    <>
      <section className="pt-20">
        <div className={` ${noSelectClass(user)}`}>
          <CommentSection id={id} user={user} />
        </div>
      </section>
    </>
  );
};

export default DiscussionTab;
