import { useState } from "react";
import PropTypes from "prop-types";
import { REGIONS } from "@/utils/areas";

const PlanningAreaFilter = (props) => {
  const [expandIndex, setExpandIndex] = useState(null);
  const { selection, setSelection } = props;

  const onSelectArea = (e, region, area) => {
    const { checked } = e.target;
    setSelection({ ...selection, [area]: checked });
  };

  return (
    <>
      <div className="mb-10">
        <p className="text-12">Click to expand for planning areas</p>
      </div>
      {REGIONS.map((region, index) => (
        <div key={index}>
          <div
            className="row y-gap-10 items-center justify-between area-subtitle cursor-pointer"
            onClick={() => setExpandIndex(expandIndex === index ? null : index)}
          >
            <div className="col-auto">
              <div className="form-checkbox d-flex items-center">
                <i className={`icon-chevron-sm-down text-blue-1 text-10 ${expandIndex === index ? 'rotate-180' : ''}`} />
                <div className="text-15 ml-10">{region.label}</div>
              </div>
            </div>
            <div className="col-auto">
              <div className="text-15 text-light-1">{region.areas.length}</div>
            </div>
          </div>
          {(selection?.[region.label] || expandIndex === index)
            && region.areas.map((area, index) => (
              <div className="row y-gap-10 items-center justify-between ml-20" key={index}>
                <div className="col-auto">
                  <div className="form-checkbox d-flex items-center">
                    <input
                      type="checkbox"
                      name={"area-" + area}
                      checked={selection?.[area]}
                      onChange={e => onSelectArea(e, region, area)}
                    />
                    <div className="form-checkbox__mark">
                      <div className="form-checkbox__icon icon-check" />
                    </div>
                    <div className="text-15 ml-10">{area}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      ))}
    </>
  );
};

PlanningAreaFilter.propTypes = {
  selection: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default PlanningAreaFilter;
