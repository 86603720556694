import { CHART_GREEN_BG, CHART_ORANGE_BG, CHART_RED_BG } from "@/utils/colors";
import { dateStrToDateObj, dateStrToMsec, formatShortDate } from "@/utils/time";
import { noSelectClass } from "@/utils/user";
import RentalTable from "@/components/project/RentalTable";
import { VALUE_TYPE_PRICE, generatePropDistributionChart } from "@/utils/chart";
import MainChart from "@/components/project/MainChart";
import DistributionChart from "@/components/project/DistributionChart";
import { useState } from "react";
import {
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FILTER_LEASE_DATE,
  FILTER_RENT_PRICE,
  FILTER_RENT_PSF,
  FILTER_SIZE,
  hasFilterSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import Sidebar from "../common/Sidebar";

const AREA_COLORS = [
  {
    label: 'small',
    color: CHART_GREEN_BG,
  },
  {
    label: 'mid',
    color: CHART_ORANGE_BG,
  },
  {
    label: 'large',
    color: CHART_RED_BG,
  },
];

const FILTER_OPTIONS = [
  FILTER_LEASE_DATE,
  FILTER_SIZE,
  FILTER_RENT_PRICE,
  FILTER_RENT_PSF
];

const RentalTab = ({
  user,
  rentalResults,
  screenDim,
  isMaximized
}) => {
  const params = new URLSearchParams();
  const [filterSelected, setFilterSelected] = useState(initializeFilters(FILTER_OPTIONS, params));
  const [filteredResults, setFilteredResults] = useState(rentalResults);

  const [chartAreaSelect, setChartAreaSelect] = useState([]);
  const [psfChartData, setPsfChartData] = useState(rentalResults);

  const getFloorArea = (item) => {
    if (!item['Floor Area (SQFT)']) return null;
    const range = item['Floor Area (SQFT)'].split('-');
    if (range.length !== 2) return null;
    const minArea = parseInt(range[1]);
    return isNaN(minArea) ? null : minArea;
  };
  
  const getAreaType = (area) => {
    if (area > midAreaMax) return 'large';
    if (area > lowAreaMax) return 'mid';
    return 'small';
  };

  const getAreaColor = (area) => {
    if (!hasAreaRange) return CHART_GREEN_BG;
    const areaType = getAreaType(area);
    if (areaType === 'large') return CHART_RED_BG;
    if (areaType === 'mid') return CHART_ORANGE_BG;
    return CHART_GREEN_BG;
  };

  const getAreaRangeDesc = (areaType) => {
    if (areaType === 'large') return `${midAreaMax} - ${maxArea}`;
    if (areaType === 'mid') return `${lowAreaMax} - ${midAreaMax}`;
    return `${minArea} - ${lowAreaMax}`;
  };

  const maxArea = Math.max(...(rentalResults ?? []).map(r => getFloorArea(r)), 0);
  let minArea = Math.min(...(rentalResults ?? []).map(r => getFloorArea(r)), Number.POSITIVE_INFINITY);
  minArea = minArea === Number.POSITIVE_INFINITY ? 0 : minArea;
  const diffArea = maxArea - minArea;
  const hasAreaRange = diffArea >= 300;
  const areaInterval = Math.floor(diffArea / 300) * 100;
  const lowAreaMax = minArea + areaInterval;
  const midAreaMax = minArea + (areaInterval * 2);

  const chartHeight = screenDim.height < 800 ? null : 250;

  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  const filterData = (data) => {
    let filteredData = data;

    // filter lease date
    if (hasFilterSelected(FILTER_LEASE_DATE, filterSelected)) {
      filteredData = filteredData.filter(row => {
        const startDate = filterSelected[FILTER_LEASE_DATE.field[0]];
        const endDate = filterSelected[FILTER_LEASE_DATE.field[1]];
        const leaseDate = dateStrToMsec(row['Lease Commencement Date']);
        if (startDate !== null && leaseDate < startDate.getTime()) {
          return false;
        }
        if (endDate !== null && leaseDate > endDate.getTime()) {
          return false;
        }
        return true;
      });
    }

    // filter size
    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      const minSize = hasMin ? filterSelected[FIELD_MIN_SIZE] : null;
      const maxSize = hasMax ? filterSelected[FIELD_MAX_SIZE] : null;
      filteredData = filteredData.filter(row => {
        const sizeRange = row['Floor Area (SQFT)'].split('-');
        const sizeMin = parseInt(sizeRange[0]);
        const sizeMax = sizeRange.length > 1 ? parseInt(sizeRange[1]) : sizeMin;
        if (maxSize !== null && sizeMax > maxSize) return false;
        if (minSize !== null && sizeMin < minSize) return false;
        return true;
      });
    }

    if (hasFilterSelected(FILTER_RENT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row['Monthly Rent ($)'];
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    // filter unit price
    if (hasFilterSelected(FILTER_RENT_PSF, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_UNIT_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_UNIT_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row['est_rental_psf_sgd'];
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    return filteredData;
  };

  const onApplyFilter = () => {
    setFilteredResults(filterData(rentalResults));

    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('rt-close-button').click();
    }
  };

  const onResetFilter = () => {
    setFilterSelected(initializeFilters(FILTER_OPTIONS, params));
  };

  return (
    <>
      <section className="pt-10 pb-20">
        <div className={`${noSelectClass(user)}`}>
          <div className="row">
            <div className="col-12">
              <h3 className="text-18 fw-500">Rental Statistics</h3>
            </div>
          </div>
          <div className="row y-gap-20 chart_responsive mt-10">

            {rentalResults?.length > 0
              && <div className={`col-${chartColGrid}`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">Rental PSF Price ($psf)</h2>
                    {/* <ChartSelect /> */}
                  </div>

                  <div className="pt-10">
                    <MainChart
                      label="Rental Price ($psf)"
                      data={
                        psfChartData.map(row => ({
                          x: dateStrToDateObj(row['Lease Commencement Date']).getTime(),
                          y: row['est_rental_psf_sgd'],
                        }))
                      }
                      colors={
                        maxArea ? psfChartData.map(row => getAreaColor(getFloorArea(row))) : null
                      }
                      tooltipTitle="Rental Price ($psf)"
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          const labels = [
                            `${formattedDate}: $${context.raw.y.toFixed(2)}`
                          ];
                          const area = getFloorArea(psfChartData[context.dataIndex]);
                          const areaRange = psfChartData[context.dataIndex]['Floor Area (SQFT)'];
                          if (hasAreaRange) labels.push(`Floor Area: ${areaRange} sft (${getAreaType(area)})`);
                          return labels;
                        }
                      }
                      yAxisLabel="Rental Price ($psf)"
                      height={chartHeight}
                    />
                    {hasAreaRange
                      && <div className="mt-15 text-center">
                        {AREA_COLORS.map(p => (
                          <span
                            key={p}
                            className="c-tag text-10 px-10 ml-5 mr-5 cursor-pointer"
                            style={{
                              backgroundColor: p.color,
                              opacity: chartAreaSelect.length === 0 || chartAreaSelect.indexOf(p.label) >= 0 ? 1 : 0.3
                            }}
                            onClick={() => {
                              let newAreaSelect = [];
                              if (chartAreaSelect.indexOf(p.label) >= 0) {
                                newAreaSelect = chartAreaSelect.filter(s => s !== p.label)
                              } else {
                                newAreaSelect = [...chartAreaSelect, p.label];
                              }
                              setChartAreaSelect(newAreaSelect);
                              setPsfChartData(rentalResults?.filter(row => {
                                const type = getAreaType(getFloorArea(row));
                                return newAreaSelect.length === 0 || newAreaSelect.indexOf(type) >= 0;
                              }));
                            }}
                          >
                            {p.label.charAt(0).toUpperCase() + p.label.slice(1)} Area sft ({getAreaRangeDesc(p.label)})
                          </span>
                        ))}
                      </div>
                    }
                  </div>
                </div>
              </div>
            }

            {rentalResults?.length > 0
              && <div className={`col-${chartColGrid}`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-15 lh-1 fw-500">Rental PSF Price ($psf) Distribution</h2>
                    {/* <ChartSelect /> */}
                  </div>

                  <div className="pt-10">
                    <DistributionChart
                      label="Rental Price ($psf) Distribution"
                      data={
                        generatePropDistributionChart(
                          VALUE_TYPE_PRICE,
                          rentalResults.map(row => row['est_rental_psf_sgd'])
                        )
                      }
                      tooltipTitleCallback={
                        (context) => `Rental price (PSF) up to $${parseFloat(context[0].label).toFixed(2)}/sqft`
                      }
                      tooltipLabelCallback={
                        (context) => `Number of Rental Transactions: ${context.raw.toFixed(0)}`
                      }
                      xAxisLabel="Rental PSF Price ($psf)"
                      yAxisLabel="Number of Rental Transactions"
                      height={chartHeight}
                    />
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </section>

      <section className={`pt-10 pb-50 modal-table border-top-light`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Rental Transactions ({
                filteredResults.length
              })</h3>
            </div>
          </div>
          <RentalTable
            data={filteredResults}
          />
        </div>
      </section>

      <div
        className="offcanvas offcanvas-start filter-bar-nopad mt-50"
        tabIndex="-1"
        id="listingSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            Filter Rentals
          </h5>
          <button
            id="rt-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <aside className="sidebar y-gap-40 xl:d-block">
            <Sidebar
              filters={filterSelected}
              setFilters={setFilterSelected}
              options={FILTER_OPTIONS}
            />
          </aside>
        </div>

        <div className="row ml-10 mr-10 mt-10 mb-10">
          <div className="col-3">
            <button
              className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
              onClick={onResetFilter}
            >
              Reset
            </button>
          </div>
          <div className="col-9">
            <button
              className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
              onClick={onApplyFilter}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalTab;
