import { useState } from 'react';
import PropTypes from "prop-types";

import CustomTable from '../transactions/CustomTable';
import { convertDisplayDate, dateStrToMsec } from '@/utils/time';
import { NA, convertFloorAreaRange } from '@/utils/convert';

const tableConfig = {
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const DEFAULT_COLUMNS = [
  {
    Header: 'Project',
    accessor: 'project',
    disableGlobalFilter: true,
  },
  {
    Header: 'Street',
    accessor: 'street',
    disableGlobalFilter: true,
  },
  {
    Header: 'District',
    accessor: 'd',
    disableGlobalFilter: true,
    disableSortBy: true,
    width: 60,
  },
  {
    Header: 'Total Units',
    accessor: 't',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'Expected TOP Year',
    accessor: 'y',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'No. of Apartment',
    accessor: 'a',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'No. of Condo',
    accessor: 'c',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'No. of Semi-Detached',
    accessor: 's',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'No. of Detached House',
    accessor: 'h',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'No. of Terrace',
    accessor: 'e',
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: 'Developer',
    accessor: 'n',
    disableGlobalFilter: true,
    width: 280,
  },
];

const UpcomingTable = ({ data }) => {
  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={tableConfig}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
    />
  );
};

UpcomingTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UpcomingTable;
