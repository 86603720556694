import PropTypes from "prop-types";
import InputRange from "react-input-range";

const RangeSlider = (props) => {
  const {
    value,
    setValue,
    minValue,
    maxValue,
    pluralize,
    unit,
    dollarize,
    minify,
    step
  } = props;

  const handleOnChange = (value) => {
    setValue({ value });
  };

  const pluralizeUnit = () => {
    if (!pluralize) return unit;
    const suffix = value?.value?.max > 1 ? 's' : '';
    return `${unit}${suffix}`;
  };

  const dollarizeUnit = (value) => {
    if (!dollarize) return value;
    return `$${(value ?? 0).toLocaleString()}`
  };

  return (
    <div className="js-price-rangeSlider">
      <div className="text-14 fw-500"></div>

      <div className="d-flex justify-between mb-20">
        <div className="text-15 text-dark-1">
          <span className="js-lower mx-1">
            {value?.value?.min === -1 ? `less than ${dollarizeUnit(0)}`: dollarizeUnit(value?.value?.min)}
          </span>to
          <span className="js-upper mx-1">
            {value?.value?.max === maxValue && !minify ? 'more than ' : ''}{dollarizeUnit(value?.value?.max)}{value?.value?.max === maxValue && minify ? '+' : ''} {pluralizeUnit()}
          </span>
        </div>
      </div>

      <div className="px-5">
        <InputRange
          formatLabel={() => ``}
          minValue={minValue ?? 0}
          maxValue={maxValue}
          value={value?.value}
          step={step}
          onChange={(value) => handleOnChange(value)}
        />
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  pluralize: PropTypes.bool,
  unit: PropTypes.string,
  dollarize: PropTypes.bool,
  minify: PropTypes.bool,
  step: PropTypes.number,
};

RangeSlider.defaultProps = {
  minValue: 0,
  pluralize: false,
  dollarize: false,
  minify: false,
  step: 1,
};

export default RangeSlider;
