import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import ProjectSearch from "./ProjectSearch";
import { DEBOUNCE_TIMING, quickQueryProps } from "@/utils/api";

const MainFilterSearchBox = (props) => {
  const {
    onSearchLocation,
    onSearchClick,
    propertySearchValue,
    setPropertySearchValue,
    disableEnter,
  } = props;

  const [results, setResults] = useState([]);
  const [isSuggested, setIsSuggested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [hideResults, setHideResults] = useState(false);

  const onSearch = (value) => {
    setIsSuggested(false);
    quickQueryProps(value, 10, (data) => {
      let results = data.d.map(r => ({
        id: encodeURIComponent(`${r['Project Name']},${r['Street']}`),
        name: r['Project Name'],
        address: r['Street']
      }));

      if (results.length === 0 && data.s) {
        results = data.s.map(r => ({
          id: encodeURIComponent(`${r['Project Name']},${r['Street']}`),
          name: r['Project Name'],
          address: r['Street']
        }));
        setIsSuggested(true);
      }

      setResults(results);
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setHideResults(false);
    if (setPropertySearchValue) setPropertySearchValue(item);
    else setSearchValue(item);

    if (item?.length >= 3) {
      setLoading(true);
      onDebouncedSearch(item);
    } else {
      setResults([]);
    }
  };

  const onSelect = (item) => {
    setSelectedItem(item.name);
    setHideResults(true);
    onSearchLocation(item);
  };

  const onSearchListing = () => {
    if (!disableEnter) {
      onSearchClick?.(propertySearchValue ?? searchValue);
    } else if (results?.length > 0) {
      onSearchLocation(results[0]);
    }
  };

  return (
    <>
      <div className="col-12 bg-white">
        <ProjectSearch
          searchValue={propertySearchValue ?? searchValue}
          selectedItem={selectedItem}
          onInput={onInput}
          onSelect={onSelect}
          loading={loading}
          locationSearchContent={results}
          onKeyDown={onSearchListing}
          hideResults={hideResults}
          setHideResults={setHideResults}
          isSuggested={isSuggested}
          disableEnter={disableEnter}
          hideClickHint
        />
      </div>

      {/* <div className="col-12">
        <div className="button-item h-full">
          <button
            className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white"
            onClick={onSearchListing}
          >
            <i className="icon-search text-20 mr-10" />
            Search
          </button>
        </div>
      </div> */}
    </>
  );
};

MainFilterSearchBox.propTypes = {
  onSearchLocation: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  propertySearchValue: PropTypes.string,
  setPropertySearchValue: PropTypes.func,
  disableEnter: PropTypes.bool,
};

MainFilterSearchBox.defaultProps = {
  disableEnter: false,
};

export default MainFilterSearchBox;
