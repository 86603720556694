import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';

import PlanList from "./profile/PlanList";
import { getUserDisplayName, getUserPhotoUrl, isAdminTier } from "@/utils/user";

const UserMenu = ({
  textClass,
  user,
  onClick,
  onClose
}) => {
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const handleCarding = () => {
    if (!click) {
      onClick?.();
    } else {
      onClose?.();
    }
    setClick((prevState) => !prevState);
  };

  const onLogout = () => {
    firebase.auth().signOut().then(() => navigate('/login'));
  };

  const onAdmin = () => {
    navigate('/admin');
  };

  return (
    <>
      <div className="col-auto">
        <button
          className={`d-flex items-center text-14 ${textClass}`}
          onClick={handleCarding}
        >
          <img
            src={getUserPhotoUrl(user)}
            alt="image"
            referrerPolicy="no-referrer"
            className="rounded-full mr-10"
            height={20}
            width={20}
          />
          <span className="js-language-mainTitle">
            {" "}
            {getUserDisplayName(user)}
          </span>
          <i className="icon-chevron-sm-down text-7 ml-15" />
        </button>
      </div>

      <div className={`langMenu langMenuTop js-langMenu ${click ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">My Profile</div>
            <button className="pointer" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>

          <div className="text-18 fw-500 lh-15 ml-35 mt-20">My Subscription</div>
          <PlanList user={user} />

          <div className="d-flex justify-content-end is-menu-opened-hide md:d-none ml-20 mr-20 mb-20">
            {isAdminTier(user?.claims)
              && <button
                className="button ml-auto p-2 px-30 fw-400 text-14 border-blue-1 -outline-blue -blue-1-05 bg-white h-50 mt-20 text-blue-1 mr-20"
                onClick={onAdmin}
              >
                <i className="icon-user text-20 mr-10" />
                Admin
              </button>
            }
            <button
              className="button ml-auto p-2 px-30 fw-400 text-14 border-blue-1 -outline-blue -blue-1-05 bg-white h-50 mt-20 text-blue-1"
              onClick={onLogout}
            >
              <i className="icon-bed text-20 mr-10" />
              Logout
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

UserMenu.propTypes = {
  textClass: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default UserMenu;
