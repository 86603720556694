import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import DraggableList from "react-draggable-list";
import TableLayoutItem from './TableLayoutItem';
import { PAGE_LIMIT_OPTIONS } from '@/utils/table';
import {
  appendCountToTHeaders,
  convertTHeaderToApiCol,
  filterTHeadersByAccessors,
  includeProjectTHeader,
  includeStreetTHeader
} from '@/utils/convert';

const ReactTableFilter = ({
  name,
  rows,
  setGlobalFilter,
  setFilterValue,
  placeholder,
  dataLength,
  currentColumns, setColumns, customizeColumns, resetColumns, hideSearchInput, hidePage,
  hidePageButton,
  pageLimit, setPageLimit,
  showFilterButton,
  onFilterButtonClick,
  groupOptions, selectedGroupOption, setSelectedGroupOption, originalColumns,
  disableCustomColumns
}) => {
  const [click, setClick] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(selectedGroupOption);
  const handleCarding = () => setClick((prevState) => !prevState);

  const containerRef = useRef();

  const hasGrouping = () => Object.keys(selectedGroup).some(g => !!selectedGroup[g]);

  const resetGroup = () => {
    setSelectedGroup({});
    setSelectedGroupOption(null, {}, null, null);
  };

  const applyGroup = () => {
    if (hasGrouping()) {
      const selectedGroups = groupOptions.filter(g => !!selectedGroup[g.value]);
      const groupByOptions = new Set();
      const accessors = new Set();
      selectedGroups.forEach(g => {
        g.options.forEach(o => groupByOptions.add(o));
        g.accessors.forEach(o => accessors.add(o));
      });

      // generate columns for query and table headers based on group selection
      let headers = filterTHeadersByAccessors(originalColumns, accessors);
      const columns = includeStreetTHeader(includeProjectTHeader(convertTHeaderToApiCol(headers)));
      headers = appendCountToTHeaders(headers);
      setSelectedGroupOption({
        by: Array.from(groupByOptions),
        count: true,
      }, selectedGroup, columns, headers);
    } else {
      resetGroup();
    }
  };

  return (
    <>
      <SearchWrap>
        <div class="d-flex baseline-align noselect">
          {showFilterButton
            && <button
              className="button h-30 px-20 rounded-100 text-light-2 filter-button text-12 mr-10 p-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#listingSidebar"
              onClick={() => onFilterButtonClick?.(name)}
            >
              <i className="icon-menu-2 text-12 mr-10" />
              Filter
            </button>
          }
          
          <div className="dropdown js-dropdown js-category-active p-2">
            {!hidePageButton
              && <div
                className="dropdown__button d-flex items-center bg-white border-light h-30 rounded-100 px-15 py-10 text-12 lh-12 ml-5"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
                data-bs-offset="0,10"
                data-bs-target="#dropdown-page"
              >
                <span className="js-dropdown-title">Show {pageLimit}</span>
                <i className="icon icon-chevron-sm-down text-7 ml-10" />
              </div>
            }

            <div id="dropdown-page" className="toggle-element -dropdown dropdown-menu z-menu">
              <div className="text-14 y-gap-15 js-dropdown-list">
                {PAGE_LIMIT_OPTIONS.map((option, index) => (
                  <div key={index}>
                    <button
                      className={`d-block js-dropdown-link ${
                        pageLimit === option ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setPageLimit(option);
                        document.querySelector(".js-dropdown-title").textContent = `Show ${option}`;
                      }}
                    >
                      {option}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-end noselect">
          {groupOptions
            && <div className="dropdown js-dropdown js-category-active">
              <button
                className="dropdown__button d-flex items-center bg-white border-light h-30 rounded-100 px-15 text-12 lh-12 mr-10 p-2"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
                data-bs-offset="0,10"
                data-bs-target="#dropdown-group"
              >
                <span className="js-dropdown-group-title">Group by</span>
                <i className="icon icon-chevron-sm-down text-7 ml-10" />
              </button>

              <div id="dropdown-group" className="toggle-element -dropdown dropdown-menu z-menu">
                <div className="text-14 y-gap-15 js-dropdown-list">
                  {groupOptions.map((option, index) => (
                    <div className="col-auto col-12" key={index}>
                      <div className="form-checkbox d-flex items-center">
                        <input
                          type="checkbox"
                          checked={!!selectedGroup[option.value]}
                          onChange={() => setSelectedGroup({
                            ...selectedGroup,
                            [option.value]: !selectedGroup[option.value]
                          })}
                        />
                        <div className="form-checkbox__mark">
                          <div className="form-checkbox__icon icon-check" />
                        </div>
                        <div className="text-12 ml-10">{option.label}</div>
                      </div>
                    </div>
                  ))}

                  <div className="row">
                    <div className="col-6">
                      <button
                        className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                        onClick={() => resetGroup()}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1"
                        onClick={() => applyGroup()}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {customizeColumns && !disableCustomColumns
            && <button
              className="button -blue-1 h-30 px-10 rounded-100 bg-blue-1-05 text-12 text-blue-1 p-2"
              onClick={handleCarding}
            >
              <i className="icon-edit text-14 mr-10" />
              Customize Columns
            </button>
          }
        </div>

      </SearchWrap>

      <div className={`langMenu langMenuTop js-langMenu unpad-modal ${click ? "" : "is-hidden"} noselect`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-10 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Customize Table Columns</div>
            <button className="pointer" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>
          <div className="custom-col-list mb-50">
            <div className="d-flex justify-content-between mr-20 mb-10">
              <p className="p-2 ml-10 text-12 lh-12">Click and drag to reorder the columns to display</p>
              <button
                className="button ml-auto p-2 px-20 fw-400 text-12 border-blue-1 -outline-blue -blue-1-05 bg-white h-30 mt-10 text-blue-1"
                onClick={resetColumns}
              >
                <i className="icon-day-night text-14 mr-10" />
                Reset
              </button>
            </div>
            <DraggableList
              itemKey="Header"
              template={TableLayoutItem}
              list={currentColumns}
              onMoveEnd={(newList) => setColumns(newList)}
              container={() => containerRef.current}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const filterGreaterThan = (rows, id, filterValue) => rows.filter((row) => {
  const rowValue = row.values[id];
  return rowValue >= filterValue;
});

filterGreaterThan.autoRemove = val => typeof val !== 'number';

ReactTableFilter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  currentColumns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
  customizeColumns: PropTypes.bool,
  resetColumns: PropTypes.func.isRequired,
  hideSearchInput: PropTypes.bool,
  hidePage: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  groupOptions: PropTypes.array,
  setSelectedGroupOption: PropTypes.func,
};

ReactTableFilter.defaultProps = {
  setFilterValue: () => {},
  placeholder: 'Search...',
  customizeColumns: false,
  hideSearchInput: false,
  hidePage: false,
  showFilterButton: false,
  groupOptions: null,
  setSelectedGroupOption: null,
};

export default ReactTableFilter;

// region STYLES

const SearchWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
  color: #000;

  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
`;

const SearchInput = styled(Form.Control)`
  max-width: 250px;
  font-size: 12px;
  margin-left: 20px;
  margin-right: 20px;
  color: #000;
  background: #fff;
`;

// endregion
