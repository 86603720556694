import { useState } from 'react';
import PropTypes from "prop-types";
import CustomTable from '../transactions/CustomTable';
import { NA } from '@/utils/convert';

const tableConfig = {
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const AreaTable = ({
  data,
  goToProperty
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Project',
      accessor: 'id',
      disableGlobalFilter: true,
      Cell: (row) =>
        <a
          className="text-blue-1 cursor-pointer"
          onClick={() => goToProperty(row.cell.value.split(',')[0], row.cell.value.split(',')[1])}
        >
          {row.cell.value.split(',')[0]}
        </a>
    },
    {
      Header: 'Profitable (%)',
      accessor: 'profitable',
      disableGlobalFilter: true,
      width: 50,
      Cell: (row) => <div>
        {isNaN(parseFloat(row.cell.value)) || row.cell.value === -1 ? NA : `${row.cell.value.toFixed(1)}%`}
      </div>
    },
    {
      Header: 'Transactions',
      accessor: 'transactions',
      disableGlobalFilter: true,
      width: 50,
      Cell: (row) => row.cell.value.toLocaleString()
    },
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={tableConfig}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      hidePage
      pageLimit={1000}
      disableCustomColumns={true}
    />
  );
};

AreaTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AreaTable;
