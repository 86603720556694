import { NA } from "@/utils/convert";

const RentalGroup = ({ item }) => {
  const generateValue = (value) => {
    if (value === -1) return NA;
    return value ?? NA;
  };

  return (
    <div className="px-15 py-5 mt-10 proj-perf">
      <div className="d-flex items-center text-blue-1 text-12">
        <i className="icon-usd fw-600 mr-10"></i>
        <div className="fw-600">Rental</div>
      </div>

      <div className="row x-gap-50 y-gap-0 text-12 pt-5">
        <div className="col-12">
          <div className="">
            Past 6mths Avg PSF Rental: <span>{
              generateValue(item?.['past_6_mth_avg_psf_rental']) === NA
                ? NA
                : `$${item['past_6_mth_avg_psf_rental'].toFixed(2)}`
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Number of Rentals in Past 6mths: <span>{
              generateValue(item?.['past_6_mth_count_total_rentals']) === NA
                ? NA
                : item['past_6_mth_count_total_rentals'].toFixed(0)
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Past 1mth Avg PSF Rental: <span>{
              generateValue(item?.['past_1_mth_avg_psf_rental']) === NA
                ? NA
                : `$${item['past_1_mth_avg_psf_rental'].toFixed(2)}`
            }</span>
          </div>
        </div>

        <div className="col-12">
          <div className="">
            Number of Rentals in Past 1mth: <span>{
              generateValue(item?.['past_1_mth_count_total_rentals']) === NA
                ? NA
                : item['past_1_mth_count_total_rentals'].toFixed(0)
            }</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default RentalGroup;
