import {
  isMapboxURL,
  transformMapboxUrl
} from 'maplibregl-mapbox-request-transformer'
import { isDevMode } from './dev';
import { NA } from './convert';

export const REGIONS = [
  {
    label: 'North',
    areas: [
      'Lim Chu Kang', 'Mandai', 'Sembawang', 'Sungei Kadut', 'Woodlands', 'Yishun',
    ],
  },
  {
    label: 'North-East',
    areas: [
      'Ang Mo Kio', 'Hougang', 'Punggol', 'Seletar', 'Sengkang', 'Serangoon',
    ],
  },
  {
    label: 'East',
    areas: [
      'Bedok', 'Changi', 'Pasir Ris', 'Paya Lebar', 'Tampines',
    ],
  },
  {
    label: 'West',
    areas: [
      'Boon Lay', 'Bukit Batok', 'Bukit Panjang', 'Choa Chu Kang', 'Clementi', 'Jurong East', 'Jurong West', 'Pioneer',
      'Tengah', 'Tuas',
    ],
  },
  {
    label: 'Central',
    areas: [
      'Bishan', 'Bukit Merah', 'Bukit Timah', 'Downtown Core', 'Geylang', 'Kallang', 'Marina East', 'Marina South',
      'Marine Parade', 'Museum', 'Newton', 'Novena', 'Orchard', 'Outram', 'Queenstown', 'River Valley', 'Rochor',
      'Singapore River', 'Southern Islands', 'Tanglin', 'Toa Payoh',
    ],
  },
];

export const PLANNING_AREAS = [
  {
    name: { en: "Ang Mo Kio", zh: "宏茂桥" },
    coordinates: { latitude: 1.38009, longitude: 103.8421 },
  },
  {
    name: { en: "Bedok", zh: "勿洛" },
    coordinates: { latitude: 1.32366, longitude: 103.92822 },
  },
  {
    name: { en: "Bishan", zh: "碧山" },
    coordinates: { latitude: 1.35382, longitude: 103.84327 },
  },
  {
    name: { en: "Boon Lay", zh: "文礼" },
    coordinates: { latitude: 1.31479, longitude: 103.70222 },
  },
  {
    name: { en: "Bukit Batok", zh: "武吉巴督" },
    coordinates: { latitude: 1.35566, longitude: 103.75474 },
  },
  {
    name: { en: "Bukit Merah", zh: "红山" },
    coordinates: { latitude: 1.27549, longitude: 103.82313 },
  },
  {
    name: { en: "Bukit Panjang", zh: "武吉班让" },
    coordinates: { latitude: 1.36682, longitude: 103.773 },
  },
  {
    name: { en: "Bukit Timah", zh: "武吉知马" },
    coordinates: { latitude: 1.32999, longitude: 103.7907 },
  },
  {
    name: { en: "Central Water Catchment", zh: "中央集水区" },
    coordinates: { latitude: 1.37666, longitude: 103.80119 },
  },
  {
    name: { en: "Changi", zh: "樟宜" },
    coordinates: { latitude: 1.35081, longitude: 103.99782 },
  },
  {
    name: { en: "Changi Bay", zh: "樟宜湾" },
    coordinates: { latitude: 1.32233, longitude: 104.02495 },
  },
  {
    name: { en: "Choa Chu Kang", zh: "蔡厝港" },
    coordinates: { latitude: 1.38402, longitude: 103.74719 },
  },
  {
    name: { en: "Clementi", zh: "金文泰" },
    coordinates: { latitude: 1.3176, longitude: 103.76075 },
  },
  {
    name: { en: "Downtown Core", zh: "市中心" },
    coordinates: { latitude: 1.28506, longitude: 103.8543 },
  },
  {
    name: { en: "Geylang", zh: "芽笼" },
    coordinates: { latitude: 1.3209, longitude: 103.88952 },
  },
  {
    name: { en: "Hougang", zh: "后港" },
    coordinates: { latitude: 1.36364, longitude: 103.8898 },
  },
  {
    name: { en: "Jurong East", zh: "裕廊东" },
    coordinates: { latitude: 1.32389, longitude: 103.73651 },
  },
  {
    name: { en: "Jurong West", zh: "裕廊西" },
    coordinates: { latitude: 1.34161, longitude: 103.7049 },
  },
  {
    name: { en: "Kallang", zh: "加冷" },
    coordinates: { latitude: 1.31034, longitude: 103.86643 },
  },
  {
    name: { en: "Lim Chu Kang", zh: "林厝港" },
    coordinates: { latitude: 1.43064, longitude: 103.71929 },
  },
  {
    name: { en: "Mandai", zh: "万礼" },
    coordinates: { latitude: 1.42267, longitude: 103.80607 },
  },
  {
    name: { en: "Marina East", zh: "滨海东" },
    coordinates: { latitude: 1.28852, longitude: 103.87235 },
  },
  {
    name: { en: "Marina South", zh: "滨海南" },
    coordinates: { latitude: 1.28053, longitude: 103.86563 },
  },
  {
    name: { en: "Marine Parade", zh: "马林百列" },
    coordinates: { latitude: 1.30023, longitude: 103.89627 },
  },
  {
    name: { en: "Museum", zh: "博物馆" },
    coordinates: { latitude: 1.29592, longitude: 103.84671 },
  },
  {
    name: { en: "Newton", zh: "纽顿" },
    coordinates: { latitude: 1.30866, longitude: 103.83884 },
  },
  {
    name: { en: "Novena", zh: "诺维娜" },
    coordinates: { latitude: 1.32488, longitude: 103.83588 },
  },
  {
    name: { en: "Orchard", zh: "乌节" },
    coordinates: { latitude: 1.30381, longitude: 103.83358 },
  },
  {
    name: { en: "Outram", zh: "欧南" },
    coordinates: { latitude: 1.28286, longitude: 103.84206 },
  },
  {
    name: { en: "Pasir Ris", zh: "巴西立" },
    coordinates: { latitude: 1.37632, longitude: 103.94849 },
  },
  {
    name: { en: "Paya Lebar", zh: "巴耶利峇" },
    coordinates: { latitude: 1.3594, longitude: 103.91478 },
  },
  {
    name: { en: "Pioneer", zh: "先驱" },
    coordinates: { latitude: 1.31259, longitude: 103.67299 },
  },
  {
    name: { en: "Punggol", zh: "榜鹅" },
    coordinates: { latitude: 1.40536, longitude: 103.91004 },
  },
  {
    name: { en: "Queenstown", zh: "女皇镇" },
    coordinates: { latitude: 1.29347, longitude: 103.78608 },
  },
  {
    name: { en: "River Valley", zh: "里峇峇利" },
    coordinates: { latitude: 1.29805, longitude: 103.83397 },
  },
  {
    name: { en: "Rochor", zh: "梧槽" },
    coordinates: { latitude: 1.30426, longitude: 103.85326 },
  },
  {
    name: { en: "Seletar", zh: "实里达" },
    coordinates: { latitude: 1.41434, longitude: 103.8771 },
  },
  {
    name: { en: "Sembawang", zh: "三巴旺" },
    coordinates: { latitude: 1.45575, longitude: 103.81883 },
  },
  {
    name: { en: "Sengkang", zh: "盛港" },
    coordinates: { latitude: 1.39204, longitude: 103.8877 },
  },
  {
    name: { en: "Serangoon", zh: "实龙岗" },
    coordinates: { latitude: 1.3652, longitude: 103.86782 },
  },
  {
    name: { en: "Simpang", zh: "新邦" },
    coordinates: { latitude: 1.44332, longitude: 103.85197 },
  },
  {
    name: { en: "Singapore River", zh: "新加坡河" },
    coordinates: { latitude: 1.29011, longitude: 103.84121 },
  },
  {
    name: { en: "Southern Islands", zh: "南部群岛" },
    coordinates: { latitude: 1.248, longitude: 103.83005 },
  },
  {
    name: { en: "Straits View", zh: "海峡景" },
    coordinates: { latitude: 1.27125, longitude: 103.85877 },
  },
  {
    name: { en: "Sungei Kadut", zh: "双溪加株" },
    coordinates: { latitude: 1.41813, longitude: 103.75746 },
  },
  {
    name: { en: "Tampines", zh: "淡滨尼" },
    coordinates: { latitude: 1.34366, longitude: 103.95426 },
  },
  {
    name: { en: "Tanglin", zh: "东陵" },
    coordinates: { latitude: 1.30837, longitude: 103.81759 },
  },
  {
    name: { en: "Tengah", zh: "登加" },
    coordinates: { latitude: 1.36384, longitude: 103.72875 },
  },
  {
    name: { en: "Toa Payoh", zh: "大巴窑" },
    coordinates: { latitude: 1.33668, longitude: 103.86103 },
  },
  {
    name: { en: "Tuas", zh: "大士" },
    coordinates: { latitude: 1.28383, longitude: 103.63069 },
  },
  {
    name: { en: "Western Water Catchment", zh: "西部集水区" },
    coordinates: { latitude: 1.38634, longitude: 103.69429 },
  },
  {
    name: { en: "Woodlands", zh: "兀兰" },
    coordinates: { latitude: 1.44068, longitude: 103.7877 },
  },
  {
    name: { en: "Yishun", zh: "义顺" },
    coordinates: { latitude: 1.41497, longitude: 103.83695 },
  },
];

export const REGION_LIST = REGIONS.map(region => region.label);

export const isRegion = (area) => REGION_LIST.includes(area);

export const getRegionOfArea = (area) => {
  for (let i = 0; i < REGIONS.length; i += 1) {
    if (REGIONS[i].areas.find(a => a === area)) return REGIONS[i].label;
  }
};

export const MARKET_SEGMENTS = [
  {
    label: 'Core Central Region',
  },
  {
    label: 'Rest of Central Region',
  },
  {
    label: 'Outside Central Region',
  },
];

export const MARKET_SEGMENT_ID_MAP = {
  [MARKET_SEGMENTS[0].label]: 'CCR',
  [MARKET_SEGMENTS[1].label]: 'RCR',
  [MARKET_SEGMENTS[2].label]: 'OCR',
};

export const MARKET_SEGMENT_ID_REVERSE_MAP = {
  'CCR': [MARKET_SEGMENTS[0].label],
  'RCR': [MARKET_SEGMENTS[1].label],
  'OCR': [MARKET_SEGMENTS[2].label],
};

export const TENURE_LIST = [
  {
    label: "30 yrs",
    value: "30",
  },
  {
    label: "60 yrs",
    value: "60",
  },
  {
    label: "70 yrs",
    value: "70",
  },
  {
    label: "99 yrs",
    value: "99",
  },
  {
    label: "100 yrs",
    value: "100",
  },
  {
    label: "102 yrs",
    value: "102",
  },
  {
    label: "103 yrs",
    value: "103",
  },
  {
    label: "999 yrs",
    value: "999",
  },
  {
    label: "Freehold",
    value: "Freehold",
  },
];

export const getTenureValue = (value) => {
  const tenure = TENURE_LIST.find(t => t.value === value);
  if (!tenure) return null;
  return tenure.value;
};

export const getAllTenureIntValues = () => TENURE_LIST
  .map(tenure => tenure.value === "Freehold" ? null : parseInt(tenure.value));

export const getTenureLabel = (value) => {
  if (value.toLowerCase().includes('Freehold')) return 'Freehold';
  const tenure = TENURE_LIST.find(t => t.value === value);
  if (!tenure) return 'Unknown';
  return tenure.label;
};

export const PROPERTY_TYPE_DISPLAY_LIST = [
  { label: "Apartment/Condo", value: "Apt/Condo" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const PROPERTY_TYPE_LIST = [
  { label: "Apartment", value: "Apartment" },
  { label: "Condo", value: "Condominium" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const RENTAL_PROPERTY_TYPE_LIST = [
  { label: "Non-Landed", value: "Non-landed Properties" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const SALE_TYPE_LIST = [
  // { label: "New Sale", value: "New Sale" },
  { label: "Sub Sale", value: "Sub Sale" },
  { label: "Resale", value: "Resale" },
];

export const SALE_TYPE_FULL_LIST = [
  { label: "New Sale", value: "New Sale" },
  { label: "Sub Sale", value: "Sub Sale" },
  { label: "Resale", value: "Resale" },
];

export const getPropertyFileName = (project, street) => {
  let str = `${project}-${street}`;
  // Replace all non-alphanumeric characters with underscores
  str = str.replace(/\W+/g, '_');
  // Replace multiple underscores with a single underscore
  str = str.replace(/_+/g, '_');
  // Strip leading and trailing underscores
  str = str.replace(/^_+|_+$/g, '');
  return str.toLowerCase();
};

export const transformMapboxStyleRequest = (url, resourceType) => {
  const mapboxKey = isDevMode
    ? "pk.eyJ1IjoieGluaW1pYyIsImEiOiJjbHo5b2wwYXQwZmlyMm1yNndneXRhbzhpIn0.yKlYXtwSwBEAtOFp5diUuA"
    : "pk.eyJ1IjoieGluaW1pYyIsImEiOiJjbHo5b3BjdGMwYm5oMmpwamRja240anh6In0.oNZE1cjnr-0KNaz6CjBdkQ";

  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType, mapboxKey)
  }
  
  return {url}
}

export const MAP_THEMES = [
  {
    label: 'Default',
    style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
  },
  {
    label: 'Street',
    style: 'mapbox://styles/mapbox/streets-v12',
  },
  {
    label: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-streets-v12',
  },
  {
    label: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v11',
  },
  {
    label: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v12',
  },
];

export const getMapStyleFromTheme = (theme) => MAP_THEMES.find(t => t.label === theme)?.style ?? MAP_THEMES[0].style;

// export const DEFAULT_MAP_THEME = 'Default';
export const DEFAULT_MAP_THEME = 'Outdoors';

export const convertIntToPropertyType = (val) => {
  if (!val) return [NA];
  const types = [];
  PROPERTY_TYPE_LIST.forEach((t, i) => {
    const b = (1 << i) & val;
    if (b > 0) {
      types.push(t.value);
    }
  });
  return types.filter(v => !!v);
};

export const convertIntToTenureType = (val) => {
  if (!val) return [NA];
  return val.split(',').map(v => {
    if (v === '-') return NA;
    if (v === '0') return 'Freehold';
    if (v.endsWith('l')) return `${v.slice(0, -1)} yrs leasehold`;
    const chunks = v.split('&');
    const d = chunks[0];
    const y = chunks[1];
    return `${d} yrs from ${y}`;
  });
};

const ID_REPLACEMENTS = {
  "AVENUE": "*a",
  "APARTMENT": "*x",
  "BUKIT": "*b",
  "BLOCK": "*5",
  "COURT": "*c",
  "CIRCLE": "*y",
  "CENTRE": "*7",
  "CRESCENT": "*9",
  "CONDOMINIUM": "*z",
  "DRIVE": "*d",
  "ESTATE": "*e",
  "GARDEN": "*g",
  "GROVE": "*.",
  "GEYLANG": "*+",
  "HILL": "*h",
  "HOUSE": "*-",
  "HEIGHTS": "*8",
  "HOLLAND": "*?",
  "JALAN": "*j",
  "LORONG": "*l",
  "LANE": "*1",
  "MANSION": "*m",
  "MOUNT": "*2",
  "PARK": "*p",
  "QUAY": "*q",
  "ROAD": "*r",
  "STREET": "*s",
  "SQUARE": "*o",
  "SERANGOON": "*:",
  "SEMBAWANG": "*;",
  "TOWER": "*t",
  "TERRACE": "*3",
  "RISE": "*4",
  "RESIDENCE": "*6",
  "VIEW": "*v",
  "WALK": "*w"
}

export const unravelId = (id) => {
  const sameStreet = !id.includes(',');
  let newId = id;
  for (let i = 0; i < Object.keys(ID_REPLACEMENTS).length; i++) {
    const replacement = Object.keys(ID_REPLACEMENTS)[i];
    newId = newId.split(ID_REPLACEMENTS[replacement]).join(replacement);
  }
  return sameStreet ? `${newId},${newId}` : newId;
};
