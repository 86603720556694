import { Link } from "react-router-dom";
import ScrollUpButton from "./ScrollUpButton";
import Social from "../common/social/Social";

const FinalPage = ({ onPrevPage }) => {
  return (
    <div className="lp-full">
      <div className="lp-intro-container">
        <div className="lp-join-content d-flex justify-content-center flex-column align-items-center">
          <img className="lp-join-img mb-10" src="/img/general/sg_map_des.jpg" />

          <div className="intro-type-wrapper">
            <p className="intro-typing-sec">Get started for <span className="text-blue-1">FREE</span> now!</p>
          </div>

          <Link
            className="button -sm -dark-1 bg-blue-1 text-white text-16 px-20 py-10 join-btn mt-40 mb-20"
            to="/login"
          >
            JOIN REALSMART.SG
          </Link>

          <div className="text-12">
            <Link
              to="/about"
              className="text-blue-1"
              target="_blank"
            >
              About Us
            </Link> · <Link
              to="/terms?t=tos"
              className="text-blue-1"
              target="_blank"
            >
              Terms of Service
            </Link> · <Link
              to="/terms?t=privacy"
              className="text-blue-1"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </div>

          <div className="lp-scroller-down-container d-flex justify-content-center mb-20 d-flex x-gap-20 items-center">
            <Social />
          </div>
        </div>

        <ScrollUpButton onPrevPage={onPrevPage} dark />
      </div>
    </div>
  );
};

export default FinalPage;
