import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import { DEBOUNCE_TIMING, quickQuerySchools } from "@/utils/api";
import SearchBar from "@/components/common/sidebar/ProjectSearch";

const SchoolQueryFilter = (props) => {
  const {
    onSearchLocation,
    schoolSearchValue,
    setSchoolSearchValue,
  } = props;

  const [results, setResults] = useState([]);
  const [isSuggested, setIsSuggested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [hideResults, setHideResults] = useState(false);

  const onSearch = (value) => {
    setIsSuggested(false);
    quickQuerySchools(value, 10, (data) => {
      let results = data.d.map(r => ({
        ...r,
        id: encodeURIComponent(`${r['name']},${r['street']}`),
      }));

      setResults(results);
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setHideResults(false);
    if (setSchoolSearchValue) setSchoolSearchValue(item);
    else setSearchValue(item);

    if (item?.length >= 3) {
      setLoading(true);
      onDebouncedSearch(item);
    } else {
      setResults([]);
    }
  };

  const onSelect = (item) => {
    setSelectedItem(item.name);
    setHideResults(true);
    onSearchLocation(item);
    setSchoolSearchValue(item.name);
  };

  const onSearchListing = () => {
    if (results?.length > 0) {
      onSearchLocation(results[0]);
      setSchoolSearchValue(results[0].name);
    }
  };

  return (
    <>
      <div className="col-12 py-10 px-0 bg-white">
        <SearchBar
          searchValue={schoolSearchValue ?? searchValue}
          selectedItem={selectedItem}
          onInput={onInput}
          onSelect={onSelect}
          loading={loading}
          locationSearchContent={results}
          onKeyDown={onSearchListing}
          hideResults={hideResults}
          setHideResults={setHideResults}
          isSuggested={isSuggested}
          hideClickHint
          isSchool
        />
      </div>
    </>
  );
};

export default SchoolQueryFilter;
