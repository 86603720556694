import PropTypes from "prop-types";

const TablePagination = (props) => {
  const { currentPage, onPageChange, hasNextPage } = props;

  const isFirstPage = currentPage === 1;
  const isLastPage = !hasNextPage;
  const onPrevPage = () => onPageChange(Math.max(1, currentPage - 1));
  const onNextPage = () => onPageChange(currentPage + 1);

  return (
    <div className="d-flex justify-content-between mb-10">
      <button
        className={`button -blue-1 h-40 rounded-100 border-light text-12 px-10 ${isFirstPage ? 'hidden-item' : ''}`}
        onClick={onPrevPage}
      >
        <i className="icon-chevron-left" />
        <span className="px-10">PREV PAGE</span>
      </button>

      <div className="size-40 flex-center rounded-full bg-dark-1 text-white centered">
        {currentPage}
      </div>
      
      <button
        className={`button -blue-1 h-40 rounded-100 border-light text-12 px-10 ${isLastPage ? 'hidden-item' : ''}`}
        onClick={onNextPage}
      >
        <span className="px-10">NEXT PAGE</span>
        <i className="icon-chevron-right" />
      </button>
    </div>
  );
};

TablePagination.propTypes = {
  currentPage: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  hasNextPage: PropTypes.func.isRequired,
};

export default TablePagination;
