import { logPageTabView } from "@/utils/api";

const TabButtons = ({
  tabs,
  selectedTab,
  setSelectedTab,
  onSwitchTab,
  session,
  projectName,
  streetName
}) => {
  return (
    <div className="d-flex flex-wrap justify-content-start py-0 modal-menu noselect">
      {tabs.map(tab => (
        <button
          className={`p-2 button mr-10 mb-5 ${
            selectedTab === tab.id
              ? '-dark-1 bg-blue-1 text-white'
              : '-blue-1 bg-blue-1-05 text-blue-1'
          } h-30 px-5 rounded-100 text-12`}
          key={tab.id}
          onClick={() => {
            setSelectedTab(tab.id);
            onSwitchTab?.(tab.id);
            logPageTabView(tab.id.toUpperCase(), session, projectName, streetName);
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabButtons;
